import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../styles/variables';
import { useMyContext } from '../../store/Context';
import { Button } from '../inputs';

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 15px;
`;

const Inputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 15px;
`;

interface InlineItemCreatorProps{
  children: any
  onCreate: any
  insertEnabled?: boolean
}

export const InlineItemCreator = ({children, onCreate, insertEnabled = true}: InlineItemCreatorProps) => {

  const lang = useMyContext();

  const handleInsertClick = (e: any) => {
    e.preventDefault();
    onCreate();
  };

  return (
    <Wrapper>
      <Inputs>
        {children}
      </Inputs>
      <Button onClick={handleInsertClick} disabled={!insertEnabled}>{lang.insert}</Button>
    </Wrapper>
  );
};