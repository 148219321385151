import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { getExpensesAmountByType } from '../../../../services/transactionsService';
import { ChartWidget } from '../../../../components/ChartWidget';
import { PieChart } from '@mui/x-charts';
import { useMyContext } from '../../../../store/Context';

export const ExpensesAmountByType = () => {
  const { id: organizationId } = useAppSelector((state) => state.organization);
  const [expenses, setExpenses] = useState([]);

  const lang = useMyContext();

  const fetchExpenses = async () => {
    const response = await getExpensesAmountByType(organizationId, {});
    setExpenses(response.data);
  };

  useEffect(() => {
    fetchExpenses();
  }, [organizationId]);

  return(
    <ChartWidget title="Ventas por tipo">
      <PieChart
        series={[
          {
            data: [
              ...expenses.map((expense: any) => ({
                value: Number(expense.amount),
                label: lang.transactions.expenses.types[expense.type] || expense.type,
              }))
            ],
            innerRadius: 50,
            outerRadius: 120,
            paddingAngle: 2,
            cornerRadius: 5,
            cx: '30%',
          }
        ]}
        height={300}
        slotProps={
          {
            legend: {
              labelStyle: {
                fontSize: 10
              }
            }
          }
        }
      />
    </ChartWidget>
  );
};