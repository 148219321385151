import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authSlice';
import userReducer from './reducers/userSlice';
import organizationSlice from './reducers/organizationSlice';
import interfaceReducer from './reducers/interfaceSlice';
import resourcesReducer from './reducers/resourcesSlice';
import resourcesCategoriesReducer from './reducers/resourcesCategoriesSlice';
import productsReducer from './reducers/productsSlice';
import productsCategoriesReducer from './reducers/productsCategoriesSlice';
import expensesReducer from './reducers/expensesSlice';
import incomesReducer from './reducers/incomesSlice';
import clientsReducer from './reducers/clientsSlice';
import priceMakersReducer from './reducers/priceMakersSlice';
import priceModifiersReducer from './reducers/priceModifiersSlice';
import pricesListsReducer from './reducers/pricesListsSlice';
import pricesListEditorReducer from './reducers/pricesListEditorSlice';

export const store = configureStore({
  reducer: {
    access: authReducer,
    user: userReducer,
    organization: organizationSlice,
    interface: interfaceReducer,
    resources: resourcesReducer,
    resourcesCategories: resourcesCategoriesReducer,
    products: productsReducer,
    productsCategories: productsCategoriesReducer,
    incomes: incomesReducer,
    expenses: expensesReducer,
    clients: clientsReducer,
    priceMakers: priceMakersReducer,
    priceModifiers: priceModifiersReducer,
    pricesLists: pricesListsReducer,
    pricesListEditor: pricesListEditorReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch