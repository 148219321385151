import { AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';
import LapseFilter from '../../../components/LapseFilter/LapseFilterBase';
import { Loader } from '../../../components/Loader';
import { QueryTextFilter } from '../../../components/QueryTextFilter';
import { TableBarAccordion, TableBarContainer, TableCell, TableColumnHeader, TableContainer, TableEnvironment, TableRow } from '../../../components/Table';
import { TablePagination } from '../../../components/Table/TablePagination';
import { useMyContext } from '../../../store/Context';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteIncome, getIncomes, setIncomesSearchParams } from '../../../store/reducers/incomesSlice';
import { DeleteIconButton } from '../../../components/CustomIconButton/DeleteIconButton';
import { IncomeEditor } from './IncomeEditor';
import { EditIconButton } from '../../../components/CustomIconButton/EditIconButton';
import { format, parseISO } from 'date-fns';

export default function IncomesList(){

  const lang = useMyContext();
  const dispatch = useAppDispatch();

  const { incomes, incomesFetchingStatus, lastPage, isSynchronized } = useAppSelector((state) => state.incomes);
  const { clients } = useAppSelector((state) => state.clients);

  const [orderField, setOrderField] = useState({
    field: 'date',
    isDesc: true,
  });

  const [displayEditor, setDisplayEditor] = useState(false);
  const [editedItem, setEditedItem] = useState<any>(null);

  const { searchParams } = useAppSelector((state) => state.incomes);

  useEffect(() => {
    dispatch(setIncomesSearchParams({
      ...searchParams,
      orderBy: orderField.field,
      order: orderField.isDesc? 'desc' : 'asc',
    }));
  }, [orderField]);

  useEffect(() => {
    dispatch(getIncomes());
  }, [searchParams]);

  useEffect(() => {
    if(!isSynchronized){
      dispatch(getIncomes());
    }
  }, [isSynchronized]);
  
  const handleEdit = (incomeId: number): void => {
    if (!incomeId) return;
    const editedItem = incomes.find((income: any) => income.id === incomeId);
    setEditedItem(editedItem);
    setDisplayEditor(true);
  };

  const handleTermSearchChange = (searchTerm: string) => {
    dispatch(setIncomesSearchParams({
      ...searchParams,
      searchTerm,
      page: 1,
    }));
  };

  const handlePageSelect = (e: any, page: number) => {
    dispatch(setIncomesSearchParams({
      ...searchParams,
      page,
    }));
  };
  const handleDelete = (incomeId: number): void => {
    dispatch(deleteIncome({ incomeId }));
  };

  const handleLapseFilterChange = (lapse: any) => {
    dispatch(setIncomesSearchParams({
      ...searchParams,
      after: lapse.after,
      before: lapse.before,
      isLapseActive: true,
    }));
  };

  const setIsLapseActive = (value: boolean) => {
    dispatch(setIncomesSearchParams({
      ...searchParams,
      isLapseActive: value,
    }));
  };

  const getClientName = (id: number) => {
    if (id) {
      return clients.find((client: any) => client.id === id)?.name;
    }
    return '';
  };

  const handleSetDisplayEditor = (display: boolean) => {
    setDisplayEditor(display);
    if (!display) {
      setEditedItem(null);
    }
  };

  return (
    <TableEnvironment>
      <TableBarContainer>
        <TableBarAccordion>
          <AccordionSummary
            expandIcon={<MdOutlineExpandMore />}
          >
            {lang.searchFilters}
          </AccordionSummary>
          <AccordionDetails>
            <QueryTextFilter
              onTermSearch={handleTermSearchChange}
            />
            <LapseFilter
              onChange={handleLapseFilterChange}
              lapse={{
                after: searchParams.after,
                before: searchParams.before,
              }}
              isActive={searchParams.isLapseActive}
              setIsActive={setIsLapseActive}
            />
          </AccordionDetails>
        </TableBarAccordion>
      </TableBarContainer>
      <Loader fetchingStatus={incomesFetchingStatus}>
        <TableContainer>
          <TableRow gridLayout='2fr 1fr 1fr 4fr 2fr 1fr 1fr'>
            <TableCell>
              <TableColumnHeader
                name='date'
                text={lang.attributes.date}
                isActive={orderField.field === 'date'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='type'
                text={lang.attributes.type}
                isActive={orderField.field === 'type'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='quantity'
                text={lang.attributes.quantity}
                isActive={orderField.field === 'quantity'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='concept'
                text={lang.attributes.concept}
                isActive={orderField.field === 'concept'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='client'
                text={lang.attributes.client}
                isActive={orderField.field === 'client'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='value'
                text={lang.attributes.value}
                isActive={orderField.field === 'value'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                text={lang.attributes.options}
              />
            </TableCell>
          </TableRow>
          {
            incomes.map((income: any) => {
              return (
                <TableRow gridLayout='2fr 1fr 1fr 4fr 2fr 1fr 1fr' key={income.id}>
                  <TableCell>
                    {format(parseISO(income.date), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>{lang.transactions.incomes.types[income.type] || income.type}</TableCell>
                  <TableCell>
                    {income.quantity}
                  </TableCell>
                  <TableCell>
                    {income.concept}
                  </TableCell>
                  <TableCell>
                    {getClientName(income.clientId)}
                  </TableCell>
                  <TableCell>
                    {income.value}
                  </TableCell>
                  <TableCell>
                    <EditIconButton onClick={() => handleEdit(income.id)} />
                    <DeleteIconButton onClick={() => handleDelete(income.id)} />
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableContainer>
      </Loader>
      <TablePagination count={lastPage} page={searchParams.page} onChange={handlePageSelect}/>
      <IncomeEditor
        display={displayEditor}
        setDisplay={handleSetDisplayEditor}
        data={editedItem}
      />
    </TableEnvironment>
  );
}
