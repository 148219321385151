import React from 'react';
import { useMyContext } from '../../store/Context';
import { Wrapper } from '../../components/Wrapper';

export function Suppliers () {
  const lang = useMyContext();
  return (
    <Wrapper title={lang.modules.suppliers}>
            Contenido por aquí
    </Wrapper>
  );
}