export const es = {
  modules: {
    products: 'productos',
    clients: 'clientes',
    resources: 'recursos',
    suppliers: 'proveedores',
    stock: 'stock',
    prices: 'precios',
    transactions: 'transacciones',
  },
  save: 'Guardar',
  cancel: 'Cancelar',
  start: 'inicio',
  insert: 'Insertar',
  search: 'Buscar',
  edit: 'Editar',
  delete: 'Eliminar',
  clone: 'Clonar',
  options: 'Opciones',
  settings: 'configuración',
  searchTerm: 'Término de búsqueda',
  searchFilters: 'Filtros de búsqueda',
  resources: {
    new: 'Nuevo recurso',
    edit: 'Editar recurso',
  },
  products: {
    product: 'Producto',
    products: 'Productos',
    new: 'Nuevo producto',
    edit: 'Editar producto'
  },
  stock: {
    resources: 'Recursos',
    products: 'Productos',
    stockedResources: 'Recursos stockeados',
    nonStockedResources: 'Recursos no stockeados',
    stockedProducts: 'Productos stockeados',
    nonStockedProducts: 'Productos no stockeados',
    restore: 'Restaurar',
  },
  prices: {
    new: 'Nuevo formador de precio',
    edit: 'Editar formador de precio',
    pricesLists: 'Listas de precios',
    pricesList: 'Lista de precios',
    priceMakers: 'Formadores de precios',
    priceMaker: 'Formador de precios',
    editor: {
      description: 'Descripción',
      formula: 'Fórmula',
    },
    newPricesList: 'Nueva lista de precios',
  },
  transactions: {
    incomes: {
      name: 'Ingresos',
      types: {
        sale: 'Venta',
        loan: 'Crédito',
      }
    },
    expenses: {
      name: 'Egresos',
      types: {
        materials: 'Materias primas',
        supplies: 'Insumos',
        services: 'Servicios',
        energy: 'Energía',
        salaries: 'Salarios',
        others: 'Otros',
      },
    },
    balance: {
      name: 'Balance',
    }
  },
  attributes: {
    name: 'Nombre',
    title: 'Título',
    subtitle: 'Subtítulo',
    price: 'Precio',
    stock: 'Stock',
    unit: 'Unidad',
    category: 'Categoría',
    categories: 'Categorías',
    tag: 'Etiqueta',
    tags: 'Etiquetas',
    description: 'Descripción',
    shortDescription: 'Descripción corta',
    longDescription: 'Descripción larga',
    date: 'Fecha',
    product: 'Producto',
    value: 'Valor',
    concept: 'Concepto',
    type: 'Tipo',
    quantity: 'Cantidad',
    options: 'Opciones',
    email: 'Email',
    address: 'Dirección',
    phone: 'Teléfono',
    client: 'Cliente'
  },
  messages: {
    onDeleteMessage: '¿Está seguro de que desea eliminar este item?'
  },
  components: {
    tagsPicker: {
      inputPlaceholder: 'Crea una nueva etiqueta...'
    },
    table: {
      categoryFilter: {
        allOption: 'Todas',
      }
    },
    loader: {
      loadingMessage: 'Cargando...',
      failMessage: 'Lo sentimos, ocurrió un error al intentar cargar los datos. Intente nuevamente actualizando la página.',
    }
  }
};