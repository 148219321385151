import React from 'react';
import styled from '@emotion/styled';
import { colors, fonts } from '../../styles/variables';

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    items-align: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
`;

const Item = styled.button`
    min-width: 200px;
    max-width: 100%;
    border-radius: 15px;
    background: none;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    background-color: ${colors.softHighlight};
    :hover{
      background-color: ${colors.secondary};
      cursor: pointer;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${fonts.base.family};
    font-size: 1rem;
    img{
      width: 40px;
      height: 40px;
      margin-right: 20px
    }
`;

interface OrganizationItem {
  id: number
  text: string
  imageUrl: string
}

interface OrganizationItemProps {
  items: OrganizationItem[]
  onClick: any
}

export const OrganizationItems = ({ items, onClick }: OrganizationItemProps) => {
  return (
    <Container>
      {
        items.map((item: OrganizationItem) => {
          return (
            <Item 
              onClick={() => {onClick(item.id);}}
              key={item.id}
            >
              <img src={item.imageUrl}/>
              {item.text}
            </Item>
          );
        })
      }
    </Container>
  );
};