import React, { useEffect } from 'react';
import './App.css';
import { Login } from './views/Login';
import { Register } from './views/Register';
import { Home } from './views/Home';
import {
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { setLoggedIn, setLoggedOut } from './store/reducers/authSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { clearStore } from './store/actions/clearStore';
import { language } from './config/language';
import { Context} from './store/Context';
import { isValidToken as isValidTokenService } from './services/authService';

function App() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  
  const { logged } = useAppSelector((state) => state.access);
  const selectedLanguage = 'es';

  useEffect(() => {
    document.title = 'Duite';
  }, []);

  const checkToken = async () => {
    const isValidToken = await isValidTokenService();
    if(isValidToken){
      dispatch(setLoggedIn());
    } else {
      navigateToLogin();
    }
  };
  
  const navigateToLogin = () => {
    if(location.pathname !== 'login' && location.pathname !== 'register'){
      navigate('/login');
    }
  };

  useEffect(() => {
    if (logged) {
      if (!localStorage.accessToken) {
        dispatch(setLoggedOut());
        dispatch(clearStore());
      }
    } else {
      if(localStorage.accessToken){
        checkToken();
      } else {
        navigateToLogin();
      }
    }
  }, [logged]);

  return (
    <div className="App">
      <Context.Provider value={language(selectedLanguage)}>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/*' element={<Home/>}>
          </Route>
        </Routes>
      </Context.Provider>
    </div>
  );
}

export default App;
