import React from 'react';
import styled from '@emotion/styled';
import { inputStyle } from './inputStyle';

const StyledSelect = styled.select`
    ${inputStyle}
`;

interface SelectProps{
    name: string
    values: any[]
    selectedValue: any,
    onSelect: any
}

export const Select = ({name, values, selectedValue, onSelect}: SelectProps) => {
  return (
    <StyledSelect value={selectedValue} onChange={onSelect} name={name}>
      {
        values.map((value) => {
          return (
            <option value={value.option} key={value.option}>{value.name}</option>
          );
        })
      }
    </StyledSelect>
  );
};