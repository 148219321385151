import React, { useEffect } from 'react';
import { useMyContext } from '../../store/Context';
import { Tabs } from '../../components/Tab';
import { Wrapper } from '../../components/Wrapper';
import { ProductsStock } from './ProductsStock';
import { ResourcesStock } from './ResourcesStock';
import { Route, Routes, Navigate } from 'react-router-dom';

export function Stock () {
  const lang = useMyContext();

  const routes = [
    {
      path: 'resources',
      title: lang.stock.resources
    },
    {
      path: 'products',
      title: lang.stock.products
    }
  ];
    
  return (
    <Wrapper title={lang.modules.stock}>
      <Tabs routes={routes}>
        <Routes>
          <Route path="/" element={<Navigate to='resources'/>}/>
          <Route path="/resources" element={<ResourcesStock/>}/>
          <Route path="/products" element={<ProductsStock/>}/>
        </Routes>
      </Tabs>
    </Wrapper>
  );
}