import React, { useEffect, useState } from 'react';
import { units } from '../../config/units';
import { useSelectValues } from '../../hooks/useSelectValues';
import { useMyContext } from '../../store/Context';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  createProduct,
  updateProduct,
  setProductOnEdition,
} from '../../store/reducers/productsSlice';
import {
  createCategory,
  deleteCategory,
} from '../../store/reducers/productsCategoriesSlice';
import { ItemEditor } from '../../components/ItemEditor';
import {
  Field, Group, Input, Label,
} from '../../components/ItemEditor/styles';
import { Select } from '../../components/ItemEditor/styles/Select';
import { TagsPicker } from '../../components/TagsPicker';
import { closeProductEditor } from '../../store/reducers/interfaceSlice';

export function ProductEditor() {
  const dispatch = useAppDispatch();
  const formatSelectValues = useSelectValues;
  const lang = useMyContext();

  const formDataInitialState = {
    shortDescription: '',
    longDescription: '',
    categories: [],
    stock: 0,
    unit: 'kg',
  };

  const [formData, setFormData] = useState<any>(formDataInitialState);

  const { categories } = useAppSelector((state) => state.productsCategories);

  const {
    productOnEdition,
  } = useAppSelector((state) => state.products);

  const { displayProductEditor: display } = useAppSelector((state) => state.interface);

  const close = () => {
    dispatch(setProductOnEdition(null));
    dispatch(closeProductEditor());
  };

  const save = async () => {
    if (productOnEdition) {
      dispatch(updateProduct({
        productId: productOnEdition.id,
        data: formData,
      }));
    } else {
      dispatch(createProduct({
        data: formData,
      }));
      setFormData(formDataInitialState);
    }
    close();
  };

  useEffect(() => {
    if (productOnEdition) {
      const {
        shortDescription, longDescription, stock, unit, categories,
      } = productOnEdition;
      setFormData({
        shortDescription: shortDescription?? '',
        longDescription: longDescription?? '',
        stock: stock?? 0,
        unit: unit?? '',
        categories: categories?? [],
      });
    } else {
      setFormData(formDataInitialState);
    }
  }, [productOnEdition]);

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCategorySelect = (id: number) => {
    if (id !== null && categories) {
      if (formData.categories.every((category: any) => category.id !== id)) {
        setFormData({
          ...formData,
          categories: formData.categories
            .concat(categories.find((category: any) => category.id === id)),
        });
      }
    }
  };

  const handleCategoryCreate = (name: string) => {
    dispatch(createCategory({
      name: name,
    }));
  };

  const handleCategoryRemove = (id: number) => {
    const removedItemIndex = formData.categories.indexOf(
      formData.categories.find(
        (category: any) => category.id === id)
    );
    setFormData({
      ...formData,
      categories: formData.categories.slice(0, removedItemIndex)
        .concat(formData.categories.slice(removedItemIndex + 1)),
    });
  };

  const handleCategoryDelete = (id: number) => {
    dispatch(deleteCategory({
      categoryId: id,
    }));
  };

  return (
    <ItemEditor
      display={display}
      title={
        productOnEdition
          ? lang.products.edit
          : lang.products.new
      }
      close={close}
      save={save}
    >
      <Group>
        <Field size={4}>
          <Label>
            {lang.attributes.shortDescription}:
          </Label>
          <Input
            type="text"
            name="shortDescription"
            value={formData.shortDescription}
            onChange={handleChange}
          />
        </Field>
        <Field size={8}>
          <Label>{lang.attributes.longDescription}:</Label>
          <Input
            type="text"
            name="longDescription"
            value={formData.longDescription}
            onChange={handleChange}
          />
        </Field>
        <Field size={2}>
          <Label>{lang.attributes.stock}</Label>
          <Input
            type="number"
            name="stock"
            value={formData.stock}
            onChange={handleChange}
          />
        </Field>
        <Field size={1}>
          <Label>{lang.attributes.unit}</Label>
          <Select
            name="unit"
            values={formatSelectValues(units)}
            selectedValue={formData.unit}
            onSelect={handleChange}
          />
        </Field>
      </Group>
      <Group>
        <Field size={1}>
          <Label>{lang.attributes.categories}</Label>
          <TagsPicker
            tags={formData.categories}
            availableTags={categories}
            onRemove={handleCategoryRemove}
            onSelect={handleCategorySelect}
            onCreate={handleCategoryCreate}
            onDelete={handleCategoryDelete}
          />
        </Field>
      </Group>
    </ItemEditor>
  );
}
