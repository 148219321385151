import api from './config/api';

interface Product{
    id?: number
    sku?: string
    short_description: string
    long_description?: string
    stock?: number
    unit?: string
    stockable?: boolean
    categories: number[]
}

export const getProducts = async (organizationId: number) => {
  const response = await api.get(`/${organizationId}/products`);
  return response.data;
};

export const getProduct = async (organizationId: number, productId: number) => {
  const response  = await api.get(`/${organizationId}/products/${productId}`);
  return response.data;
};

export const updateProduct = async (organizationId: number, productId: number, data: Product) => {
  const response = await api.put(`/${organizationId}/products/${productId}`, data);
  return response.data;
};

export const createProduct = async (organizationId: number, data: Product) => {
  const response = await api.post(`/${organizationId}/products`, data);
  return response.data;
};

export const deleteProduct = async (organizationId: number, productId: number) => {
  const response = await api.delete(`/${organizationId}/products/${productId}`);
  return response.data;
};

export const getProductsCategories = async (organizationId: number) => {
  const response = await api.get(`/${organizationId}/products_categories`);
  return response.data;
};

export const getProductsCategory = async (organizationId: number, productId: number) => {
  const response  = await api.get(`/${organizationId}/products_categories/${productId}`);
  return response.data;
};

export const updateProductsCategory = async (organizationId: number, productId: number, data: any) => {
  const response = await api.put(`/${organizationId}/products_categories/${productId}`, data);
  return response.data;
};

export const createProductsCategory = async (organizationId: number, data: any) => {
  const response = await api.post(`/${organizationId}/products_categories`, {
    name: data
  });
  return response.data;
};

export const deleteProductsCategory = async (organizationId: number, productId: number) => {
  const response = await api.delete(`/${organizationId}/products_categories/${productId}`);
  return response.data;
};