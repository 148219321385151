import React, { useEffect, useState } from 'react';
import { Modal } from '../../../components/Modal';
import { DatePicker } from '@mui/x-date-pickers';
import { Button } from '../../../components/inputs';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { useAppDispatch } from '../../../store/hooks';
import { format, formatISO, parseISO } from 'date-fns';
import { updateExpense } from '../../../store/reducers/expensesSlice';

type InitialExpenseProps = {
  id: number,
  date: string,
  concept: string,
  value: number,
}

type Expense = {
  id: number | null,
  date: Date,
  concept: string,
  value: number,
}

export const ExpenseEditor = ({ data: initialData, display, setDisplay }: {
  data: InitialExpenseProps,
  display: boolean,
  setDisplay: (display: boolean) => void
}) => {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<Expense>({
    id: null,
    date: new Date(),
    concept: '',
    value: 0,
  });

  useEffect(() => {
    if(initialData) {
      setData({
        id: initialData.id,
        date: parseISO(initialData.date),
        concept: initialData.concept,
        value: initialData.value,
      });
    }
  }, [initialData]);

  const handleSave = () => {
    dispatch(updateExpense({
      expenseId: data.id,
      data: {
        date: data.date ? formatISO(data.date, { representation: 'date' }) : new Date().toISOString(),
        concept: data.concept,
        value: data.value,
      }
    }));
    setDisplay(false);
  };

  return (
    <Modal display={display} setDisplay={setDisplay} title="Editar egreso">
      <Form>
        <DatePicker 
          label="Fecha"
          value={data.date}
          onChange={(date) => setData({...data, date: date ?? new Date()})}
        />
        <TextField
          label="Concepto"
          value={data.concept}
          onChange={(event) => setData({...data, concept: event.target.value})}
        />
        <TextField
          label="Valor"
          type="number"
          value={data.value}
          onChange={(event) => setData({...data, value: parseFloat(event.target.value)})}
        />
        <Button onClick={handleSave}>Guardar</Button>
        <Button onClick={() => setDisplay(false)}>Cancelar</Button>
      </Form>
    </Modal>
  );
};

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});