import React from 'react';
import { useMyContext } from '../../store/Context';
import { Tabs } from '../../components/Tab';
import { Wrapper } from '../../components/Wrapper';
import PriceMakersList from './PriceMakersList';
import PricesLists from './PricesLists';
import { Navigate, Route, Routes } from 'react-router-dom';
import PricesListPDFMock from './PricesLists/PricesListPDFMock';

export default function Prices () {
  const lang = useMyContext();

  const routes = [
    {
      path: 'makers',
      title: lang.prices.priceMakers
    },
    {
      path: 'lists',
      title: lang.prices.pricesLists
    }
  ];

  return (
    <Wrapper title={lang.modules.prices}>
      <Tabs routes={routes}>
        <Routes>
          <Route path="/" element={<Navigate to='makers'/>}/>
          <Route path="/makers" element={<PriceMakersList/>}/>
          <Route path="/lists" element={<PricesLists/>}/>
          <Route path="/lists/mock" element={<PricesListPDFMock/>}/>
        </Routes>
      </Tabs>
    </Wrapper>
  );
}