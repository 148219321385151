import { AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOutlineExpandMore } from 'react-icons/md';
import { CustomIconButton } from '../../components/CustomIconButton';
import { Input } from '../../components/inputs';
import { Loader } from '../../components/Loader';
import { Wrapper } from '../../components/Wrapper';
import { TableBarAccordion, TableBarContainer, TableCell, TableColumnHeader, TableEnvironment, TableRow, TableTextFilter } from '../../components/Table';
import { InlineItemCreator } from '../../components/Table/InlineItemCreator';
import { useSort } from '../../hooks/useSort';
import { useMyContext } from '../../store/Context';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createClient, deleteClient, getClients } from '../../store/reducers/clientsSlice';

export default function Clients () {

  const lang = useMyContext();
  const dispatch = useAppDispatch();
  const sortByField = useSort;

  const [textFilter, setTextFilter] = useState('');
  const [orderField, setOrderField] = useState({
    field: 'name',
    isDesc: false
  });
  const [newClient, setNewClient] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
  });
  const [filteredClients, setFilteredClients] = useState<any[]>([]);

  const { id: organizationId } = useAppSelector((state) => state.organization);
  const { clients, clientsFetchingStatus } = useAppSelector((state) => state.clients);

  useEffect(() => {
    if(organizationId){
      dispatch(getClients());
    }
  }, [organizationId]);

  useEffect(() => {
    setFilteredClients(
      clients
        .filter((client: any) => client.name.toLowerCase().match(textFilter.toLowerCase()))
        .sort((a: any, b: any) => sortByField(a,b, orderField.field, orderField.isDesc))
    );
  }, [clients, textFilter, orderField]);

  const handleNewClientChange = (e: any) => {
    setNewClient({
      ...newClient,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreate = () => {
    dispatch(createClient({organizationId, data: newClient}));
  };

  const handleDelete = (id: number) => {
    dispatch(deleteClient({organizationId, id}));
  };

  return (
    <Wrapper title={lang.modules.clients}>
      <TableBarAccordion>
        <AccordionSummary expandIcon={<MdOutlineExpandMore/>} >
          {lang.insert}
        </AccordionSummary>
        <AccordionDetails>
          <InlineItemCreator onCreate={handleCreate}>
            <label>{lang.attributes.name}</label>
            <Input
              name='name'
              type='text'
              value={newClient.name}
              onChange={handleNewClientChange}
            />
            <label>{lang.attributes.phone}</label>
            <Input
              name='phone'
              type='text'
              value={newClient.phone}
              onChange={handleNewClientChange}
            />
            <label>{lang.attributes.email}</label>
            <Input
              name='email'
              type='text'
              value={newClient.email}
              onChange={handleNewClientChange}
            />
            <label>{lang.attributes.address}</label>
            <Input
              name='address'
              type='text'
              value={newClient.address}
              onChange={handleNewClientChange}
            />
          </InlineItemCreator>
        </AccordionDetails>
      </TableBarAccordion>
      <Loader fetchingStatus={clientsFetchingStatus}>
        <TableEnvironment>
          <TableBarContainer>
            <TableTextFilter
              setter={setTextFilter}
            />
          </TableBarContainer>
          <TableRow gridLayout='3fr 3fr 3fr 3fr 1fr'>
            <TableCell>
              <TableColumnHeader
                name='name'
                text={lang.attributes.name}
                isActive={orderField.field === 'name'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              {lang.attributes.phone}
            </TableCell>
            <TableCell>
              {lang.attributes.email}
            </TableCell>
            <TableCell>
              {lang.attributes.address}
            </TableCell>
            <TableCell>
              <TableColumnHeader
                text={lang.attributes.options}
              />
            </TableCell>
          </TableRow>
          {
            filteredClients.map((client: any) => {
              return (
                <TableRow gridLayout='3fr 3fr 3fr 3fr 1fr' key={client.id}>
                  <TableCell>{client.name}</TableCell>
                  <TableCell>{client.phone}</TableCell>
                  <TableCell>{client.email}</TableCell>
                  <TableCell>{client.address}</TableCell>
                  <TableCell>
                    <CustomIconButton onClick={() => handleDelete(client.id)}><AiOutlineDelete /></CustomIconButton>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableEnvironment>
      </Loader>
    </Wrapper>
  );
}