import api from './config/api';

export const getResources = async (organizationId: number) => {
  const response = await api.get(`${organizationId}/resources`);
  return response.data;
};

export const getResource = async (organizationId: number, resourceId: number) => {
  const response = await api.get(`${organizationId}/resources/${resourceId}`);
  return response.data;
};

interface Resource{
    name: string,
    price: number,
    category: number,
    stock: number,
    stockable: boolean,
    unit?: string,
}

export const updateResource = async (organizationId: number, resourceId: number, data:Resource) => {
  console.log(data);
  const resource = {
    name: data.name,
    price: data.price,
    stock: data.stock,
    stockable: data.stockable,
    unit: data.unit,
    resource_category_id: data.category,
  };
  const response = await api.put(`${organizationId}/resources/${resourceId}`, resource);
  return response.data;
};

export const createResource = async (organizationId: number, data:any) => {
  const resource = {
    name: data.name,
    price: data.price,
    stock: data.stock,
    unit: data.unit,
    resources_category_id: data.category,
  };
  const response = await api.post(`${organizationId}/resources`, resource);
  return response.data;
};

export const deleteResource = async (organizationId: number, resourceId: number) => {
  const response = await api.delete(`${organizationId}/resources/${resourceId}`);
  return response.data;
};


export const getResourcesCategories = async (organizationId: number) => {
  const response = await api.get(`${organizationId}/resources_categories`);
  return response.data;
};

export const createResourcesCategory = async (organizationId: number, categoryName: string) => {
  const response = await api.post(`${organizationId}/resources_categories`, {
    name: categoryName
  });
  return response.data;
};

export const updateResourcesCategory = async (OrganizationId: number, categoryId: number, name: string) => {
  const response = await api.put(`${OrganizationId}/resources_categories/${categoryId}`, name);
  return response.data;
};

export const deleteResourcesCategory = async (organizationId: number, categoryId: number) => {
  const response = await api.delete(`${organizationId}/resources_categories/${categoryId}`);
  return response.data;
};