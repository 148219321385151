import React, { MouseEventHandler } from 'react';
import styled from '@emotion/styled';
import {colors} from '../../styles/variables';
import { IconButton } from '@mui/material';

export const StyledCustomIconButton = styled.button`
    border: none;
    background-color: ${colors.secondary};
    padding: 5px;
    box-sizing: border-box;
    margin: 0;
    border-radius: 7px;
    :hover {
        background-color: ${colors.secondaryHover};
        cursor: pointer;
    }
    svg{
        min-width: 15px;
        width: 100%;
        height: 100%;
    }
`;

interface ButtonProps{
    onClick?: MouseEventHandler,
    children: JSX.Element,
    className?: any
}

export const CustomIconButton = ({onClick, children, className}: ButtonProps) => {
  return (
    <IconButton onClick={onClick} className={className}>
      {children}
    </IconButton>
  );
};