import React from 'react';
import styled from '@emotion/styled';
import { Container } from '../components/Container';

const StyledSignForm = styled.form`
    width: 90%;
    height: 90%;
    max-width: 500px;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 0 15px 15px rgba(0,0,0,0.1);

    .logo{
        width: 40%;
        max-width: 500px;
    }
    img{
        width: 100%;
    }
    label{
        margin-top: 20px;
    }
    input{
        margin-top: 10px;
    }
`;

interface SignFormPropsInterface{
  children: JSX.Element
}

export const SignForm = ({children}: SignFormPropsInterface) => {
  return (
    <Container>
      <StyledSignForm>
        {children}
      </StyledSignForm>
    </Container>
  );
};