import React, { MouseEventHandler } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { IconButton, Tooltip } from '@mui/material';
import { useMyContext } from '../../store/Context';

interface ButtonProps{
  onClick?: MouseEventHandler,
  className?: any
}

export const EditIconButton = ({onClick, className}: ButtonProps) => {
  const lang = useMyContext();
  return (
    <Tooltip title={lang.edit}>
      <IconButton onClick={onClick} className={className}>
        <AiOutlineEdit/>
      </IconButton>
    </Tooltip>
  );
};