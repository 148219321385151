import React from 'react';
import styled from '@emotion/styled';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Button } from '../../inputs';

interface StyledWrapperProps{
    $display: boolean,
}

const StyledWrapper = styled.div<StyledWrapperProps>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    display: ${props => props.$display? 'inherit' : 'none'};
`;

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 2%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Form = styled.form`
    width: 100%;
    height: 100%;
    padding: 2%;
    box-sizing: border-box;
    overflow: auto;
`;

const StyledBar = styled.div`
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
`;

const StyledTitle = styled.div`
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 2px solid black;
    display: block;
    h2{
        margin: 0;
    }
`;

const StyledCloseButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    svg{
        width: 30px;
        height: 30px;
    }
`;

interface ItemEditorWrapperProps{
    children: any,
    display: boolean,
    title: string,
    close: any,
    save: any,
    cancelName: string,
    saveName: string
}

export const ItemEditorWrapper = ({children, display, title, close, save, cancelName, saveName}: ItemEditorWrapperProps) => {
  return (
    <StyledWrapper $display={display}>
      <StyledContainer>
        <StyledBar>
          <StyledTitle><h2>{title}</h2></StyledTitle>
          <StyledCloseButton><AiOutlineCloseCircle onClick={close}/></StyledCloseButton>
        </StyledBar>
        <Form>
          {children}
        </Form>
        <StyledBar>
          <Button onClick={save}>{saveName}</Button>
          <Button onClick={close}>{cancelName}</Button>
        </StyledBar>
      </StyledContainer>
    </StyledWrapper>
  );
};