import React, { useEffect } from 'react';
import { useMyContext } from '../../store/Context';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getExpenses } from '../../store/reducers/expensesSlice';
import { getIncomes } from '../../store/reducers/incomesSlice';
import { Tabs } from '../../components/Tab';
import { Wrapper } from '../../components/Wrapper';
import Expenses from './Expenses';
import Incomes from './Incomes';
import { getClients } from '../../store/reducers/clientsSlice';
import Balance from './Balance';
import { Navigate, Route, Routes } from 'react-router-dom';

export default function Transactions () {

  const lang = useMyContext();
  const dispatch = useAppDispatch();

  const { id: organizationId } = useAppSelector((state) => state.organization);

  useEffect(() => {
    dispatch(getClients()).unwrap();
    dispatch(getIncomes());
    dispatch(getExpenses(null));
  }, [organizationId]);

  const routes = [
    {
      path: 'incomes',
      title: lang.transactions.incomes.name
    },
    {
      path: 'expenses',
      title: lang.transactions.expenses.name
    },
    {
      path: 'balance',
      title: lang.transactions.balance.name
    }
  ];

  return (
    <Wrapper title={lang.modules.transactions}>
      <Tabs routes={routes}>
        <Routes>
          <Route path="/" element={<Navigate to='incomes'/>}/>
          <Route path="/incomes" element={<Incomes/>}/>
          <Route path="/expenses" element={<Expenses/>}/>
          <Route path="/balance" element={<Balance/>}/>
        </Routes>
      </Tabs>
    </Wrapper>
  );
}