import React, { MouseEventHandler } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { BiTrash } from 'react-icons/bi';
import { useMyContext } from '../../store/Context';

interface ButtonProps{
  onClick?: MouseEventHandler,
  className?: any
}

export const DeleteIconButton = ({onClick, className}: ButtonProps) => {
  const lang = useMyContext();

  return (
    <Tooltip title={lang.delete}>
      <IconButton onClick={onClick} className={className}>
        <BiTrash/>
      </IconButton>
    </Tooltip>
  );
};