const brand = 'Dulceres';
const logo_url = 'https://github.com/nicolasoleinizak/d-suite-images/blob/main/production/dulceres/organization/dulceres-logo.png';
const pricesList = {
  'id': 1,
  'title': 'Alfajores y chocolates',
  'subtitle': 'Minorista',
  'note': 'Esta es una nota descriptiva al final de la lista de precios',
  'date': '2023-12-31',
  'organization_id': 1,
  'created_at': null,
  'updated_at': '2023-12-31T17:15:48.000000Z',
  'pricesGroups': [
    {
      'id': 1,
      'title': 'Alfajores',
      'prices_list_id': 1,
      'prices': [
        {
          'id': 3,
          'product_id': 4,
          'updated_at': '2023-12-31T16:10:35.000000Z',
          'result': 57.86,
          'product': {
            'id': 4,
            'shortDescription': 'Galletita chocolate'
          }
        },
        {
          'id': 4,
          'product_id': 3,
          'updated_at': '2023-12-31T16:10:35.000000Z',
          'result': 62.16,
          'product': {
            'id': 3,
            'shortDescription': 'Alfajor individual'
          }
        }
      ]
    },
    {
      'id': 3,
      'title': 'Insumos',
      'prices_list_id': 1,
      'prices': [
        {
          'id': 3,
          'product_id': 4,
          'updated_at': '2023-12-31T16:10:35.000000Z',
          'result': 57.86,
          'product': {
            'id': 4,
            'shortDescription': 'Galletita chocolate'
          }
        },
        {
          'id': 4,
          'product_id': 3,
          'updated_at': '2023-12-31T16:10:35.000000Z',
          'result': 62.16,
          'product': {
            'id': 3,
            'shortDescription': 'Alfajor individual'
          }
        }
      ]
    }
  ]
};

import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100vh'
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    backgroundColor: '#fff',
    padding: 40,
  },
  logoContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 20,
  },
  image: {
    width: 100,
    height: 100
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 20,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 20,
    textTransform: 'uppercase',
  },
  listTitlesContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 10,
    borderBottomWidth: 1,
  },
  subtitle: {
    width: '80%',
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
  },
  date: {
    width: '20%',
    fontSize: 14,
    textAlign: 'right',
    marginBottom: 20,
  },
  pricesGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    paddingLeft: 20,
  },
  priceGroupTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    backgroundColor: '#f0f0f0',
    padding: 5,
  },
  priceGroupTitle: {
    fontSize: 18,
    textAlign: 'left',
    marginBottom: 10,
  },
  pricesContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    paddingLeft: 20,
  },
  priceRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  priceDescription: {
    fontSize: 12,
    textAlign: 'left',
  },
  priceValue: {
    fontSize: 12,
    textAlign: 'right',
  },
  noteContainer: {
    width: '100%',
    marginTop: 20,
    padding: 15,
    borderTopWidth: 1,

  },
  note: {
    fontSize: 11,
    textAlign: 'center',
  }
});

const PricesListPDFMock = () => {
  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`Lista de precios ${pricesList.title}`}>
        <Page size="A4" style={styles.page}>
          <View style={styles.logoContainer}>
            <Image src={logo_url} style={styles.image}/>
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{brand}</Text>
          </View>
          <View style={styles.listTitlesContainer}>
            <Text style={styles.subtitle}>{pricesList?.title}</Text>
            <Text style={styles.date}>{new Date(pricesList?.date).toLocaleDateString()}</Text>
          </View>
          {
            pricesList?.pricesGroups?.map((group: any) => {
              return (
                <View key={group.id} style={styles.pricesGroup}>
                  <View style={styles.priceGroupTitleContainer}>
                    <Text style={styles.priceGroupTitle}>{group.title}</Text>
                  </View>
                  {
                    group.prices &&
                    <View style={styles.pricesContainer}>
                      {
                        group.prices.map((price: any) => {
                          return (
                            <View key={price.id} style={styles.priceRow}>
                              <Text style={styles.priceDescription}>{price.product.shortDescription}</Text>
                              <Text style={styles.priceValue}>$ {price.result}</Text>
                            </View>
                          );
                        })
                      }
                    </View>
                  }
                </View>
              );
            })
          }
          {
            pricesList?.note &&
            <View style={styles.noteContainer}>
              <Text style={styles.note}>{pricesList.note}</Text>
            </View>
          }
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PricesListPDFMock;
