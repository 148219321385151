import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../styles/variables';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
export const TabBarWrapper= styled.div`
    width: 100%;
    height: 3vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
`;

interface TabButtonProps{
    isActive: boolean
}

const TabButton = styled.button<TabButtonProps>`
    height: 100%;
    margin: 0 10px 0 10px;
    ${
  props => props.isActive? `
        border-style: solid;
        border-width: 2px 2px 0 2px;
        background: ${colors.softHighlight};
    `
    : `
        border-width: 0px;
        background: none;
    `
}
    border-color: ${colors.softHighlight};
    display: inline-block;
    border-radius: 10px 10px 0 0px;
`;

const Wrapper = styled.div`
    width: 100%;
`;

const TabsContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: solid 2px ${colors.softHighlight};
`;


const Tab = styled.div`
    width: 100%;
    height: 100%;
`;

interface TabsProps{
    children: any
    routes: any[]
}

export const Tabs = ({children, routes}: TabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goTo = (route: string) => {
    navigate(route);
  };

  const isActive = (route: string) => {
    return useMatch(`/:organizationId/:module/${route}`) ? true : false;
  };

  return (
    <Wrapper>
      <TabBarWrapper>
        {
          routes.map((route: any, index: number) => {
            return (
              <TabButton 
                isActive={isActive(route.path)}
                onClick={() => goTo(route.path)}
                key={index}
              >
                {route.title}
              </TabButton>
            );
          })
        }
      </TabBarWrapper>
      <TabsContainer>
        <Tab>
          { children }
        </Tab>
      </TabsContainer>
    </Wrapper>
  );
};