import { currencies } from '../config/currencies';

const actualCurrency = 'AR'; //This must be changed in the future for a user selected currency

export const useFormat = function (text: string, type = 'allCaps') {
  switch(type){
  case 'allCaps':
    return text
      .split(' ')
      .map((word) => {
        return word.substring(0,1).toUpperCase()+word.substring(1);
      })
      .join(' ');
  case 'firstCap':
    return text.substring(0,1).toUpperCase()+text.substring(1);
  case '$':
    return currencies[actualCurrency]+text;
  }
};