import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { getIncomesAmountByConcept } from '../../../../services/transactionsService';
import { ChartWidget } from '../../../../components/ChartWidget';
import { PieChart } from '@mui/x-charts';

export const IncomesAmountByConcept = () => {
  const { id: organizationId } = useAppSelector((state) => state.organization);
  const [incomes, setIncomes] = useState([]);

  const fetchIncomes = async () => {
    const response = await getIncomesAmountByConcept(organizationId, {});
    setIncomes(response.data);
  };

  useEffect(() => {
    fetchIncomes();
  }, [organizationId]);

  return(
    <ChartWidget title="Ventas por concepto">
      <PieChart
        series={[
          {
            data: [
              ...incomes.map((income: any) => ({
                value: Number(income.amount),
                label: income.concept,
              }))
            ],
            innerRadius: 50,
            outerRadius: 120,
            paddingAngle: 2,
            cornerRadius: 5,
            cx: '30%',
          }
        ]}
        height={300}
        slotProps={
          {
            legend: {
              labelStyle: {
                fontSize: 10
              }
            }
          }
        }
      />
    </ChartWidget>
  );
};