const units = [
  'u',
  'kg',
  'g',
  'mg',
  'l',
  'ml',
  'h',
];

export { units };