const modules = [
  {
    id: 2,
    name: 'products',
  },
  {
    id: 3,
    name: 'clients',
  },
  {
    id: 4,
    name: 'resources',
  },
  {
    id: 5,
    name: 'suppliers',
  },
  {
    id: 6,
    name: 'stock',
  },
  {
    id: 7,
    name: 'prices',
  },
  {
    id: 8,
    name: 'transactions',
  }
];

export default modules;