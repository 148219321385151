import React, { createContext, useEffect, useState } from 'react';
import { useMyContext } from '../../../store/Context';
import { TableBarContainer, TableCell, TableColumnHeader, TableContainer, TableEnvironment, TableRow, TableTextFilter, TableTitle } from '../../../components/Table';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getProducts } from '../../../store/reducers/productsSlice';
import { Loader } from '../../../components/Loader';
import PriceMakerEditor from '../PriceMakerEditor';
import { getResources } from '../../../store/reducers/resourcesSlice';
import { getPriceModifiers } from '../../../store/reducers/priceModifiersSlice';
import { createPriceMaker, deletePriceMaker, getPriceMakers, setItemOnEdition, updatePriceMaker } from '../../../store/reducers/priceMakersSlice';
import { TableButton } from '../../../components/Table/TableButton';
import { useSort } from '../../../hooks/useSort';
import { EditIconButton } from '../../../components/CustomIconButton/EditIconButton';
import { CloneIconButton } from '../../../components/CustomIconButton/CloneIconButton';
import { DeleteIconButton } from '../../../components/CustomIconButton/DeleteIconButton';
export const ReferencesContext = createContext({products: [], resources: [], priceModifiers: []});

export default function PriceMakersList() {

  const lang = useMyContext();
  const dispatch = useAppDispatch();
  const sortByField = useSort;
  
  const getFormattedPriceMakers = () => {
    return initialPriceMakers.map((priceMaker: any) => {
      return {
        ...priceMaker,
        product: products.find((product: any) => product.id == priceMaker.productId)?.shortDescription ?? ''
      };
    });
  };

  const getFormattedProductReferences = () => {
    if(products.length === 0) return [];
    return initialPriceMakers.map((priceMaker: any) => {
      const product = products.find((product: any) => product.id === priceMaker.productId);
      return {
        id: priceMaker.id,
        description: product.shortDescription + ' - ' + priceMaker.description,
        value: priceMaker.value,
      };
    });
  };

  const getFormattedResourcesReferences = () => {
    if(!resources) return [];
    return resources.map((resource: any) => {
      return {
        id: resource.id,
        description: resource.name,
        value: resource.price
      };
    });
  };

  const { id: organizationId } = useAppSelector((state) => state.organization);
  const { priceMakers: initialPriceMakers, fetchingStatus, itemOnEdition } = useAppSelector((state) => state.priceMakers);
  const { products } = useAppSelector((state) => state.products);
  const { resources } = useAppSelector((state) => state.resources);
  const { priceModifiers } = useAppSelector((state) => state.priceModifiers);

  const [priceMakers, setPriceMakers] = useState<any>([]);
  const [filteredPriceMakers, setFilteredPriceMakers] = useState<any>([]);
  const [references, setReferences] = useState<any>();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [textFilter, setTextFilter] = useState('');
  const [orderField, setOrderField] = useState({
    field: 'description',
    isDesc: false,
  });
  
  useEffect(() => {
    fetchReferences();
  }, [organizationId]);
  
  const fetchReferences = async () => {
    await dispatch(getPriceMakers()).unwrap();
    await dispatch(getProducts()).unwrap();
    await dispatch(getResources()).unwrap();
    await dispatch(getPriceModifiers()).unwrap();
  };
  
  useEffect(() => {
    setReferences({
      products: getFormattedProductReferences(),
      resources: getFormattedResourcesReferences(),
      priceModifiers
    });
  }, [resources, priceModifiers, products, initialPriceMakers]);

  useEffect(() => {
    setPriceMakers(
      getFormattedPriceMakers()
    );
  }, [initialPriceMakers, products]);

  useEffect(() => {
    setFilteredPriceMakers(
      priceMakers
        .filter((priceMaker:any) => priceMaker.product.toLowerCase().match(textFilter.toLowerCase()) ||  priceMaker.description.toLowerCase().match(textFilter.toLowerCase()))
        .sort((a: any, b: any) => sortByField(a,b, orderField.field, orderField.isDesc))
    );
  }, [priceMakers, textFilter, orderField]);

  const openCreator = () => {
    setIsEditorOpen(true);
  };

  const editItem = (id: number) => {
    dispatch(setItemOnEdition(id));
    setIsEditorOpen(true);
  };

  const closeEditor = () => {
    dispatch(setItemOnEdition(null));
    setIsEditorOpen(false);
  };

  const onSave = (priceMaker: any) => {
    if(itemOnEdition){
      dispatch(updatePriceMaker({priceMaker}));
    } else {
      dispatch(createPriceMaker({priceMaker}));
    }
    dispatch(setItemOnEdition(null));
    setIsEditorOpen(false);
  };

  const deleteItem = (id: number) => {
    dispatch(deletePriceMaker({id}));
  };

  const cloneItem = (id: number) => {
    const priceMaker = priceMakers.find((priceMaker: any) => priceMaker.id === id);
    dispatch(createPriceMaker({ priceMaker: {
      ...priceMaker,
      description: priceMaker.description + ' (copia)',
    }}));
  };

  return (
    <TableEnvironment>
      <TableTitle>{lang.prices.priceMakers}</TableTitle>
      <TableBarContainer>
        <TableTextFilter
          setter={setTextFilter}
        />
        <TableButton text={lang.prices.new} onClick={openCreator}/>
      </TableBarContainer>
      <Loader fetchingStatus={fetchingStatus}>
        <TableContainer>
          <TableRow gridLayout='2fr 3fr 1fr 1fr'>
            <TableCell>
              <TableColumnHeader
                name='product'
                text={lang.attributes.product} 
                isActive={orderField.field ==='product'} 
                isDesc={orderField.isDesc} 
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='description'
                text={lang.attributes.description} 
                isActive={orderField.field ==='description'} 
                isDesc={orderField.isDesc} 
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                text={lang.attributes.value} 
              />
            </TableCell>
          </TableRow>
          {
            filteredPriceMakers.map((priceMaker: any) => {
              return (
                <TableRow gridLayout='2fr 3fr 1fr 1fr' key={priceMaker.id}>
                  <TableCell>
                    {priceMaker.product}
                  </TableCell>
                  <TableCell>
                    {priceMaker.description}
                  </TableCell>
                  <TableCell>
                    {priceMaker.value}
                  </TableCell>
                  <TableCell>
                    <EditIconButton onClick={() => editItem(priceMaker.id)} />
                    <CloneIconButton onClick={() => cloneItem(priceMaker.id)} />
                    <DeleteIconButton onClick={() => deleteItem(priceMaker.id)} />
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableContainer>
        <ReferencesContext.Provider value={references}>
          <PriceMakerEditor
            display={isEditorOpen}
            title={lang.prices.priceMaker}
            close={closeEditor}
            save={onSave}
          />
        </ReferencesContext.Provider>
      </Loader>
    </TableEnvironment>
  );
}
