import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ReferencesContext } from '../PriceMakersList';

const PriceMakerReferenceContainer = styled.div`
  width: 90%;
  box-sizing: border-box;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 3fr;
`;

const PriceMakerReferenceTypeSelect = styled.select`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  padding: 4px;
  box-sizing: border-box;
  border-width: 1px 0 1px 1px;
  text-align: right;
`;

const PriceMakerReferenceValueSelect = styled.select`
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px 0;
  padding: 4px;
  box-sizing: border-box;
  border-width: 1px 1px 1px 0px;
  font-size: 1.05rem;
`;

interface PriceMakerReference{
  type: string
  id: number
  onChange: any
}

export const PriceMakerReference = ({type, id, onChange}: PriceMakerReference) => {

  const [reference, setReference] = useState<Array<any>>();
  const references = useContext(ReferencesContext);
  const [options, setOptions] = useState<any[]>([]);
  
  useEffect(() => {
    let collection: any[];
    if(type === 'product'){
      collection = references.products;
    } else if (type === 'resource') {
      collection = references.resources;
    } else if (type === 'modifier') {
      collection = references.priceModifiers;
    } else {
      collection = [];
    }
    setOptions(
      collection?.map((item: any) => {
        return {
          value: item.id,
          description: item.description,
        };
      })
    );
  }, [type, references]);

  useEffect(() => {
    if(options && reference){
      if(id === null){
        setReference([reference[0], options[0]?.value]);
      }
    }
  }, [id, options]);

  useEffect(() => {
    setReference([type, id]);
  }, [type, id]);

  const handleTypeChange = (e: any) => {
    setReference(
      [e.target.value, null]
    );
  };

  const handleValueChange = (value: any) => {
    if(reference){
      setReference(
        [reference[0], value]
      );
    }
  };

  useEffect(() => {
    let completedReference;
    if(reference && options){
      if(options[0]){
        completedReference = reference[1]? reference : [reference[0], options[0].value];
      }
    } else {
      completedReference = reference;
    }
    onChange(completedReference);
  }, [reference, options]);

  return (
    <PriceMakerReferenceContainer>
      <PriceMakerReferenceTypeSelect value={type} onChange={handleTypeChange}>
        <option value="product">Producto</option>
        <option value="resource">Recurso</option>
        <option value="modifier">Modificador</option>
      </PriceMakerReferenceTypeSelect>
      <PriceMakerReferenceValueSelector
        options={options}
        value={id}
        onSelect={handleValueChange}
      />
    </PriceMakerReferenceContainer>
  );
};

interface PriceMakerReferenceValueSelectorProps{
  options: any,
  value: number,
  onSelect: any,
}

const PriceMakerReferenceValueSelector = ({options, value, onSelect}: PriceMakerReferenceValueSelectorProps) => {

  const handleChange = (e: any) => {
    onSelect(e.target.value);
  };

  useEffect(() => {
    if(options){
      if(options.length > 0){
        if(value === null){
          onSelect(options[0].value);
        }
      }
    }
  }, [options, value]);

  return (
    <PriceMakerReferenceValueSelect value={value?? ''} onChange={handleChange}>
      {
        options?.map((option: any) => {
          return (
            <option key={option.value} value={option.value}>{option.description}</option>
          );
        })
      }
    </PriceMakerReferenceValueSelect>
  );
};