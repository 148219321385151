import React from 'react';
import styled from 'styled-components';

const ToolbarContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export default function PricesListDetailToolbar({children}: any) {

  return (
    <ToolbarContainer>
      { children }
    </ToolbarContainer>
  );
}
