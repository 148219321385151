import { AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';
import LapseFilter from '../../../components/LapseFilter/LapseFilterBase';
import { Loader } from '../../../components/Loader';
import { QueryTextFilter } from '../../../components/QueryTextFilter';
import { TableBarAccordion, TableBarContainer, TableCell, TableColumnHeader, TableContainer, TableEnvironment, TableRow } from '../../../components/Table';
import { TablePagination } from '../../../components/Table/TablePagination';
import { useMyContext } from '../../../store/Context';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteExpense, getExpenses } from '../../../store/reducers/expensesSlice';
import { setExpensesSearchParams } from '../../../store/reducers/expensesSlice';
import { DeleteIconButton } from '../../../components/CustomIconButton/DeleteIconButton';
import { format, parseISO, set } from 'date-fns';
import { EditIconButton } from '../../../components/CustomIconButton/EditIconButton';
import { ExpenseEditor } from './ExpenseEditor';

export default function ExpensesList(){

  const lang = useMyContext();
  const dispatch = useAppDispatch();

  const { expenses, expensesFetchingStatus, lastPage, isSynchronized, searchParams } = useAppSelector((state) => state.expenses);

  const [orderField, setOrderField] = useState({
    field: 'date',
    isDesc: true,
  });

  const [displayEditor, setDisplayEditor] = useState<boolean>(false);
  const [editedItem, setEditedItem] = useState<any>(null);

  useEffect(() => {
    dispatch(setExpensesSearchParams({
      ...searchParams,
      orderBy: orderField.field,
      order: orderField.isDesc? 'desc' : 'asc',
    }));
  }, [orderField]);

  useEffect(() => {
    dispatch(getExpenses({searchParams}));
  }, [searchParams]);

  useEffect(() => {
    if(!isSynchronized){
      dispatch(getExpenses({searchParams}));
    }
  }, [isSynchronized]);
  
  const handleEdit = (expenseId: number): void => {
    if (!expenseId) return;
    setEditedItem(expenses.find((expense: any) => expense.id === expenseId));
    setDisplayEditor(true);
  };

  const handleTermSearchChange = (searchTerm: string) => {
    dispatch(setExpensesSearchParams({
      ...searchParams,
      searchTerm,
      page: 1,
    }));
  };

  const handlePageSelect = (e: any, page: number) => {
    dispatch(setExpensesSearchParams({
      ...searchParams,
      page,
    }));
  };
  const handleDelete = (expenseId: number): void => {
    dispatch(deleteExpense({expenseId}));
  };

  const handleLapseFilterChange = (lapse: any) => {
    dispatch(setExpensesSearchParams({
      ...searchParams,
      after: lapse.after,
      before: lapse.before,
      isLapseActive: true,
    }));
  };

  const setIsLapseActive = (value: boolean) => {
    dispatch(setExpensesSearchParams({
      ...searchParams,
      isLapseActive: value,
    }));
  };

  const handleCloseEditor = () => {
    setEditedItem(null);
    setDisplayEditor(false);
  };

  return (
    <TableEnvironment>
      <TableBarContainer>
        <TableBarAccordion>
          <AccordionSummary
            expandIcon={<MdOutlineExpandMore />}
          >
            {lang.searchFilters}
          </AccordionSummary>
          <AccordionDetails>
            <QueryTextFilter
              onTermSearch={handleTermSearchChange}
            />
            <LapseFilter
              onChange={handleLapseFilterChange}
              lapse={{
                after: searchParams.after,
                before: searchParams.before,
              }}
              isActive={searchParams.isLapseActive}
              setIsActive={setIsLapseActive}
            />
          </AccordionDetails>
        </TableBarAccordion>
      </TableBarContainer>
      <Loader fetchingStatus={expensesFetchingStatus}>
        <TableContainer>
          <TableRow gridLayout='2fr 1fr 4fr 1fr 1fr'>
            <TableCell>
              <TableColumnHeader
                name='date'
                text={lang.attributes.date}
                isActive={orderField.field === 'date'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='type'
                text={lang.attributes.type}
                isActive={orderField.field === 'type'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='concept'
                text={lang.attributes.concept}
                isActive={orderField.field === 'concept'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='value'
                text={lang.attributes.value}
                isActive={orderField.field === 'value'}
                isDesc={orderField.isDesc}
                setOrder={setOrderField}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                text={lang.attributes.options}
              />
            </TableCell>
          </TableRow>
          {
            expenses.map((expense: any) => {
              return (
                <TableRow gridLayout='2fr 1fr 4fr 1fr 1fr' key={expense.id}>
                  <TableCell>
                    {format(parseISO(expense.date), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>{lang.transactions.expenses.types[expense.type] || expense.type}</TableCell>
                  <TableCell>
                    {expense.concept}
                  </TableCell>
                  <TableCell>
                    {expense.value}
                  </TableCell>
                  <TableCell>
                    <EditIconButton onClick={() => handleEdit(expense.id)} />
                    <DeleteIconButton onClick={() => handleDelete(expense.id)} />
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableContainer>
      </Loader>
      <ExpenseEditor 
        data={editedItem}
        display={displayEditor}
        setDisplay={handleCloseEditor}
      />
      <TablePagination count={lastPage} page={searchParams.page} onChange={handlePageSelect}/>
    </TableEnvironment>
  );
}
