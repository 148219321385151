import React, { useEffect, useState } from 'react';
import { DashboardContainer, Sidebar, SidebarItem, Content } from './styles';
import modules from '../../config/modules';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchPermissions } from '../../store/reducers/userSlice';
import { Route, Routes } from 'react-router-dom';
import { Products } from '../../modules/Products';
import { Suppliers } from '../../modules/Suppliers';
import { useContext } from 'react';
import { Context } from '../../store/Context';
import { useFormat } from '../../hooks/useFormat';
import { Resources } from '../../modules/Resources';
import Prices from '../../modules/Prices';
import { Stock } from '../../modules/Stock';
import Transactions from '../../modules/Transactions';
import Clients from '../../modules/Clients';

export function Navigator (){

  const dispatch = useAppDispatch();
  const format = useFormat;
  const lang = useContext(Context);

  const { permissions, permissionsFetchingStatus } = useAppSelector((state) => state.user);
  const { ...organization } = useAppSelector((state) => state.organization);

  const getPermissions = () => {
    dispatch(fetchPermissions(organization.id));
  };

  const [activeModules, setActiveModules]: any[] = useState([]);

  useEffect(()=> {
    if(organization.id){
      if(organization.id !== null && permissions.length === 0){
        getPermissions();
      }
    }
  }, [organization]);

  useEffect(() => {
    if(permissions.length > 0){
      const tempActiveModules = modules.filter((module) => {
        const modulePermissions = permissions.find((permission) => permission.module_id === module.id);
        const moduleLicense = organization.licenses.find((license) => license.module_id === module.id);
        if(modulePermissions && moduleLicense){
          return modulePermissions.permission_level > 0 && new Date(moduleLicense.expires) > new Date();
        }
        return false;
      });
      setActiveModules(tempActiveModules); 
    }
  }, [permissions]);

  return (
    <>
      {
        permissionsFetchingStatus === 'success' &&
          <DashboardContainer>
            <Sidebar>
              <SidebarItem to='home'>{format(lang.start)}</SidebarItem>
              {
                activeModules.map((module:any) => {
                  return(
                    <SidebarItem key={module.id} to={module.name}>
                      {format(lang.modules[module.name])}
                    </SidebarItem>
                  );
                })
              }
              <SidebarItem to='settings'>{format(lang.settings)}</SidebarItem>
            </Sidebar>
            <Content>
              <Routes>
                <Route path='products' element={<Products/>} />
                <Route path='clients' element={<Clients/>} />
                <Route path='resources' element={<Resources/>} />
                <Route path='suppliers' element={<Suppliers/>} />
                <Route path='prices/*' element={<Prices/>} />
                <Route path='stock/*' element={<Stock/>} />
                <Route path='transactions/*' element={<Transactions/>} />
              </Routes>
            </Content>
          </DashboardContainer> 
      }
      {
        permissionsFetchingStatus === 'failed' &&
          <DashboardContainer>
            <div>Hubo un error al intentar obtener los datos</div>
            <button onClick={getPermissions}>Reintentar</button>
          </DashboardContainer>

      }
    </>
  );
}