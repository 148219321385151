import styled from '@emotion/styled';
import React from 'react';

interface StyledPriceMakerValueProps {
  type: string
}

const StyledPriceMakerValue = styled.input<StyledPriceMakerValueProps>`
  width: 90%;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px grey;
  text-align: right;
  ${props => props.type === 'compact'?
    `
      width: 20%;
    ` : ''
}
`;

interface PriceMakerValueProps{
  value: number
  onChange: any
  type?: string
}

export const PriceMakerValue = ({value, onChange, type = ''}: PriceMakerValueProps) => {

  const handleChange = (e: any) => {
    onChange(['value', e.target.value]);
  };

  return (
    <StyledPriceMakerValue
      value={value?? ''}
      onChange={handleChange}
      type={type}
    />
  );
};