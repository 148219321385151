import React from 'react';
import styled from '@emotion/styled';
import { FiArrowDownCircle } from 'react-icons/fi';

const StyledTopMenu = styled.div`
    width: 100%;
    height: 60px;
    padding: 8px;
    box-sizing: border-box;
    box-shadow: 0 10px 10px 10px rgba(0,0,0,0.5);
`;

export const TopMenuContainer = ({ children } : any) => {
  return (
    <StyledTopMenu>
      { children }
    </StyledTopMenu>
  );
};

const StyledTopMenuBar = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const TopMenuBar = ({ children } : any) => {
  return (
    <StyledTopMenuBar>
      {children}
    </StyledTopMenuBar>
  );
};

