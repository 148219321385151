import api from './config/api';

export const getIncomes = async (organizationId: number, searchParams: any) => {
  let lapse;
  if(searchParams.isLapseActive){
    lapse = {
      after: searchParams.after,
      before: searchParams.before,
    };
  }
  const params = {
    search_term: searchParams.searchTerm?? '',
    limit: searchParams.limit?? 10,
    page: searchParams.page?? 1,
    order_by: searchParams.orderBy?? 'date',
    order: searchParams.order?? 'desc',
    ...lapse,
  };
  const response = await api.get(`/${organizationId}/incomes`, {params});
  return response.data;
};

/**
 * It creates an income for a given organization
 * @param {number} organizationId The id of the organization that the income belongs to.
 * @param {any} data - The data to be sent to the server.
 * @returns The income data
 */
export const createIncome = async (organizationId: number, data: any) => {
  const income = {
    ...data,
    client_id: data.clientId,
  };
  const response = await api.post(`/${organizationId}/incomes`, income);
  return response.data;
};

/**
 * It updates an income for a given organization
 * @param {number} organizationId - number - The id of the organization that the income belongs to.
 * @param {number} id - The id of the income you want to update.
 * @param {any} data - any - Income data for updating.
 * @returns The updated income data.
 */
export const updateIncome = async (organizationId: number, id: number, data: any) => {
  const response = await api.put(`/${organizationId}/incomes/${id}`, data);
  return response.data;
};

/**
 * It makes a DELETE request to the API endpoint `/:organizationId/incomes/:id` and
 * returns the response data
 * @param {number} organizationId - number - The id of the organization that the income belongs to.
 * @param {number} id - The id of the income you want to delete.
 * @returns The deleted income data.
 */
export const deleteIncome = async (organizationId: number, id: number) => {
  const response = await api.delete(`${organizationId}/incomes/${id}`);
  return response.data;
};

export const getIncomesAmount = async (organizationId: number, searchParams: any) => {
  const response = await api.get(`${organizationId}/incomes/amount`, {params: searchParams});
  return response.data;
};

export const getMonthlyIncomesAmount = async (organizationId: number, searchParams: any) => {
  const response = await api.get(`${organizationId}/incomes/amount/monthly`, {params: searchParams});
  return response.data;
};

export const getIncomesAmountByClient = async (organizationId: number, searchParams: any) => {
  const response = await api.get(`${organizationId}/incomes/amount/client`, {params: searchParams});
  return response.data;
};

export const getIncomesAmountByConcept = async (organizationId: number, searchParams: any) => {
  const response = await api.get(`${organizationId}/incomes/amount/concept`, {params: searchParams});
  return response.data;
};

/**
 * It makes a GET request to the /expenses/ endpoint of the API, and returns the data that comes back
 * @param {number} organizationId - number - The id of the organization that the expenses belong to.
 * @param {any} searchParams
 * @returns An array of expense objects.
 */
export const getExpenses = async (organizationId: number, searchParams: any) => {
  let lapse;
  if(searchParams.isLapseActive){
    lapse = {
      after: searchParams.after,
      before: searchParams.before,
    };
  }
  const params = {
    search_term: searchParams.searchTerm?? '',
    limit: searchParams.limit?? 10,
    page: searchParams.page?? 1,
    order_by: searchParams.orderBy?? 'date',
    order: searchParams.order?? 'desc',
    ...lapse,
  };
  const response = await api.get(`/${organizationId}/expenses`, {params});
  return response.data;
};

/**
 * It creates an expense for a given organization
 * @param {number} organizationId - number - The ID of the organization that the expense belongs to.
 * @param {any} data - The expense data.
 * @returns The created expense data.
 */
export const createExpense = async (organizationId: number, data: any) => {
  const response = await api.post(`/${organizationId}/expenses`, data);
  return response.data;
};

/**
 * It makes a PUT request to the /expenses/:id endpoint, passing in the organizationId and id as URL
 * parameters, and the data as the request body
 * @param {number} organizationId - number - The ID of the organization that the expense belongs to.
 * @param {number} id - The id of the expense you want to update.
 * @param {any} data - The data for updating on the expense
 * @returns The updated expense object.
 */
export const updateExpense = async (organizationId: number, id: number, data: any) => {
  const response = await api.put(`/${organizationId}/expenses/${id}`, data);
  return response.data;
};

/**
 * It makes a DELETE request to the API endpoint /:organizationId/expenses/:id and
 * returns the deleted item
 * @param {number} organizationId - The id of the organization that the expense belongs to.
 * @param {number} id - The id of the expense to delete
 * @returns The deleted item data.
 */
export const deleteExpense = async (organizationId: number, id: number) => {
  const response = await api.delete(`/${organizationId}/expenses/${id}`);
  return response.data;
};

export const getExpensesAmount = async (organizationId: number, searchParams: any) => {
  const response = await api.get(`${organizationId}/expenses/amount`, {params: searchParams});
  return response.data;
};

export const getMonthlyExpensesAmount = async (organizationId: number, searchParams: any) => {
  const response = await api.get(`${organizationId}/expenses/amount/monthly`, {params: searchParams});
  return response.data;
};

export const getExpensesAmountByType = async (organizationId: number, searchParams: any) => {
  const response = await api.get(`${organizationId}/expenses/amount/type`, {params: searchParams});
  return response.data;
};
