import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Input } from '../inputs';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Helpers = styled.div`
  min-width: 200px;
`;

const Pickers = styled.div`
  
`;

const Calendar = styled.div`
  display: flex;
  margin-top: 2px;
  label {
    margin-right: 15px;
  }
`;

interface LapseFilterProps{
  lapse: any
  onChange: any
  isActive: boolean
  setIsActive: any
}

interface Lapse{
  after: string
  before: string
}

const LapseFilterBase = ({lapse, onChange, isActive, setIsActive}: LapseFilterProps) => {
  
  const handleActivation = (value: boolean) => {
    if(setIsActive){
      setIsActive(value);
    }
  };
  
  const handleChange = (field: string, e: any) => {
    onChange({
      ...lapse,
      [field]: e.target.value
    });
  };

  const handleCheckboxChange = (e: any) => {
    e.target.checked?
      handleActivation(true)
      :
      handleActivation(false);
  };
  
  return (
    <Wrapper>
      {
        setIsActive &&
          <Helpers>
            <input type='checkbox' name='lapse' value='Activo' onChange={handleCheckboxChange} checked={isActive}/>
            <label>Filtrar por fecha</label>
          </Helpers>
      }
      <Pickers>
        <Calendar>
          <label>Desde</label>
          <Input value={lapse.after} onChange={(e) => handleChange('after', e)} type='date' name='after'/>
        </Calendar>
        <Calendar>
          <label>Hasta</label>
          <Input value={lapse.before} onChange={(e) => handleChange('before', e)} type='date' name='before'/>
        </Calendar>
      </Pickers>
    </Wrapper>
  );
};

export default LapseFilterBase;