export const useGenerateFormula = () => (parsedFormula: any[]) => {
  const stringify = (parsedFormula: any) => {
    return parsedFormula?.map((item: any) => {
      switch(item[0]){
      case 'operator':
      case 'value':
        return item[1];
      case 'resource':
        return `#Resource:${item[1]}`;
      case 'product':
        return `#Product:${item[1]}`;
      case 'modifier':
        return `#PriceModifier:${item[1]}`;
      case 'group':
      case 'monomial':
        return  `(${stringify(item[1])})`;
      }
    }).join('');
  };
  return stringify(parsedFormula);
};