import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';
import { requestStatus } from '../../constants/requestStatus';
import { useMyContext } from '../../store/Context';
import { BiErrorCircle } from 'react-icons/bi';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const StatusMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10% 0;
  ${props => props.color? `color: ${props.color};` : ''}
`;

const FailIcon = styled(BiErrorCircle)`
  width: 40px;
  height: 40px;
`;

interface LoaderProps{
  fetchingStatus: string
  children: any
}

export const Loader = ({fetchingStatus, children}: LoaderProps) => {

  const lang = useMyContext();
  
  return (
    <Container>
      {
        fetchingStatus === requestStatus.PENDING &&
        <Content>
          <StatusMessage>
            <TailSpin
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            <p>{lang.components.loader.loadingMessage}</p>
          </StatusMessage>
        </Content>
      }
      {
        fetchingStatus === requestStatus.SUCCESS &&
        <Content>
          {children}
        </Content>
      }
      {
        fetchingStatus === requestStatus.FAILED &&
        <Content>
          <StatusMessage color="red">
            <FailIcon />
            <p>{lang.components.loader.failMessage}</p>
          </StatusMessage>
        </Content>
      }
    </Container>
  );
};
