import React, { useEffect } from 'react';
import { TopMenu } from '../../components/TopMenu';
import { Navigator } from '../Navigator';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DashboardWrapper } from './Styles';
import { getOrganization } from '../../store/reducers/organizationSlice';

export function Dashboard () {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { organizationId } = useParams();

  const { email: userEmail } = useAppSelector((state) => state.user);
  
  const { isSetted: isOrganizationSetted } = useAppSelector((state) => state.organization);

  useEffect(() => {
    if(userEmail !== null){
      if(organizationId){
        dispatch(getOrganization(Number(organizationId)));
      }
      else if(!isOrganizationSetted){
        navigate('/organization_selection');
      }
    }
  }, [userEmail]);

  return (
    <DashboardWrapper>
      <TopMenu />
      <Navigator />
    </DashboardWrapper>
  );
}