import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { ArrowButton } from '../ArrowButton';
import { BsTrash } from 'react-icons/bs';
import { colors } from '../../styles/variables';
import { CustomIconButton } from '../CustomIconButton';
import { TagsBox } from '../Tags';
import { useMyContext } from '../../store/Context';

const Wrapper = styled.div`
    width: 100%;
    height: 40px;
    display: grid;
    position: relative;
`;
    
const TagsDisplayBar = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 40px;
    background-color: ${colors.softHighlight};
    align-items: center
`;  

interface DropdownContainerProps{
    $display: boolean
}
const DropdownContainer = styled.div<DropdownContainerProps>`
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0px;
    display: ${props => props.$display? 'inherit' : 'none'};
    padding: 1vw;
    box-sizing: border-box;
    background-color: ${colors.softHighlight};
`;

const DropdownList = styled.div`
    width: 100%;
    max-height: 300px;
    overflow: auto;
`;

const DropdownListItem = styled.div`
    width: 100%;
    height: 30px;
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    :hover {
        background-color: rgba(0,0,0,0.2);
    }
    border-radius: 0.5vw;
    padding: 0.2vw 0;
`;

const ItemClickable = styled.button`
    width: 100%;
    height: 30px;
    font-size: 0.9rem;
    background: none;
    border: none;
`;

const ItemDeleteButton = styled.div`
    width: 30px;
    height: 30px;
    svg{
        width: 100%;
        height: 100%;
    }
`;

const NewTagInput = styled.input`
    width: 100%;
    height: 30px;
    margin: auto;
    backrgound-color: none;
    border: none;
    border-radius: 1vw;
    padding: 0 1vw;
    box-sizing: border-box;
`;

interface TagsPickerProps{
    tags: any[]
    availableTags: any[]
    onRemove: any
    onSelect: any
    onCreate: any
    onDelete: any
}

export const TagsPicker = ({tags, availableTags, onRemove, onSelect, onCreate, onDelete}: TagsPickerProps) => {

  const lang = useMyContext();

  const [newTagValue, setNewTagValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('keydown', (e: any) => {
      if(e.keyCode === 13){
        e.preventDefault();
      }
    });
  });

  const switchDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onInputChange = (e: any) => {
    setNewTagValue(e.target.value);
  };

  const handleClick = (id: number, e: any) => {
    e.preventDefault();
    onSelect(id);
    switchDropdown();
  };

  const handleDelete = (id: number, e: any) => {
    e.preventDefault();
    onDelete(id);
  };

  const createTag = (e: any) => {
    if (e.keyCode === 13) {
      onCreate(newTagValue);
      setNewTagValue('');
    }
  };
    
  return (
    <Wrapper>
      <TagsDisplayBar onClick={switchDropdown}>
        <TagsBox 
          tags={tags}
          removable={true}
          action={onRemove}
        />
        <ArrowButton twisted={isDropdownOpen} onClick={switchDropdown}/>
      </TagsDisplayBar>
      <DropdownContainer $display={isDropdownOpen}>
        <DropdownList>
          {
            availableTags.map((tag: any) => {
              return (
                <DropdownListItem key={tag.id}>
                  <ItemClickable onClick={(e) => handleClick(tag.id, e)}>{tag.name}</ItemClickable>
                  <ItemDeleteButton>
                    <CustomIconButton>
                      <BsTrash
                        onClick={(e) => handleDelete(tag.id, e)}
                      />
                    </CustomIconButton>
                  </ItemDeleteButton>
                </DropdownListItem>
              );
            })
          }
        </DropdownList>
        <NewTagInput 
          type="text"
          value={newTagValue}
          onChange={onInputChange}
          onKeyUp={createTag}
          placeholder={lang.components.tagsPicker.inputPlaceholder}
        />
      </DropdownContainer>
    </Wrapper>
  );
};

