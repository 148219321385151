import React from 'react';
import styled from '@emotion/styled';

export const ChartsContainer = ({children }: {
  children: React.ReactNode,
}) => {
  return (
    <Container>
      { children }
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;