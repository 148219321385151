import styled from '@emotion/styled';
import { colors } from '../../../../styles/variables';

interface ProfileMenuProperties {
    $display: boolean,
}

export const ProfileMenuContainer = styled.div<ProfileMenuProperties>`
    width: 20%;
    min-width: 200px;
    max-width: 350px;
    display: ${ props => props.$display? 'inherit' : 'none'};
    position: absolute;
    top: 70px;
    right: 10px;
    border: 2px solid ${colors.softHighlight};
    border-radius: 15px;
    background-color: white;

    li {
        list-style: none;
        margin-top: 5px;
        border-radius: 10px;
        padding: 4px;
        box-sizing: border-box;
    }

    li:hover{
        background-color: rgba(0,0,0,0.2);
        cursor: pointer;
    }
`;