import styled from '@emotion/styled';
import { colors } from '../../../../../styles/variables';

export const OperatorAggregatorMiniButton = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  background-color: ${colors.softHighlight};
  border: none;
  :hover{
    background-color: ${colors.secondary};
    cursor: pointer;
  }
`;