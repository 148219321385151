
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestStatus } from '../../constants/requestStatus';
import {
  getPricesLists as getPricesListService,
  getPricesList as getPriceListService,
  createPricesList as createPriceListService,
  updatePricesList as updatePricesListService,
  deletePricesList as deletePricesListService,
  getPricesGroup as getPricesGroupService,
  createPricesGroup as createPricesGroupService,
  updatePricesGroup as updatePricesGroupService,
  deletePricesGroup as deletePricesGroupService,
} from '../../services/pricesListsService';

interface State {
  pricesLists: any[],
  fetchingStatus: string,
  creationStatus: string,
  searchParams: SearchParams,
}

interface SearchParams{
  page: number,
  limit: number,
  orderBy: string,
  order: string,
}

const initialState: State = {
  pricesLists: [],
  fetchingStatus: requestStatus.READY,
  creationStatus: requestStatus.READY,
  searchParams: {
    page: 1,
    limit: 10,
    orderBy: 'title',
    order: 'DESC'
  }
};

export const getPricesLists = createAsyncThunk(
  'getPricesLists',
  async (_, { getState }) => {
    const { organization: { id: organizationId }, incomes: { searchParams }} = getState() as any;
    const response = await getPricesListService(organizationId, searchParams);
    return response.data;
  }
);

export const deletePricesList = createAsyncThunk(
  'deletePricesList',
  async (id: number, { getState }) => {
    const { organization: { id: organizationId } } = getState() as any;
    const response = await deletePricesListService(organizationId, id);
    return response.data;
  }
);

export const createEmptyPricesList = createAsyncThunk(
  'createEmptyPricesList',
  async (_, { getState }) => {
    const { organization: { id: organizationId } } = getState() as any;
    const data = {
      title: 'No title',
      date: new Date().toISOString().slice(0, 10),
    };
    const response = await createPriceListService(organizationId, data);
    console.log(response.data);
    return response.data;
  }
);

export const updatePricesList = createAsyncThunk(
  'updatePricesList',
  async (data: any, { getState }) => {
    const { organization: { id: organizationId } } = getState() as any;
    const response = await updatePricesListService(organizationId, data);
    return response.data;
  }
);

const pricesListsSlice = createSlice({
  name: 'pricesLists',
  initialState,
  reducers: {
    setSearchParams: (state, action) => {
      state.searchParams = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPricesLists.pending, (state) => {
      state.fetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(getPricesLists.fulfilled, (state, action) => {
      state.pricesLists = action.payload;
      state.fetchingStatus = requestStatus.SUCCESS;
    });
    builder.addCase(getPricesLists.rejected, (state) => {
      state.fetchingStatus = requestStatus.FAILED;
    });
    builder.addCase(deletePricesList.fulfilled, (state, action) => {
      state.pricesLists = state.pricesLists.filter((pricesList) => pricesList.id !== action.payload.id);
    });
    builder.addCase(createEmptyPricesList.pending, (state) => {
      state.creationStatus = requestStatus.PENDING;
    });
    builder.addCase(createEmptyPricesList.fulfilled, (state, action) => {
      state.pricesLists.push(action.payload);
      state.creationStatus = requestStatus.SUCCESS;
    });
    builder.addCase(createEmptyPricesList.rejected, (state) => {
      state.creationStatus = requestStatus.FAILED;
    });
    builder.addCase(updatePricesList.fulfilled, (state, action) => {
      state.pricesLists = state.pricesLists.map((pricesList) => {
        if (pricesList.id === action.payload.id) {
          return action.payload;
        }
        return pricesList;
      });
    });
  }
});

export const { setSearchParams } = pricesListsSlice.actions;
export default pricesListsSlice.reducer;
