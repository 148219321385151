import React, { useState } from 'react';
import styled from '@emotion/styled';

const Title = styled.input`
  width: 100%;
  font-size: 1.3rem;
  padding: 0.5vh;
  box-sizing: border-box;
  background-color: rgb(200,200,200);
  text-align: center;
  border-radius: 5px;
  border: none;
  margin-bottom: 5px;
`;

interface PriceGroupTitleProps {
  title: string
  onChange: any
  onSave: any
}

export const PricesGroupTitle = ({ title, onChange, onSave }: PriceGroupTitleProps) => {

  const [isSaved, setIsSaved] = useState(true);

  const handleChange = (e: any) => {
    onChange('title', e.target.value);
    setIsSaved(false);
  };

  const handleBlur = () => {
    if(!isSaved){
      onSave();
      setIsSaved(true);
    }
  };

  return (
    <Title type='text' value={title?? ''} onChange={handleChange} onBlur={handleBlur}/>
  );
};
