const useSelectValues = (values: any[], optionFieldName?: string, nameFieldName?: string) => {
  return values.map((value: any) => {
    if(typeof(value) === 'object' && optionFieldName && nameFieldName){
      return {
        option: value[optionFieldName],
        name: value[nameFieldName],
      };
    } else if (typeof(value) === 'string'){
      return {
        option: value,
        name: value,
      };
    }
  });
};

export { useSelectValues };