import React from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { ProfileMenuContainer, ProfileMenuItemsGroup, ProfileMenuGroupsSeparator } from './styles';
import { logout } from '../../../store/reducers/authSlice';
import { clearStore } from '../../../store/actions/clearStore';

interface PropsInterface{
    display: boolean,
    name: string,
}

export function ProfileMenu ({display: $display, name}:PropsInterface) {

  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(clearStore());
  };

  return (
    <ProfileMenuContainer $display={$display}>
      <ProfileMenuItemsGroup>
                ¡Hola, {name}!
      </ProfileMenuItemsGroup>
      <ProfileMenuGroupsSeparator/>
      <ProfileMenuItemsGroup>
        <li><a onClick={handleLogout}>Cerrar sesión</a></li>
      </ProfileMenuItemsGroup>
    </ProfileMenuContainer>
  );

}