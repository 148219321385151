import React from 'react';
import styled from '@emotion/styled';
import { MdOutlineCancel } from 'react-icons/md';
import { colors } from '../../styles/variables';


const TagsContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: start;
align-items: center;
flex-wrap: wrap;
padding: 5px;
box-sizing: border-box;
`;

const Tag = styled.div`
    padding: 4px 6px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 0.8rem;
    margin: 2px;
    background-color: ${colors.secondary};
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const RemoveIcon = styled(MdOutlineCancel)`
    margin-left: 5px;
    width: 15px;
    height: 15px;
`;

interface TagProps{
    name: string,
    id: number,
}

interface TagsBoxProps{
    tags: TagProps[],
    removable?: boolean,
    action?: any,
}

export const TagsBox = ({tags, removable, action}: TagsBoxProps) => {
  return(
    <TagsContainer>
      {
        tags.map((tag: any) => {
          return (
            <Tag key={tag.id}>
              {tag.name}
              {
                removable && action &&
                                <RemoveIcon onClick={() => {action(tag.id);}}/>
              }
            </Tag>
          );
        })
      }
    </TagsContainer>
  );
};