import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import styled from 'styled-components';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 30,
    backgroundColor: 'white'
  },
  brandContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
    gap: 15,
  },
  logoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10
  },
  logo: {
    width: 80,
    height: 80
  },
  brandNameCap: {
    fontSize: 40,
    fontStyle: 'strong',
    textTransform: 'uppercase'
  },
  brandName: {
    fontSize: 25,
    fontStyle: 'strong',
    textTransform: 'uppercase'
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    marginBottom: 10,
    backgroundColor: 'rgb(230, 230, 230)',
    borderRadius: 7,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  pricesGroupContainer: {
    paddingTop: 20,
  },
  pricesGroupTitleContainer: {
    borderBottomWidth: '1px',
    borderBottomColor: 'grey',
    paddingBottom: 3,
  },
  priceRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '12',
    padding: 2,
    paddingLeft: 15,
    marginTop: 4,
    gap: 8,
  },
  pricesRowLine: {
    flexGrow: 1,
    borderBottom: '1px dotted black',
    borderColor: 'grey'
  },
  note: {
    fontSize: 11,
    textAlign: 'center',
    borderTop: '1px solid grey',
    marginTop: 'auto',
  },
  attributionNote: {
    fontSize: 8,
    textAlign: 'center',
    marginTop: 10,
  }
});

interface PricesGroup {
  id: number
  title: string
  prices: any[]
}

interface PricesList {
  id: number
  title: string
  pricesGroups: PricesGroup[],
  note: string
}

interface PricesListPDFViewerPropsInterface {
  pricesList?: PricesList
  organization: any
}

const PDFViewerWrapper = styled<any>(PDFViewer)`
  height: 100%;
`;

const PricesListPDFViewer = ({ pricesList, organization }: PricesListPDFViewerPropsInterface) => {
  return (
    <PDFViewerWrapper >
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.brandContainer}>
            <View style={styles.logoContainer}>
              <Image src={ organization.imageUrl } style={styles.logo}/>
            </View>
            <View>
              <Text>
                <Text style={styles.brandNameCap}>{organization.name.slice(0,1)}</Text>
                <Text style={styles.brandName}>{organization.name.slice(1)}</Text>

              </Text>
            </View>
          </View>
          <View style={{...styles.section, ...styles.title}}>
            <Text>{pricesList?.title}</Text>
          </View>
          <View style={styles.section}>
            {
              pricesList?.pricesGroups?.map((group: any) => {
                return (
                  <View key={group.id} style={styles.pricesGroupContainer}>
                    <Text style={styles.pricesGroupTitleContainer}>{group.title}</Text>
                    {
                      group.prices?.map((price: any) => {
                        return (
                          <View key={price.id} style={styles.priceRow}>
                            <Text>{price.product.shortDescription}</Text>
                            <View style={styles.pricesRowLine}></View>
                            <Text>{price.result}</Text>
                          </View>
                        );
                      })
                    }
                  </View>
                );
              })
            }
          </View>
          <View style={{...styles.section, ...styles.note}}>
            <Text>{pricesList?.note}</Text>
          </View>
          <View style={{...styles.section, ...styles.attributionNote}}>
            <Text>Lista de precios generada con Duite software</Text>
          </View>
        </Page>
      </Document>
    </PDFViewerWrapper>
  );
};

export default PricesListPDFViewer;
