import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestStatus } from '../../constants/requestStatus';
import { getOrganizationData as getOrganizationDataService } from '../../services/organizationService';
import { clearStore } from '../actions/clearStore';

interface InitialStateInterface{
  isSetted: boolean
  id: number
  name: string
  imageUrl: string
  licenses: any[]
  fetchingStatus: string
}

const initialState: InitialStateInterface = {
  isSetted: false,
  id: 0,
  name: '',
  imageUrl: '',
  licenses: [],
  fetchingStatus: requestStatus.READY,
};

export const getOrganization = createAsyncThunk(
  'organization/getOrganizationData',
  async (organizationId: number) => {
    const response = await getOrganizationDataService(organizationId);
    return response.data;
  }
);

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });

    // Get organization data
    builder.addCase(getOrganization.pending, (state) => {
      state.fetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(getOrganization.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.imageUrl = action.payload.image_url;
      state.licenses = action.payload.licenses;
      state.fetchingStatus = requestStatus.SUCCESS;
      state.isSetted = true;
    });
    builder.addCase(getOrganization.rejected, (state) => {
      state.fetchingStatus = requestStatus.FAILED;
      state.isSetted = false;
    });
  }
});

export default organizationSlice.reducer;