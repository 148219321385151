import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearStore } from '../actions/clearStore';
import {
  getResources as getResourcesService,
  updateResource as updateResourceService,
  createResource as createResourceService,
  deleteResource as deleteResourceService,
} from '../../services/resourcesService';
import { requestStatus } from '../../constants/requestStatus';

interface InitialStateInterface{
    resources: any[]
    resourceOnEdition: any
    resourcesFetchingStatus: string
    resourceFetchingStatus: string
}

const initialState: InitialStateInterface = {
  resources: [],
  resourceOnEdition: null,
  resourcesFetchingStatus: requestStatus.READY,
  resourceFetchingStatus: requestStatus.READY,
};

export const getResources = createAsyncThunk(
  'resources/fetchResources',
  async (_, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getResourcesService(organizationId);
    return response.data;
  }
);

export const createResource = createAsyncThunk(
  'resource/createResource',
  async ({ data }: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await createResourceService(organizationId, data);
    return response.data;
  }
);

export const updateResource = createAsyncThunk(
  'resources/updateResource',
  async ({ resourceId, data }: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await updateResourceService(organizationId, resourceId, data);
    return response.data;
  }
);

export const deleteResource = createAsyncThunk(
  'resources/deleteResource',
  async ({ resourceId }: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await deleteResourceService(organizationId, resourceId);
    if(!response.data.error){
      return resourceId;
    }
    return null;
  }
);

const formatResource = (resource: any) => {
  return {
    id: resource.id,
    name: resource.name,
    price: resource.price,
    stock: resource.stock,
    stockable: resource.stockable,
    unit: resource.unit,
    category: resource.resources_category_id,
  };
};

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setResourceOnEdition: ((state, action: PayloadAction<number|null> ) => {
      if(action.payload){
        state.resourceOnEdition = state.resources.find((resource: any) => resource.id === action.payload);
      } else{
        state.resourceOnEdition = null;
      }
    })
  },
  extraReducers: (builder) => {
    // CLEAR STORE
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });

    // GET RESOURCES
    builder.addCase(getResources.pending, (state) => {
      state.resourcesFetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(getResources.fulfilled, (state, action) => {
      state.resources = action.payload.map((resource: any) => formatResource(resource));
      state.resourcesFetchingStatus = requestStatus.SUCCESS;
    });
    builder.addCase(getResources.rejected, (state) => {
      state.resourcesFetchingStatus = requestStatus.FAILED;
    });

    // CREATE RESOURCE
    builder.addCase(createResource.fulfilled, (state, action) => {
      state.resources = Object.assign(state.resources, state.resources.concat(formatResource(action.payload)));
    });

    // UPDATE RESOURCE
    builder.addCase(updateResource.fulfilled, (state, action) => {
      const updatedItem = formatResource(action.payload);
      state.resources = state.resources.map((resource: any) => 
        resource.id == updatedItem.id
          ? updatedItem
          : resource
      );
    });

    // DELETE RESOURCE

    builder.addCase(deleteResource.fulfilled, (state, action) => {
      state.resources = state.resources.filter((resource: any) => {
        return resource.id != action.payload;
      });
    });

  }
});

export default resourcesSlice.reducer;

export const { setResourceOnEdition } = resourcesSlice.actions;