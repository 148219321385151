import React, { useEffect, useState } from 'react';
import { useMyContext } from '../../store/Context';
import { Wrapper } from '../../components/Wrapper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { 
  TableEnvironment, 
  TableContainer, 
  TableRow, 
  TableCell, 
  TableBarContainer, 
  TableTextFilter, 
  TableCategoryFilter, 
  TableColumnHeader } 
  from '../../components/Table';
import { useFormat } from '../../hooks/useFormat';
import { TableButton } from '../../components/Table/TableButton';
import { ResourceEditor } from './ResourceEditor';
import {
  getResources,
  deleteResource,
  setResourceOnEdition
} from '../../store/reducers/resourcesSlice';
import {
  getCategories
} from '../../store/reducers/resourcesCategoriesSlice';
import { openResourceEditor } from '../../store/reducers/interfaceSlice';
import { useSort } from '../../hooks/useSort';
import { Loader } from '../../components/Loader';
import { EditIconButton } from '../../components/CustomIconButton/EditIconButton';
import { DeleteIconButton } from '../../components/CustomIconButton/DeleteIconButton';

export function Resources () {
  const lang = useMyContext();
  const format = useFormat;
  const dispatch = useAppDispatch();
  const sortByField = useSort;
  
  const { id: organizationId } = useAppSelector((state) => state.organization);

  const { resources, resourcesFetchingStatus } = useAppSelector((state) => state.resources);
  const { categories } = useAppSelector((state) => state.resourcesCategories);

  const [textFilter, setTextFilter] = useState('');
  const [filteredResources, setFilteredResources] = useState<any[]>([]);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>([]);
  const [isCategoryFilterEnabled, setIsCategoryFilterEnabled] = useState(false);

  const [orderField, setOrderField] = useState({
    field: 'name',
    isDesc: true,
  });

  useEffect(() => {
    dispatch(getResources());
    dispatch(getCategories());
  }, [organizationId]);

  useEffect(() => {
    setFilteredResources(
      resources
        .filter((resource:any) => resource.name.toLowerCase().match(textFilter.toLowerCase()))
        .filter((resource:any) => isCategoryFilterEnabled? categoriesFilter.includes(resource.category) : true)
        .sort((a, b) => sortByField(a,b, orderField.field, orderField.isDesc))
    );
  }, [resources, textFilter, categoriesFilter, orderField, isCategoryFilterEnabled]);

  const handleNew = () => {
    dispatch(openResourceEditor());
  };

  const handleEdit = (resourceId: number) => {
    dispatch(setResourceOnEdition(resourceId));
    dispatch(openResourceEditor());
  };

  const handleDelete = (resourceId: number) => {
    const sure = window.confirm(lang.messages.onDeleteMessage);
    if(sure){
      dispatch(deleteResource({
        resourceId}));
    }
  };

  return (
    <Wrapper title={lang.modules.resources}>
      <Loader fetchingStatus={resourcesFetchingStatus}>
        <TableEnvironment>
          <TableBarContainer>
            <TableTextFilter
              setter={setTextFilter}
            />
            <TableCategoryFilter
              type={'Categorias'}
              categories={categories}
              setFilteredCategories={setCategoriesFilter}
              setCategoryFilterStatus={setIsCategoryFilterEnabled}
            />
            <TableButton text={lang.resources.new} onClick={handleNew}/>
          </TableBarContainer>
          <TableContainer>
            <TableRow gridLayout='6fr 3fr 4fr 1fr'>
              <TableCell>
                <TableColumnHeader 
                  name='name' 
                  text={lang.attributes.name} 
                  isActive={orderField.field==='name'} 
                  isDesc={orderField.isDesc} 
                  setOrder={setOrderField}
                />
              </TableCell>
              <TableCell>
                <TableColumnHeader 
                  name='category' 
                  text={lang.attributes.category} 
                  isActive={orderField.field==='category'} 
                  isDesc={orderField.isDesc} 
                  setOrder={setOrderField}
                />
              </TableCell>
              <TableCell>
                <TableColumnHeader 
                  name='price' 
                  text={lang.attributes.price} 
                  isActive={orderField.field==='price'} 
                  isDesc={orderField.isDesc} 
                  setOrder={setOrderField}
                />
              </TableCell>
              <TableCell>
                <TableColumnHeader text={lang.options} />
              </TableCell>
            </TableRow>
            {
              filteredResources.map((resource: any) => {
                return(
                  <TableRow gridLayout='6fr 3fr 4fr 1fr' key={resource.id}>
                    <TableCell>{format(resource.name, 'firstCap')}</TableCell>
                    <TableCell>{
                      categories.find((category) => category.id === resource.category)?.name
                    }</TableCell>
                    <TableCell>{format(resource.price, '$')}</TableCell>
                    <TableCell>
                      <EditIconButton onClick={() => handleEdit(resource.id)} />
                      <DeleteIconButton onClick={() => handleDelete(resource.id)} />
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableContainer>
        </TableEnvironment>
      </Loader>
      <ResourceEditor/>
    </Wrapper>
  );
}