import React from 'react';
import styled from '@emotion/styled';

interface ListFooterProps {
  note: string
  onChange: any
}

export const ListFooter = ({ note, onChange }: ListFooterProps) => {

  const handleNoteChange = (e: any) => {
    onChange('note', e.target.value);
  };

  return (
    <Footer>
      <NoteEditor onChange={handleNoteChange} value={note} />
    </Footer>
  );
};

const Footer = styled.div`
    width: 100%;
    padding: 20px;
`;

const NoteEditor = styled.textarea`
  width: 100%;
  text-align: center;
`;