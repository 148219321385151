import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestStatus } from '../../constants/requestStatus';
import { PricesGroup } from '../../modules/Prices/PricesLists/PricesGroup';
import {
  getPricesList as getPricesListService,
  updatePricesList as updatePricesListService,
  createPricesGroup as createPricesGroupService,
  deletePricesGroup as deletePricesGroupService,
  updatePricesGroup as updatePricesGroupService,
  addGroupPrice as addGroupPriceService,
  removeGroupPrice as removeGroupPriceService,
} from '../../services/pricesListsService';

interface PricesGroup {
  title: string,
  prices: any[]
}

interface PricesList {
  id: number | null,
  title: string,
  subtitle: string | null,
  date: string,
  pricesGroups: PricesGroup[],
  note: string | null,
}

interface InitialState {
  pricesList: PricesList,
  fetchingStatus: string,
}

const initialState: InitialState = {
  pricesList: {
    id: null,
    title: '',
    subtitle: null,
    date: new Date().toString(),
    pricesGroups: [],
    note: null
  },
  fetchingStatus: requestStatus.READY,
};

export const getPricesList = createAsyncThunk(
  'getPricesList',
  async ({id}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getPricesListService(organizationId, id);
    return response;
  }
);

export const updatePricesList = createAsyncThunk(
  'updatePricesList',
  async ({data}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await updatePricesListService(organizationId, data);
    return response.data;
  }
);

export const createPricesGroup = createAsyncThunk(
  'createPricesGroup',
  async ({data}: any, { getState }) => {
    const { 
      organization: { id: organizationId }, 
      pricesListEditor: { pricesList: {id: pricesListId}}
    } = getState() as any;
    const response = await createPricesGroupService(organizationId, {
      ...data,
      prices_list_id: pricesListId,
    });
    return response.data;
  }
);

export const updatePricesGroup = createAsyncThunk(
  'updatePricesGroup',
  async ({data}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await updatePricesGroupService(organizationId, data);
    return response.data;
  }
);

export const deletePricesGroup = createAsyncThunk(
  'deletePricesGroup',
  async ({id}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await deletePricesGroupService(organizationId, id);
    return response.data;
  }
);

export const addGroupPrice = createAsyncThunk(
  'addGroupPrice',
  async ({pricesGroupId, priceMakerId}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await addGroupPriceService(organizationId, pricesGroupId, priceMakerId);
    return response.data; 
  }
);

export const removeGroupPrice = createAsyncThunk(
  'removeGroupPrice',
  async ({pricesGroupId, priceMakerId}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await removeGroupPriceService(organizationId, pricesGroupId, priceMakerId);
    return response.data; 
  }
);

const pricesListEditorSlice = createSlice({
  name: 'pricesListEditor',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(getPricesList.pending, (state) => {
      state.fetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(getPricesList.fulfilled, (state, action) => {
      state.pricesList = action.payload;
      state.fetchingStatus = requestStatus.SUCCESS; 
    });
    builder.addCase(getPricesList.rejected, (state) => {
      state.fetchingStatus = requestStatus.FAILED;
    });
    builder.addCase(deletePricesGroup.fulfilled, (state, payload) => {
      state.pricesList.pricesGroups = state.pricesList.pricesGroups
        .filter((pricesGroup: any) => pricesGroup.id !== payload.meta.arg.id);
    });
    builder.addCase(createPricesGroup.fulfilled, (state, action) => {
      state.pricesList.pricesGroups = state.pricesList.pricesGroups.concat(action.payload);
    });
  }
});

export default pricesListEditorSlice.reducer;