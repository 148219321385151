import React, { useEffect, useState } from 'react';
import { useFormat } from '../../hooks/useFormat';
import { useSort } from '../../hooks/useSort';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getCategories } from '../../store/reducers/resourcesCategoriesSlice';
import { getResources, updateResource } from '../../store/reducers/resourcesSlice';
import { CustomIconButton } from '../../components/CustomIconButton';
import { 
  TableBarContainer, 
  TableCategoryFilter, 
  TableCell, 
  TableColumnHeader, 
  TableContainer, 
  TableEnvironment, 
  TableRow, 
  TableTextFilter, 
  TableTitle } 
  from '../../components/Table';
import { TableCellInput } from '../../components/Table/TableCellInput';
import { BiReset } from 'react-icons/bi';
import { useMyContext } from '../../store/Context';

export function ResourcesStock () {
    
  const lang = useMyContext();
  const sortByField = useSort;
  const format = useFormat;
  const dispatch = useAppDispatch();

  const [textFilter, setTextFilter] = useState('');
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>([]);
  const [order, setOrder] = useState({
    field: 'name',
    isDesc: true
  });

  const { id: organizationId } = useAppSelector((state) => state.organization);
  const { resources } = useAppSelector((state) => state.resources);
  const { categories } = useAppSelector((state) => state.resourcesCategories);

  const [filteredResources, setFilteredResources] = useState<any[]>([]);
  const [stockedFilteredResources, setStockedFilteredResources] = useState<any[]>([]);
  const [nonStockedFilteredResources, setNotStockedFilteredResources] = useState<any[]>([]);
  const [isCategoryFilterEnabled, setCategoriesFilterStatus] = useState(false);

  useEffect(() => {
    dispatch(getResources());
    dispatch(getCategories());
  }, [organizationId]);

  useEffect(() => {
    setFilteredResources(
      resources
        .filter((resource:any) => resource.name.toLowerCase().match(textFilter.toLowerCase()))
        .filter((resource:any) => isCategoryFilterEnabled? categoriesFilter.includes(resource.category) : true)
        .sort((a, b) => sortByField(a,b, order.field, order.isDesc))
    );
  }, [resources, textFilter, categoriesFilter, order]);

  useEffect(() => {
    setStockedFilteredResources(
      filteredResources.filter((resource:any) => resource.stockable)
    );
    setNotStockedFilteredResources(
      filteredResources.filter((resource:any) => !resource.stockable)
    );
  }, [filteredResources]);

  const setResourceStock = (id: number, value: number) => {
    updateData(id, 'stock', value);
  };

  const setResourceStockable = (id: number, value: boolean) => {
    updateData(id, 'stockable', value);
  };

  const updateData = (id: number, field: string, value: any) => {
    const data = {
      resourceId: id,
      data: {
        [field]: value,
      },
    };
    console.log(data);
    dispatch(updateResource(data));
  };

  return (
    <TableEnvironment>
      <TableBarContainer>
        <TableTextFilter
          setter={setTextFilter}
        />
        <TableCategoryFilter 
          type='Categorías'
          categories={categories}
          setFilteredCategories={setCategoriesFilter}
          setCategoryFilterStatus={setCategoriesFilterStatus}
        />
      </TableBarContainer>
      <TableContainer>
        <TableTitle>{lang.stock.stockedResources}</TableTitle>
        <TableRow gridLayout='6fr 4fr 3fr 2fr 1fr'>
          <TableCell>
            <TableColumnHeader 
              name='name' 
              text={lang.attributes.name} 
              isActive={order.field==='name'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader 
              name='category' 
              text={lang.attributes.category} 
              isActive={order.field==='category'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader 
              name='stock' 
              text={lang.attributes.stock} 
              isActive={order.field==='stock'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader text={lang.attributes.unit} />
          </TableCell>
          <TableCell>
            <TableColumnHeader text={lang.options} />
          </TableCell>
        </TableRow>
        {
          stockedFilteredResources.map((resource: any) => {
            return(
              <TableRow gridLayout='6fr 4fr 3fr 2fr 1fr' key={resource.id}>
                <TableCell>{format(resource.name, 'firstCap')}</TableCell>
                <TableCell>{
                  categories.find((category) => category.id === resource.category)?.name
                }</TableCell>
                <TableCellInput
                  id={resource.id}
                  actualValue={resource.stock}
                  onChange={setResourceStock}
                />
                <TableCell>
                  {resource.unit}
                </TableCell>
                <TableCell>
                  <CustomIconButton onClick={() => setResourceStockable(resource.id, false)}><BiReset/></CustomIconButton>
                </TableCell>
              </TableRow>
            );
          })
        }
      </TableContainer>
      <TableContainer>
        <TableTitle>{lang.stock.nonStockedResources}</TableTitle>
        <TableRow gridLayout='6fr 4fr 3fr 1fr'>
          <TableCell>
            <TableColumnHeader 
              name='name' 
              text={lang.attributes.name} 
              isActive={order.field==='name'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader 
              name='category' 
              text={lang.attributes.category} 
              isActive={order.field==='category'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader text={lang.options} />
          </TableCell>
        </TableRow>
        {
          nonStockedFilteredResources.map((resource: any) => {
            return(
              <TableRow gridLayout='6fr 4fr 3fr' key={resource.id}>
                <TableCell>{format(resource.name, 'firstCap')}</TableCell>
                <TableCell>{
                  categories.find((category) => category.id === resource.category)?.name
                }</TableCell>
                <TableCell>
                  <CustomIconButton onClick={() => { setResourceStockable(resource.id, true);}}>{lang.stock.restore}</CustomIconButton>
                </TableCell>
              </TableRow>
            );
          })
        }
      </TableContainer>
    </TableEnvironment>
  );
}