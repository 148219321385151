import React from 'react';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {colors} from '../../styles/variables';
import { useMyContext } from '../../store/Context';

const TableCategoryFilterContainer = styled.div`
    width: 100%;
    min-width: 20%;
    max-width: 100%;
    height: 100%;
    margin-left: 5px;
    position: relative;
`;

const TableCategoryFilterBar = styled.div`
    width: 100%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 2px solid ${colors.secondary};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    :hover{
        background-color: ${colors.secondary};
        cursor: pointer;
    }
`;

interface TableCategoryFilterListProps{
    $display: boolean,
}

const TableCategoryFilterList = styled.div<TableCategoryFilterListProps>`
    width: 100%;
    position: absolute;
    left: 0px;
    top: 40px;
    background-color: ${colors.secondary};
    display: ${props => props.$display? 'inherit' : 'none'}
`;

const TableCategoryFilterListItem = styled.li`
    width: 100%;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    list-style: none;
    display: flex;
    :hover{
        background-color: rgba(0,0,0,0.2);
    }
`;

const Checkbox = styled.input`
    width: 16px;
    height: 16px;
    outline: 0px;
`;

interface TableCategoryFilterProps{
    type: string,
    categories: {
        name: string,
        id: number,
    }[],
    setFilteredCategories?: any,
    setCategoryFilterStatus: any,
}

export const TableCategoryFilter = ({type, categories, setFilteredCategories, setCategoryFilterStatus}: TableCategoryFilterProps) => {

  const lang = useMyContext();
  const [displayList, setDisplayList] = useState(false);
  const [mappedCategories, setMappedCategories]: any = useState([]);
  const [allOption, setAllOption] = useState({
    name: lang.components.table.categoryFilter.allOption,
    isChecked: true,
  });

  const handleBarClick = () => {
    setDisplayList(!displayList);
  };

  useEffect(() => {
    const updateFilteredCategories = () => {
      const selectedCategoriesIds = mappedCategories
        .filter((category: any) => category.isChecked)
        .map((category: any) => category.id);
      setFilteredCategories(selectedCategoriesIds);
    };
    updateFilteredCategories();
  }, [mappedCategories]);

  useEffect(() => {
    setMappedCategories(
      categories.map((category) => {
        return {
          name: category.name,
          id: category.id,
          isChecked: false,
        };
      })
    );
  }, [categories]);


  const handleOnChange = async (e: any) => {
    const index = mappedCategories.indexOf(
      mappedCategories.find((category: any) => category.id === Number(e.target.name))
    );
    const newItem = {
      ...mappedCategories[index],
      isChecked: !mappedCategories[index].isChecked,
    };
    setMappedCategories(mappedCategories.slice(0,index).concat(newItem).concat(mappedCategories.slice(index+1)));
  };

  const handleAllOptionChange = (e: any) => {
    setAllOption({
      ...allOption,
      isChecked: e.target.checked,
    });
  };

  useEffect(() => {
    setCategoryFilterStatus(!allOption.isChecked);
  }, [allOption.isChecked]);

  return(
    <TableCategoryFilterContainer>
      <TableCategoryFilterBar onClick={handleBarClick}>{type}</TableCategoryFilterBar>
      <TableCategoryFilterList $display={displayList}>
        <TableCategoryFilterListItem>
          <Checkbox type='checkbox' checked={allOption.isChecked} onChange={handleAllOptionChange}/>
          {allOption.name}
        </TableCategoryFilterListItem>
        {
          mappedCategories.map((category: any) => {
            return(
              <TableCategoryFilterListItem key={category.id}>
                <Checkbox type='checkbox' checked={category.isChecked} name={category.id} onChange={handleOnChange}/>
                {category.name}
              </TableCategoryFilterListItem>
            );
          })
        }
      </TableCategoryFilterList>
    </TableCategoryFilterContainer>
  );
};