import React, { useEffect, useState } from 'react';
import { ListDetail } from '../../../components/ListItemDetail';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ListHeader } from './ListHeader';
import { PricesGroup } from './PricesGroup';
import { getPriceMakers } from '../../../store/reducers/priceMakersSlice';
import { getProducts } from '../../../store/reducers/productsSlice';
import { createPricesGroup, getPricesList, updatePricesList } from '../../../store/reducers/pricesListEditorSlice';
import { Button } from '../../../components/inputs';
import { ListFooter } from './ListFooter';
import { useMyContext } from '../../../store/Context';
import { FaFilePdf } from 'react-icons/fa';
import PricesListDetailToolbar from './PricesListDetailToolbar';
import { AppBar, Dialog, Toolbar, Typography } from '@mui/material';
import PricesListPDFViewer from './PricesListPDFViewer';
import CloseIcon from '@mui/icons-material/Close';
import { CustomIconButton } from '../../../components/CustomIconButton';

interface PriceListDetailProps {
  id: number
  onGoBack: any
}

export function PriceListDetail ({id, onGoBack}: PriceListDetailProps) {

  const dispatch = useAppDispatch();
  const lang = useMyContext();

  const { priceMakers } = useAppSelector((state) => state.priceMakers);
  const { products } = useAppSelector((state) => state.products);
  const [ pricesOptions, setPricesOptions ] = useState<any>([]); 
  const { pricesList } = useAppSelector((state) => state.pricesListEditor);
  const [data, setData] = useState<any>();
  const [displayPDFViewer, setDisplayPDFViewer] = useState(false);
  const organization = useAppSelector((state) => state.organization);

  useEffect(() => {
    if(id){
      requestPricesList();
    }
  }, [id]);

  // REVIEW THIS HOOK, IT'S NECCESARY TO RENDER EVERY TIME pricesList CHANGES?
  useEffect(() => {
    if(id){
      dispatch(getPriceMakers());
      dispatch(getProducts());
    }
    setData(pricesList);
  }, [pricesList]);

  const requestPricesList = () => {
    dispatch(getPricesList({id}));
  };

  useEffect(() => {
    if(priceMakers && products){
      setPricesOptions(
        priceMakers.map((priceMaker: any) => {
          return {
            ...priceMaker,
            product: products.find((product: any) => product.id == priceMaker.productId)?.shortDescription ?? ''
          };
        })
      );
    }
  }, [priceMakers, products]);

  const handleDataChange = (field: string, value: any) => {
    setData({
      ...data,
      [field]: value,
    });
  };
  
  const handleGroupCreation = () => {
    dispatch(createPricesGroup({
      data: {
        title: ''
      }
    }));
  };

  const handleSave = () => {
    dispatch(updatePricesList({data}));
  };

  const openPDFViewer = () => {
    setDisplayPDFViewer(true);
  };
 
  return (
    <>
      {
        data?.id &&
          <ListDetail title={data.title} onGoBack={onGoBack}>
            <PricesListDetailToolbar>
              <CustomIconButton onClick={openPDFViewer}>
                <FaFilePdf />
              </CustomIconButton>
            </PricesListDetailToolbar>
            <ListHeader 
              title={data.title}
              subtitle={data.subtitle} 
              date={data.date} 
              onChange={handleDataChange}
              onGroupCreation={handleGroupCreation}
            />
            {
              data.pricesGroups?.map((pricesGroup: any) => {
                return (
                  <PricesGroup 
                    key={pricesGroup.id} 
                    pricesGroup={pricesGroup} 
                    prices={pricesOptions} 
                    onUpdate={requestPricesList}
                  />
                );
              })
            }
            <ListFooter note={data.note} onChange={handleDataChange}/>
            <Button 
              onClick={handleSave}
            >
              {lang.save}
            </Button>
          </ListDetail>
      }
      <Dialog fullScreen open={displayPDFViewer} onClose={() => setDisplayPDFViewer(false)}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <CustomIconButton
              onClick={() => setDisplayPDFViewer(false)}
              aria-label="close"
            >
              <CloseIcon />
            </CustomIconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Vista PDF
            </Typography>
          </Toolbar>
        </AppBar>
        <PricesListPDFViewer pricesList={data} organization={organization}/>
      </Dialog>
    </>
  );
}