import React, { useState } from 'react';
import { Wrapper } from '../../../components/Wrapper';
import { useMyContext } from '../../../store/Context';
import { PriceListDetail } from './PriceListDetail';
import { PricesListsList } from './PriceListsList';

export default function PricesLists() {

  const lang = useMyContext();

  const [displayDetail, setDisplayDetail] = useState(false);
  const [detailedItemId, setDetailedItemId] = useState<number|null>(null);

  const handleDisplayDetail = (id: number) => {
    setDetailedItemId(id);
    setDisplayDetail(true);
  };

  const handleGoBackToList = () => {
    setDetailedItemId(null);
    setDisplayDetail(false);
  };

  return (
    <Wrapper title={lang.prices.pricesLists}>
      {
        !displayDetail &&
          <PricesListsList onDisplayDetail={handleDisplayDetail}/>
      }
      {
        displayDetail && detailedItemId &&
          <PriceListDetail id={detailedItemId} onGoBack={handleGoBackToList}/>
      }
    </Wrapper>
  );
}