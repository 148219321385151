import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { Link } from 'react-router-dom';
import { SignForm } from '../../styles/SignForm';
import { Input, Button } from '../../components/inputs';
import { register } from '../../store/reducers/authSlice';

export function Register() {

  const dispatch = useAppDispatch();

  const initialData = {
    name: '',
    email: '',
    password: '',
  };

  const[registrationData, setRegistrationData] = useState(initialData);

  const registerError = useAppSelector((state) => state.access.loginError);
  const errorMessage= useAppSelector((state) => state.access.errorMessage);

  const handleInputChange = (e: any) => {
    setRegistrationData({
      ...registrationData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(register(
      {
        name: registrationData.name,
        email: registrationData.email,
        password: registrationData.password,
      }
    ));
  };

  return (
    <SignForm>
      <>
        <div className="logo">
          <img src="logo.png" alt="logo"/>
        </div>
        <h2>Registro</h2>
        <label>Nombre: </label><Input type="text" value={registrationData.name} name="name" onChange={handleInputChange}/>
        <label>Email: </label><Input type="email" value={registrationData.email} name="email" onChange={handleInputChange}/>
        <label>Contraseña: </label><Input type="password" value={registrationData.password} name="password" onChange={handleInputChange}/>
        <Button onClick={handleSubmit}>Iniciar sesión</Button>
        {
          registerError &&
                <div className="alert">
                  {errorMessage}
                </div>
        }
        <Link to="/login">Ya estoy registrado</Link>
      </>
    </SignForm>
  );

}