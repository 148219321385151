import React from 'react';
import styled from '@emotion/styled';
import { useMyContext } from '../../store/Context';
import { GoSearch } from 'react-icons/go';

const Wrapper = styled.div`
  width: 100%;
  min-height: 35px;
  min-width: 40%;
  max-width: 100%;
  border: solid 1px rgba(0,0,0,0.2);
  border-radius: 7px;
  color: rgba(0,0,0,0.6);
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 6px 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
  border: none;
  background: none;
`;

const Icon = styled.div`
  width: 40px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableTextFilter = ({setter}: any) => {

  const handleKeyDown = (e: any) => {
    setter(e.target.value);
  };

  const lang = useMyContext();

  return (
    <Wrapper>
      <Input type="text" onKeyUp={handleKeyDown} placeholder={lang.searchTerm} />
      <Icon>
        <GoSearch/>
      </Icon>
    </Wrapper>
  );
};