import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearStore } from '../actions/clearStore';
import {
  getProducts as getProductsService,
  createProduct as createProductService,
  updateProduct as updateProductService,
  deleteProduct as deleteProductService,
} from '../../services/productsService';
import { requestStatus } from '../../constants/requestStatus';

interface InitialStateInterface{
    products: any[]
    productOnEdition: any
    fetchingProductsStatus: string
    fetchingProductStatus: string
}

const initialState: InitialStateInterface = {
  products: [],
  productOnEdition: null,
  fetchingProductsStatus: requestStatus.READY,
  fetchingProductStatus: requestStatus.READY,
};

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async (_, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getProductsService(organizationId);
    return response.data;
  }
);

export const createProduct = createAsyncThunk(
  'products/createProduct',
  async ({data}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const formattedData = formatUploadProduct(data);
    const response = await createProductService(organizationId, formattedData);
    return response.data;
  }
);

export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async ({productId, data}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const formattedData = formatUploadProduct(data);
    const response = await updateProductService(organizationId, productId, formattedData);
    return response.data;
  }
);

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async ({productId}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await deleteProductService(organizationId, productId);
    return response.data;
  }
);

const formatDownloadProduct = (product: any) => {
  return {
    id: product.id,
    sku: product.sku,
    shortDescription: product.short_description,
    longDescription: product.long_description,
    stock: product.stock,
    unit: product.unit,
    stockable: product.stockable,
    categories: product.categories.map((category: any) => {
      return {
        id: category.id,
        name: category.name
      };
    }),
  };
};

const formatUploadProduct = (product: any) => {
  return {
    id: product.id,
    sku: product.sku,
    short_description: product.shortDescription,
    long_description: product.longDescription,
    stock: product.stock,
    unit: product.unit,
    stockable: product.stockable,
    categories: product.categories
      ? product.categories.map((category: any) => {
        return category.id;
      })
      : null,
  };
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductOnEdition: ((state, action: PayloadAction<number|null> ) => {
      if(action.payload){
        state.productOnEdition = state.products.find((product: any) => product.id === action.payload);
      } else{
        state.productOnEdition = null;
      }
    })
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });

    // GET PRODUCTS
    builder.addCase(getProducts.pending, (state) => {
      state.fetchingProductsStatus = requestStatus.PENDING;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload.map((product: any) => formatDownloadProduct(product));
      state.fetchingProductsStatus = requestStatus.SUCCESS;
    });
    builder.addCase(getProducts.rejected, (state) => {
      state.fetchingProductsStatus = requestStatus.FAILED;
    });

    // CREATE PRODUCT

    builder.addCase(createProduct.fulfilled, (state, action) => {
      state.products = Object.assign(state.products, state.products.concat(formatDownloadProduct(action.payload)));
    });


    // UPDATE RESOURCE
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      const updatedItem = formatDownloadProduct(action.payload);
      state.products = state.products.map((product:any) => 
        product.id == updatedItem.id
          ? updatedItem
          : product
      );
    });

    // DELETE RESOURCE
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.products = state.products.filter((product:any) => {
        return product.id != action.payload.id;
      });
    });
  }
});

export default productsSlice.reducer;

export const { setProductOnEdition } = productsSlice.actions;