import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { getMonthlyExpensesAmount, getMonthlyIncomesAmount } from '../../../../services/transactionsService';
import { ChartWidget } from '../../../../components/ChartWidget';
import { LineChart } from '@mui/x-charts';

export const BalanceAmountByMonth = () => {
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [balanceHistory, setBalanceHistory] = useState([]);
  const { id: organizationId } = useAppSelector((state) => state.organization);

  const fetchIncomes = async () => {
    const response = await getMonthlyIncomesAmount(organizationId, {});
    setIncomes(response.data);
  };

  const fetchExpenses = async () => {
    const response = await getMonthlyExpensesAmount(organizationId, {});
    setExpenses(response.data);
  };

  useEffect(() => {
    fetchIncomes();
    fetchExpenses();
  }, [organizationId]);

  useEffect(() => {
    if (incomes.length > 0 && expenses.length > 0) {
      const balance = incomes.map((income: any) => {
        const expense: any = expenses.find((expense: any) => expense.date === income.date);
        return {
          date: income.date,
          amount: Number(income.amount) - (expense?.amount || 0),
        };
      });
      setBalanceHistory(balance as any);
    }
  }, [incomes, expenses]);

  return (
    <ChartWidget title="Ingresos, egresos y balance por mes">
      <LineChart
        xAxis={[
          {
            scaleType: 'point',
            data: incomes.map((income: any) => income.date)
          }
        ]}
        series={[
          {
            id: 'Incomes',
            label: 'Ingresos',
            data: incomes.map((income: any) => Number(income.amount)),
          },
          {
            id: 'Expenses',
            label: 'Gastos',
            data: expenses.map((expense: any) => Number(expense.amount)),
          },
          {
            id: 'Balance',
            label: 'Balance',
            data: balanceHistory?.map((balance: any) => balance.amount),
            area: true,
          }
        ]}
        margin={
          {
            left: 100,
          }
        }
        height={300}
      />
    </ChartWidget>
  );
};