import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { fetchAccessToken } from '../../store/reducers/authSlice'; 
import { SignForm } from '../../styles/SignForm';
import { useNavigate, Link } from 'react-router-dom';
import { ImagoType } from './styles/ImagoType';
import { Alert, Button, TextField } from '@mui/material';

export function Login() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const logged = useAppSelector((state) => state.access.logged);
  const loginError = useAppSelector((state) => state.access.loginError);
  const errorMessage= useAppSelector((state) => state.access.errorMessage);

  useEffect(() => {
    if(logged){
      navigate('/');
    }
  }, [logged]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(fetchAccessToken(
      {
        email: email,
        password: password,
      }
    ));
  };

  return (
    <SignForm>
      <>
        <ImagoType />
        <h2>Iniciar sesión</h2>
        <TextField id="outlined-basic" label="Email" variant="outlined" name='email' margin='dense' fullWidth
          value={email} 
          onChange={(e) => setEmail(e.target.value)}
          autoComplete='false'
        />
        <TextField id="outlined-basic" type='password' label="Contraseña" variant="outlined" name='password' margin='normal' fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)} 
        />
        <Button variant="outlined" onClick={handleSubmit}>Iniciar sesión</Button>
        {
          loginError &&
            <Alert severity='error'>{errorMessage}</Alert>
        }
        <Link to="/register">¿Aún no estás registrado?</Link>
      </>
    </SignForm>
  );

}