import React from 'react';
import styled from '@emotion/styled';
import { inputStyle } from './inputStyle';

export const StyledInput = styled.input`
    ${inputStyle}
`;

interface InputProps{
    type: string,
    name: string,
    value: any,
    onChange: any,
    placeholder?: string,
}

export const Input = ({type, name, value, onChange, placeholder}: InputProps) => {
  return (
    <StyledInput 
      type = {type}
      name = {name}
      value = {value}
      onChange = {onChange}
      placeholder = {placeholder}
    />
  );
};