import React from 'react';
import styled from '@emotion/styled';
import { FiDelete } from 'react-icons/fi';
import { CustomIconButton } from '../../../../components/CustomIconButton';

const DeleteButtonContainer = styled.div`
  width: 30px;
  height: 30px;
`;

interface StyledDeleteButtonProps{
  $display: boolean,
}

const StyledDeleteButton = styled(CustomIconButton)<StyledDeleteButtonProps>`
    width: 30px;
    height: 30px;
    margin-left: 5px;
    display: ${props => props.$display? 'inherit' : 'none'};
`;

interface DeleteButtonProps {
  onClick: any,
  display: boolean,
}

export const DeleteButton = ({ onClick, display }: DeleteButtonProps) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    onClick();
  };

  return (
    <DeleteButtonContainer>
      <StyledDeleteButton $display={display} onClick={handleClick}>
        <FiDelete />
      </StyledDeleteButton>
    </DeleteButtonContainer>
  );
};