import React from 'react';
import styled from '@emotion/styled';
import { ArrowButton } from '../../ArrowButton';

const StyledOrganizationContainer = styled.div`
    width: 50%;
    max-width: 400px;
    height: 100%;
`;

interface ListPropsInterface{
    $display: boolean,
}

const StyledOrganizationsList = styled.div<ListPropsInterface>`
    width: 30%;
    max-width: 300px;
    position: absolute;
    top: 70px;
    left: 10px;
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    display: ${props => props.$display? 'inherit' : 'none'};
    li {
        list-style: none;
    }
`;

const StyledListItem = styled.li`
    width: 100%;
    height: 100px;
    max-height: 50px;
    padding: 5px;
    box-sizing: border-box;
    margin: 5px 0 5px 0;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    :hover {
        cursor: pointer;
        background-color: rgba(0,0,0,0.3);
    }
    div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img {
        height: 100%;
        margin-right: 20px;
    }
    p {
        margin: 0;
    }
`;

const StyledOrganizationLabel = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem;
    div {
        height: 100%;
        margin-left: 10px;
        display: flex;
        align-items: center;
    }
    img {
        height: 100%;
    }
    p {
        margin: 0;
    }
`;

export const MenuGroupsSeparator = styled.hr`
    width: 80%;
    margin: auto;
`;

interface OrganizationLabelProperties{
    src: string,
    text: string,
    organizations: any[],
    onSelect: any,
    display: boolean,
    onSwitch: any,
} 

export const OrganizationSelector = ({src, text, organizations, onSelect, display, onSwitch}: OrganizationLabelProperties) => {
  return (
    <StyledOrganizationContainer>
      <StyledOrganizationLabel>
        <div className="organization-label-image">
          <img src={src} alt="logo" />
        </div>
        <div className="organization-label-text">
          <p>{text}</p>
        </div>
        <ArrowButton twisted={display} onClick={onSwitch}></ArrowButton>
      </StyledOrganizationLabel>
      <StyledOrganizationsList $display={display}>
        {
          organizations.map((organization:any) => {
            return (
              <StyledListItem 
                onClick={() => onSelect(organization.id)}
                key={organization.id}
              >
                <div>
                  <img src={organization.imageUrl} alt="logo"/>
                  <p>{organization.name}</p>
                </div>
              </StyledListItem>
            );
          })
        }
        <MenuGroupsSeparator/>
        <StyledListItem>
          <div>Crear nueva organización</div>
        </StyledListItem>
      </StyledOrganizationsList>
    </StyledOrganizationContainer>
  );
};