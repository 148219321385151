import axios, { AxiosRequestHeaders } from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://api.duite.com.ar/api',
  timeout: 10000
});

api.interceptors.request.use((config) => {
  config.headers = {
    'Authorization': localStorage.accessToken? `bearer ${localStorage.accessToken}` : '',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': 'https://api.duite.com.ar',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
  } as unknown as AxiosRequestHeaders;
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use( (response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('accessToken');
  }
  return Promise.reject(error);
});


export default api;