import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestStatus } from '../../constants/requestStatus';
import {
  getPriceModifiers as getPriceModifiersService
} from '../../services/priceModifiersService';
import { clearStore } from '../actions/clearStore';

interface State{
  priceModifiers: any[],
  fetchingStatus: string,
}

const initialState : State = {
  priceModifiers: [],
  fetchingStatus: requestStatus.READY,
};

export const getPriceModifiers = createAsyncThunk(
  'getPriceModifiers',
  async (_, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getPriceModifiersService(organizationId);
    return response.data;
  }
);

const priceModifiersSlice = createSlice({
  name: 'priceModifiers',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // CLEAR STORE
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });
    
    builder.addCase(getPriceModifiers.pending, (state) => {
      state.fetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(getPriceModifiers.fulfilled, (state, action) => {
      state.priceModifiers = action.payload;
      state.fetchingStatus = requestStatus.SUCCESS;
    });
    builder.addCase(getPriceModifiers.rejected, (state) => {
      state.fetchingStatus = requestStatus.FAILED;
    });
  }
});

export default priceModifiersSlice.reducer;