import styled from '@emotion/styled';

export { ItemEditorWrapper } from './ItemEditorWrapper';
export { Input } from './Input';
export { TagsSelector } from './TagsSelector';

export const Group = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
`;

interface FieldProps{
    size: number
}

export const Field = styled.div<FieldProps>`
    width: max-content;
    padding: 0.5%;
    box-sizing: border-box;
    flex-grow: ${props => props.size}
`;

export const Label = styled.label`
    margin-top: 10px;
    margin-right: 10px;
`;