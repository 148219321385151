import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import LapseFilterBase from './LapseFilterBase';
import { LapsePresets } from './Presets';
import { useFormatDate } from '../../hooks/useFormatDate';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

interface LapseFilterPresettableProps{
  after: string
  before: string
  onChange: any
  isActive: boolean
  setIsActive: any
}

interface MsLapse{
  after: number,
  before: number,
}
interface StringLapse{
  after: string,
  before: string
}

export function LapseFilterPresettable ({after, before, onChange, isActive, setIsActive}: LapseFilterPresettableProps) {

  const formatDate = useFormatDate;

  const [lapse, setLapse] = useState<StringLapse>({
    after,
    before,
  });

  const handleSelectorChange = ({after, before}: MsLapse) => {
    setLapse({
      after: formatDate(after), 
      before: formatDate(before),
    });
  };

  const onDatePickerChange = ({after, before}: StringLapse) => {
    setLapse({
      after,
      before,
    });
  };

  useEffect(() => {
    onChange(lapse);
  }, [lapse]);

  return (
    <Wrapper>
      <LapsePresets
        onChange={handleSelectorChange}
      />
      <LapseFilterBase 
        onChange={onDatePickerChange}
        lapse={lapse}
        isActive={isActive}
        setIsActive={setIsActive}
      />
    </Wrapper>

  );

}