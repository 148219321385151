import React, { useState } from 'react';
import { useMyContext } from '../../../store/Context';
import { useAppDispatch } from '../../../store/hooks';
import { createExpense } from '../../../store/reducers/expensesSlice';
import { TableBarAccordion } from '../../../components/Table';
import { Wrapper } from '../../../components/Wrapper';
import { InlineItemCreator } from '../../../components/Table/InlineItemCreator';
import { Input } from '../../../components/inputs';
import { Select } from '../../../components/ItemEditor/styles/Select';
import expenseTypes from '../../../config/expenseTypes';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { MdOutlineExpandMore } from 'react-icons/md';
import { useFormatDate } from '../../../hooks/useFormatDate';
import ExpensesList from './ExpensesList';
import { ExpensesCharts } from './ExpensesCharts';

export default function Expenses () {

  const lang = useMyContext();
  const dispatch = useAppDispatch();
  const formatDate = useFormatDate;

  const newExpenseInitialState = {
    date: formatDate(Date.now()),
    type: expenseTypes[0],
    quantity: 0,
    concept: '',
    value: 0,
  };

  const [newExpense, setNewExpense] = useState(newExpenseInitialState);

  const handleNewExpenseChange = (e: any) => {
    setNewExpense({
      ...newExpense,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreate = () => {
    dispatch(createExpense({data: newExpense}));
    setNewExpense(newExpenseInitialState);
  };

  return (
    <Wrapper title={lang.transactions.expenses.name}>
      <TableBarAccordion>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore />}
        >
          {lang.insert}
        </AccordionSummary>
        <AccordionDetails>
          <InlineItemCreator
            onCreate={handleCreate}
          >
            <label>{lang.attributes.date}</label>
            <Input
              name='date'
              type='date'
              value={newExpense.date}
              onChange={handleNewExpenseChange}
            />
            <label>{lang.attributes.type}</label>
            <Select
              name='type'
              values={
                expenseTypes.map((type: string) => ({
                  option: type,
                  name: lang.transactions.expenses.types[type]?? type,
                }))
              }
              selectedValue={newExpense.type}
              onSelect={handleNewExpenseChange}
            />
            <label>{lang.attributes.concept}</label>
            <Input
              name='concept'
              type='text'
              value={newExpense.concept}
              onChange={handleNewExpenseChange}
            />
            <label>{lang.attributes.value}</label>
            <Input
              name='value'
              type='number'
              value={newExpense.value}
              onChange={handleNewExpenseChange}
            />
          </InlineItemCreator>
        </AccordionDetails>
      </TableBarAccordion>
      <ExpensesList/>
      <ExpensesCharts/> 
    </Wrapper>
  );
}

