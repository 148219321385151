import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clearStore } from '../actions/clearStore';
import {
  getResourcesCategories as getResourcesCategoriesService,
  updateResourcesCategory as updateResourcesCategoryService,
  createResourcesCategory as createResourcesCategoryService,
  deleteResourcesCategory as deleteResourcesCategoryService,
} from '../../services/resourcesService';
import { requestStatus } from '../../constants/requestStatus';

interface InitialStateInterface{
    categories: any[]
    fetchingCategoriesStatus: string,
}

const initialState: InitialStateInterface = {
  categories: [],
  fetchingCategoriesStatus: requestStatus.READY,
};

export const getCategories = createAsyncThunk(
  'resourcesCategories/getCategories',
  async (_, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getResourcesCategoriesService(organizationId);
    return response.data;
  }
);

export const createCategory = createAsyncThunk(
  'resourcesCategories/createCategory',
  async ({name}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await createResourcesCategoryService(organizationId, name);
    return response.data;
  }
);

export const updateCategory = createAsyncThunk(
  'resourcesCategories/updateCategory',
  async ({categoryId, name}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await updateResourcesCategoryService(organizationId, categoryId, name);
    return response.data;
  }
);

export const deleteCategory = createAsyncThunk(
  'resourcesCategories/deleteCategory',
  async ({categoryId}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await deleteResourcesCategoryService(organizationId, categoryId);
    if(!response.data.error){
      return categoryId;
    }
    return null;
  }
);

export const resourcesCategoriesSlice = createSlice({
  name: 'resourcesCategories',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // CLEAR STORE
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });

    // GET CATEGORIES
    builder.addCase(getCategories.pending, (state) => {
      state.fetchingCategoriesStatus = requestStatus.PENDING;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.fetchingCategoriesStatus = requestStatus.SUCCESS;
    });
    builder.addCase(getCategories.rejected, (state) => {
      state.fetchingCategoriesStatus = requestStatus.FAILED;
    });

    // CREATE CATEGORY
    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.categories = state.categories.concat(action.payload);
    });

    // UPDATE CATEGORY
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      const updatedItem = action.payload;
      state.categories = state.categories.map((category: any) => 
        category.id == updatedItem.id
          ? updatedItem
          : category
      );
    });

    // DELETE CATEGORY
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.categories = state.categories.filter((category: any) => {
        return category.id != action.payload;
      });
    });
  }
});

export default resourcesCategoriesSlice.reducer;