const colors = {
  primary: '#ECCF8D',
  secondary: '#ECCF8D',
  secondaryHover: '#DBB867',
  dark: '#2C1507',
  softHighlight: '#FAF2DF',
};

const fonts = {
  base: {
    family: '"Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif',
    size: '1rem',
  }
};

export { colors, fonts };