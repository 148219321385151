import api from './config/api';

export const getPricesLists = async (organizationId: number, searchParams: any) => {
  const params = {
    page: searchParams.page,
    limit: searchParams.limit,
    order_by: searchParams.orderBy,
    order: searchParams.order,
  };
  const response = await api.get(`/${organizationId}/prices_lists`, {params});
  return response.data;
};

export const getPricesList = async (organizationId: number, pricesListId: number) => {
  const response = await api.get(`/${organizationId}/prices_lists/${pricesListId}`);
  const data = {
    id: response.data.data.id,
    title: response.data.data.title,
    subtitle: response.data.data.subtitle,
    note: response.data.data.note,
    date: response.data.data.date,
    organizationId: response.data.data.organization_id,
    createdAt: response.data.data.create_at,
    updatedAt: response.data.data.updated_at,
    pricesGroups: response.data.data.prices_groups?.map((pricesGroup: any) => {
      return {
        id: pricesGroup.id,
        title: pricesGroup.title,
        pricesListId: pricesGroup.prices_list_id,
        prices: pricesGroup.prices?.map((price: any) => {
          return {
            id: price.id,
            productId: price.product_id,
            updatedAt: price.updated_at,
            result: price.result,
            product: {
              id: price.product.id,
              shortDescription: price.product.short_description,
            }
          };
        })
      };
    }) 
  };
  return data;
};

export const createPricesList = async (organizationId: number, data: any) => {
  const response = await api.post(`/${organizationId}/prices_lists`, data);
  return response.data;
};

export const updatePricesList = async (organizationId: number, data: any) => {
  const response = await api.put(`/${organizationId}/prices_lists/${data.id}`, data);
  return response.data;
};

export const deletePricesList = async (organizationId: number, pricesListId: number) => {
  const response = await api.delete(`/${organizationId}/prices_lists/${pricesListId}`);
  return response.data;  
};

export const getPricesGroup = async (organizationId: number, pricesGroupId: number) => {
  const response = await api.get(`/${organizationId}/prices_groups/${pricesGroupId}`);
  return response.data;
};

export const createPricesGroup = async (organizationId: number, data: any) => {
  const response = await api.post(`/${organizationId}/prices_groups`, data);
  return response.data;
};

export const updatePricesGroup = async (organizationId: number, data: any) => {
  const response = await api.put(`/${organizationId}/prices_groups/${data.id}`, data);
  return response.data;
};

export const deletePricesGroup = async (organizationId: number, pricesGroupId: number) => {
  const response = await api.delete(`/${organizationId}/prices_groups/${pricesGroupId}`);
  return response.data;  
};

export const addGroupPrice = async (organizationId: number, priceGroupId: number, priceMakerId: number) => {
  const response = await api.post(`${organizationId}/prices_groups/${priceGroupId}/prices/${priceMakerId}`);
  return response.data;
};

export const removeGroupPrice = async (organizationId: number, priceGroupId: number, priceMakerId: number) => {
  const response = await api.delete(`${organizationId}/prices_groups/${priceGroupId}/prices/${priceMakerId}`);
  return response.data;
};