import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { requestStatus } from '../../constants/requestStatus';
import {
  getPriceMakers as getPriceMakersService,
  updatePriceMaker as updatePriceMakerService,
  createPriceMaker as createPriceMakerService,
  deletePriceMaker as deletePriceMakerService,
} from '../../services/priceMakersService';
import { clearStore } from '../actions/clearStore';

interface StateInterface{
  priceMakers: any[],
  fetchingStatus: string,
  itemOnEdition: any,
}

const initialState: StateInterface = {
  priceMakers: [],
  fetchingStatus: requestStatus.READY,
  itemOnEdition: null,
};

export const getPriceMakers = createAsyncThunk(
  'getPriceMakers',
  async (_, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getPriceMakersService(organizationId);
    return response.data;
  }
);

export const updatePriceMaker = createAsyncThunk(
  'updatePriceMaker',
  async ({priceMaker}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await updatePriceMakerService(organizationId, priceMaker);
    return response.data;
  }
);

export const createPriceMaker = createAsyncThunk(
  'createPriceMaker',
  async ({priceMaker}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await createPriceMakerService(organizationId, priceMaker);
    return response.data;
  }
);

export const deletePriceMaker = createAsyncThunk(
  'deletePriceMaker',
  async ({id}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await deletePriceMakerService(organizationId, id);
    return response.data;
  }
);

const formatDownloadPriceMaker = (data: any) => {
  return {
    id: data.id,
    productId: data.product_id,
    description: data.description,
    formula: data.formula,
    parsedFormula: JSON.parse(data.parsed_formula),
    value: data.result
  };
};

const priceMakersSlice = createSlice({
  name: 'priceMakers',
  initialState,
  reducers: {
    setItemOnEdition: ((state, action: PayloadAction<any>) => {
      if(action.payload){
        state.itemOnEdition = state.priceMakers.find((priceMaker: any) => priceMaker.id === action.payload);
      } else {
        state.itemOnEdition = null;
      }
    })
  },
  extraReducers: (builder) => {
    // CLEAR STORE
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });
    
    builder.addCase(getPriceMakers.pending, (state) => {
      state.fetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(getPriceMakers.fulfilled, (state, action) => {
      state.priceMakers = action.payload.map((priceMaker: any) => {
        return formatDownloadPriceMaker(priceMaker);
      });
      state.fetchingStatus = requestStatus.SUCCESS;
    });
    builder.addCase(getPriceMakers.rejected, (state) => {
      state.fetchingStatus = requestStatus.FAILED;
    });

    builder.addCase(createPriceMaker.fulfilled, (state, action) => {
      const newItem = formatDownloadPriceMaker(action.payload);
      state.priceMakers = state.priceMakers.concat(newItem);
    });

    builder.addCase(updatePriceMaker.fulfilled, (state, action) => {
      state.priceMakers = state.priceMakers.map((priceMaker: any) => {
        if(priceMaker.id === action.payload.id){
          return formatDownloadPriceMaker(action.payload);
        }
        return priceMaker;
      });
    });

    builder.addCase(deletePriceMaker.fulfilled, (state, action) => {
      state.priceMakers = state.priceMakers.filter((priceMaker: any) => priceMaker.id !== action.meta.arg.id);
    });
  }
});

export default priceMakersSlice.reducer;

export const {setItemOnEdition} = priceMakersSlice.actions;