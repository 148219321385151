import React, { useEffect } from 'react';
import { Loader } from '../../../components/Loader';
import { TableCell, TableColumnHeader, TableContainer, TableEnvironment, TableRow } from '../../../components/Table';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getPricesLists, setSearchParams, deletePricesList, createEmptyPricesList } from '../../../store/reducers/pricesListsSlice';
import { useMyContext } from '../../../store/Context';
import { Button } from '../../../components/inputs';
import { EditIconButton } from '../../../components/CustomIconButton/EditIconButton';
import { DeleteIconButton } from '../../../components/CustomIconButton/DeleteIconButton';

interface PricesListsListProps {
  onDisplayDetail: any
}

export function PricesListsList ({onDisplayDetail}: PricesListsListProps) {

  const lang = useMyContext();
  const dispatch = useAppDispatch();
  const { pricesLists, fetchingStatus, searchParams } = useAppSelector((state) => state.pricesLists);

  useEffect(() => {
    dispatch(getPricesLists());
  }, []);

  const setOrder = (orderData: any) => dispatch(setSearchParams(
    {
      ...searchParams,
      orderBy: orderData.field,
      order: orderData.isDesc? 'DESC' : 'ASC',
    }
  ));

  const handleEdit = (id: number) => {
    onDisplayDetail(id);
  };

  const handleDelete = (id: number) => {
    dispatch(deletePricesList(id));
  };

  const createNewList = () => {
    dispatch(createEmptyPricesList());
  };

  return (
    <TableEnvironment>
      <Button onClick={createNewList}>{lang.prices.newPricesList}</Button>
      <Loader fetchingStatus={fetchingStatus}>
        <TableContainer>
          <TableRow gridLayout='3fr 3fr 1fr 1fr'>
            <TableCell>
              <TableColumnHeader
                name='title'
                text={lang.attributes.title}
                isActive={searchParams.orderBy === 'date'}
                isDesc={searchParams.order === 'DESC'}
                setOrder={setOrder}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='subtitle'
                text={lang.attributes.subtitle}
                isActive={searchParams.orderBy === 'date'}
                isDesc={searchParams.order === 'DESC'}
                setOrder={setOrder}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                name='date'
                text={lang.attributes.date}
                isActive={searchParams.orderBy === 'date'}
                isDesc={searchParams.order === 'DESC'}
                setOrder={setOrder}
              />
            </TableCell>
            <TableCell>
              <TableColumnHeader
                text={lang.attributes.options}
              />
            </TableCell>
          </TableRow>
          {
            pricesLists.map((pricesList: any) => {
              return (
                <TableRow 
                  gridLayout='3fr 3fr 1fr 1fr' 
                  key={pricesList.id}
                >
                  <TableCell>
                    {pricesList.title}
                  </TableCell>
                  <TableCell>
                    {pricesList.subtitle}
                  </TableCell>
                  <TableCell>
                    {pricesList.date}
                  </TableCell>
                  <TableCell>
                    <EditIconButton onClick={() => handleEdit(pricesList.id)} />
                    <DeleteIconButton onClick={() => handleDelete(pricesList.id)} />
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableContainer>
      </Loader>
    </TableEnvironment>
  );
}