import React from 'react';
import styled from '@emotion/styled';

const ValueContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border-radius: 12px;
  border: 1px solid grey;
  margin: 5px;
`;

const Title = styled.p`
  border-bottom: solid 1px grey;
  padding: 1%;
  box-sizing: border-box;
  font-size: 1.2rem;
`;

interface ValueContainerProps{
  title: string,
  content: any,
  variationPercentage?: number
}

export const ValueContainer = ({title, content, variationPercentage}: ValueContainerProps) => {
  return (
    <ValueContainerWrapper>
      <Title>{title}</Title>
      <p>
        {content}
        {
          variationPercentage !== undefined &&
          <Variation percentage={variationPercentage}/>
        }
      </p>
    </ValueContainerWrapper>
  );
};

interface StyledVariationProps{
  positive: boolean
}

const StyledVariation = styled.span<StyledVariationProps>`
  font-size: 0.8rem;
  color: ${props => props.positive? 'green' : 'red'};
  margin-left: 15px;
  `;

interface VariationProps{
  percentage: number
}

const Variation = ({percentage}: VariationProps) => {
  return (
    <StyledVariation positive={percentage >= 0}>
      {percentage}%
    </StyledVariation>
  );
};