import api from './config/api';

export const login = async (email: string, password: string): Promise<any> => {
  const response = await api.post('/login', {
    email,
    password,
  });
  return response.data;
};

export const me = async () => {
  const response = await api.get('/me');
  return response.data;
};

export const logout = async () => {
  const response = await api.post('/logout');
  return response.data;
};

export const register = async (name: string, email: string, password: string): Promise<any> => {
  const response = await api.post('/register');
  return response.data;
};

export const isValidToken = async () => {
  try {
    const response = await api.get('/isValidToken');
    return response?.data?.is_valid_token;
  } catch {
    return false;
  }
};