import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
`;

const Selector = styled.button`
  background: none;
  border: none;
  display: block;
  :hover{
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface LapsePresetsProps{
  onChange: any
}

export function LapsePresets ({onChange}: LapsePresetsProps) {

  const handleSelect = (after: number, before: number) => {
    onChange({
      after,
      before
    });
  };

  const selectLast7 = () => {
    const { now } = getDates();
    const after = now - (7*24*60*60*1000);
    const before = now;
    handleSelect(after, before);
  };

  const selectThisMonth = () => {
    const { year, month } = getDates();
    const after = new Date(year, month, 1).getTime();
    const before = Date.now();
    handleSelect(after, before);
  };

  const selectPastMonth = () => {
    const { year, month } = getDates();
    let pastMonth;
    let pastMonthYear;
    if(month === 0){
      pastMonth = 11;
      pastMonthYear = year - 1;
    } else {
      pastMonth = month - 1;
      pastMonthYear = year;
    }
    const after = new Date(pastMonthYear, pastMonth, 1).getTime();
    const before = new Date(pastMonthYear, month, 0).getTime();
    handleSelect(after, before);
  };

  const selectLast30 = () => {
    const { now } = getDates(); 
    const after = now - (30*24*60*60*1000);
    const before = now;
    handleSelect(after, before);
  };

  const selectThisYear = () => {
    const { now, year } = getDates();
    const after = new Date(year, 0, 1).getTime();
    const before = now;
    handleSelect(after, before);
  };

  const selectLast365 = () => {
    const { now } = getDates();
    const after = now - (365*24*60*60*1000);
    const before = now;
    handleSelect(after, before);
  };

  const getDates = () => {
    const date = new Date();
    const now = Date.now();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return {
      now, year, month, day
    };
  };

  return (
    <Wrapper>
      <Selector onClick={selectLast7}>Últimos 7 días</Selector>
      <Selector onClick={selectThisMonth}>Este mes</Selector>
      <Selector onClick={selectPastMonth}>Mes anterior</Selector>
      <Selector onClick={selectLast30}>Últimos 30 días</Selector>
      <Selector onClick={selectThisYear}>Este año</Selector>
      <Selector onClick={selectLast365}>Último año</Selector>
    </Wrapper>
  );
}