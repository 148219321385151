export const useSort = (a: any, b: any, fieldName: string, isDesc: boolean) => {
  const x = typeof(a[fieldName]) === 'string'? a[fieldName].toLowerCase() : a[fieldName];
  const y = typeof(b[fieldName]) === 'string'? b[fieldName].toLowerCase() : b[fieldName];
  if(isDesc){
    if(x < y){
      return 1;
    }
    if(x > y){
      return -1;
    }
    return 0;
  } else {
    if(x > y){
      return 1;
    }
    if(x < y){
      return -1;
    }
    return 0;
  }
};