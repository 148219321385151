import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestStatus } from '../../constants/requestStatus';
import {
  getIncomes as getIncomesService,
  createIncome as createIncomeService,
  updateIncome as updateIncomeService,
  deleteIncome as deleteIncomeService,
  getIncomesAmount as getIncomesAmountService,
} from '../../services/transactionsService';
import { clearStore } from '../actions/clearStore';
interface Income {
  id: number
  type: string
  date: string
  quantity: number
  concept: string
  value: number
  status: string
  clientId: number
}

interface SearchParams{
  searchTerm: string,
  page: number,
  limit: number,
  orderBy: string,
  order: string,
  after?: string,
  before?: string,
  isLapseActive: boolean,
}

interface State {
  incomes: Income[]
  incomesFetchingStatus: string
  lastPage: number 
  currentPeriodIncomesAmount: number
  previousPeriodIncomesAmount: number
  isSynchronized: boolean
  searchParams: SearchParams
}

const initialState: State = {
  incomes: [],
  incomesFetchingStatus: requestStatus.READY,
  lastPage: 10,
  currentPeriodIncomesAmount: 0,
  previousPeriodIncomesAmount: 0,
  isSynchronized: true,
  searchParams: {
    searchTerm: '',
    page: 1,
    limit: 10,
    orderBy: 'date',
    order: 'desc',
    isLapseActive: false,
    after: (new Date()).toISOString().split('T')[0],
    before: (new Date()).toISOString().split('T')[0],
  }
};

export const getIncomes = createAsyncThunk(
  'getIncomes',
  async (_, { getState }) => {
    const { organization: { id: organizationId }, incomes: { searchParams }} = getState() as any;
    const response = await getIncomesService(organizationId, searchParams);
    return response.data;
  }
);

export const createIncome = createAsyncThunk(
  'createIncome',
  async({ data }: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await createIncomeService(organizationId, data);
    return response.data;
  }
);

export const updateIncome = createAsyncThunk(
  'updateIncome',
  async ({ incomeId, data }: {
    incomeId: number,
    data: {
      id?: number,
      date?: string,
      quantity?: number,
      concept?: string,
      value?: number,
      clientId?: number
    }
  }, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await updateIncomeService(organizationId, incomeId, data);
    return response.data;
  }
);

export const deleteIncome = createAsyncThunk(
  'deleteIncome',
  async ({ incomeId }: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await deleteIncomeService(organizationId, incomeId);
    return response.data;
  }
);

export const getIncomesAmount = createAsyncThunk(
  'getIncomesAmount',
  async ({ searchParams }: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getIncomesAmountService(organizationId, searchParams);
    return response.data;
  } 
);

const formatDownload = (income: any): Income => {
  return {
    id: income.id,
    type: income.type,
    date: income.date,
    quantity: income.quantity,
    concept: income.concept,
    value: income.value,
    status: requestStatus.READY,
    clientId: income.client_id,
  };
};

export const incomesSlice = createSlice({
  name: 'incomes',
  initialState,
  reducers: {
    setIncomesSearchParams: (state, action) => {
      state.searchParams = action.payload;
    }
  },
  extraReducers: (builder) => {
    // CLEAR STORE
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });
    
    // getIncomes
    builder.addCase(getIncomes.pending, (state) => {
      state.incomesFetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(getIncomes.fulfilled, (state, action) => {
      state.lastPage = action.payload.last_page;
      state.incomes = action.payload.data.map((income: any) => {
        return formatDownload(income);
      });
      state.incomesFetchingStatus = requestStatus.SUCCESS;
      state.isSynchronized = true;
    });
    builder.addCase(getIncomes.rejected, (state) => {
      state.incomesFetchingStatus = requestStatus.FAILED;
    });

    // createIncome
    builder.addCase(createIncome.fulfilled, (state) => {
      state.isSynchronized = false;
    });

    // updateIncome
    builder.addCase(updateIncome.pending, (state, action) => {
      state.incomes = state.incomes.map((income: any) => 
        income.id === action.meta.arg.data.id?
          {
            ...income,
            updatingStatus: requestStatus.PENDING
          } 
          :
          income,
      );
    });
    builder.addCase(updateIncome.fulfilled, (state) => {
      state.isSynchronized = false;
    });
    builder.addCase(updateIncome.rejected, (state, action) => {
      state.incomes = state.incomes.map((income: any) => 
        income.id == action.meta.arg.data.id?
          {
            ...income,
            updatingStatus: requestStatus.FAILED
          } 
          :
          income,
      );
    });

    // deleteIncome
    builder.addCase(deleteIncome.fulfilled, (state) => {
      state.isSynchronized = false;
    });

    builder.addCase(getIncomesAmount.fulfilled, (state, action) => {
      state.currentPeriodIncomesAmount = action.payload.current_amount?? 0;
      state.previousPeriodIncomesAmount = action.payload.previous_amount?? 0;
    });

  }
});

export default incomesSlice.reducer;
export const { setIncomesSearchParams } = incomesSlice.actions;