import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import React from 'react';
import { IoMdClose } from 'react-icons/io';

export const Modal = ({ display, setDisplay, children, title }: {
  display: boolean,
  setDisplay: (display: boolean) => void,
  children: React.ReactNode,
  title?: string
}) => {
  return (
    <ModalContainer display={display ? 'true' : 'false'}>
      <ModalContent>
        <OpBar>
          <IconButton onClick={() => setDisplay(false)}>
            <IoMdClose/>
          </IconButton>
        </OpBar>
        <div>
          <h2>{title ?? ''}</h2>
          {children}
        </div>
      </ModalContent>
    </ModalContainer>
  );
};

type ModalProps = {
  display: string,
}

const ModalContainer = styled('div')<ModalProps>`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props: {display: string}) => props.display === 'true' ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  width: 50%;
  min-width: 300px;
  max-width: 600px;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-sizing: border-box;
`;

const OpBar = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '1rem',
});