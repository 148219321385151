import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { requestStatus } from '../../constants/requestStatus';
import {
  getClients as getClientsService,
  createClient as createClientService,
  updateClient as updateClientService,
  deleteClient as deleteClientService
} from '../../services/clientService';
import { clearStore } from '../actions/clearStore';

interface InitialState{
  clients: any[]
  clientsFetchingStatus: string
  clientCreatingStatus: string
  lastPage: number
}

const initialState: InitialState = {
  clients: [],
  clientsFetchingStatus: requestStatus.READY,
  clientCreatingStatus: requestStatus.READY,
  lastPage: 10
};

export const getClients = createAsyncThunk(
  'getClients',
  async (_, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getClientsService(organizationId);
    return response.data;
  }
);

export const createClient = createAsyncThunk(
  'createClient',
  async ({data}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await createClientService(organizationId, data);
    return response.data;
  }
);

export const updateClient = createAsyncThunk(
  'updateClient',
  async ({id, data}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await updateClientService(organizationId, id, data);
    return response.data;
  }
);

export const deleteClient = createAsyncThunk(
  'deleteClient',
  async ({id}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await deleteClientService(organizationId, id);
    return response.data;
  }
);

const formatClientDownload = (client: any) => {
  return {
    id: client.id,
    name: client.name,
    phone: client.phone,
    email: client.email,
    address: client.address,
  };
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // CLEAR STORE
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });

    builder.addCase(getClients.pending, (state) => {
      state.clientsFetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.clients = action.payload.data.map((client: any) => {
        return formatClientDownload(client);
      });
      state.clientsFetchingStatus = requestStatus.SUCCESS;
      state.lastPage = action.payload.last_page;
    });
    builder.addCase(getClients.rejected, (state) => {
      state.clientsFetchingStatus = requestStatus.FAILED;
    });

    builder.addCase(createClient.pending, (state) => {
      state.clientCreatingStatus = requestStatus.PENDING;
    });
    builder.addCase(createClient.fulfilled, (state, action) => {
      state.clients = state.clients.concat(formatClientDownload(action.payload));
      state.clientCreatingStatus = requestStatus.SUCCESS;
    });
    builder.addCase(createClient.rejected, (state) => {
      state.clientCreatingStatus = requestStatus.FAILED;
    });

    builder.addCase(updateClient.pending, (state, action: PayloadAction<any>) => {
      state.clients = state.clients.map((client: any) => {
        if(client.id === action.payload.data.id){
          return {
            ...action.payload.data.id,
            updatingStatus: requestStatus.PENDING,
          };
        }
        return client;
      });
    });
    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.clients = state.clients.map((client: any) => {
        if(client.id === action.payload.data.id){
          return {
            ...action.payload.data.id,
            updatingStatus: requestStatus.SUCCESS,
          };
        }
        return client;
      });
    });
    builder.addCase(updateClient.rejected, (state, action: PayloadAction<any>) => {
      state.clients = state.clients.map((client: any) => {
        if(client.id === action.payload.id){
          return {
            ...action.payload.id,
            updatingStatus: requestStatus.FAILED,
          };
        }
        return client;
      });
    });

    builder.addCase(deleteClient.pending, (state, action) => {
      state.clients = state.clients.map((client: any) => {
        if(client.id === action.meta.arg.id){
          return {
            ...client,
            deletingStatus: requestStatus.PENDING,
          };
        }
        return client;
      });
    });
    builder.addCase(deleteClient.fulfilled, (state, action) => {
      state.clients = state.clients.filter((client: any) => {
        return client.id != action.meta.arg.id;
      });
    });
    builder.addCase(deleteClient.rejected, (state, action) => {
      console.log(action);
      state.clients = state.clients.map((client: any) => {
        if(client.id === action.meta.arg.id){
          return {
            ...client,
            deletingStatus: requestStatus.FAILED,
          };
        }
        return client;
      });
    });
  },
});

export default clientsSlice.reducer;