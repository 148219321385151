import { createSlice } from '@reduxjs/toolkit';
import { clearStore } from '../actions/clearStore';

interface InterfaceState{
    displayProfileMenu: boolean,
    displayOrganizationsMenu: boolean,
    displayResourceEditor: boolean,
    displayProductEditor: boolean,
}

const initialState: InterfaceState = {
  displayProfileMenu: false,
  displayOrganizationsMenu: false,
  displayResourceEditor: false,
  displayProductEditor: false,
};

export const interfaceSlice = createSlice({
  name: 'interface',
  initialState,
  reducers: {
    switchProfileMenu: ((state) => {
      state.displayProfileMenu = !state.displayProfileMenu;
    }),
    switchOrganizationsMenu: ((state) => {
      state.displayOrganizationsMenu = !state.displayOrganizationsMenu;
    }),
    openResourceEditor: ((state) => {
      state.displayResourceEditor = true;
    }),
    closeResourceEditor: ((state) => {
      state.displayResourceEditor = false;
    }),
    openProductEditor: ((state) => {
      state.displayProductEditor = true;
    }),
    closeProductEditor: ((state) => {
      state.displayProductEditor = false;
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });
  }
});

export const { 
  switchProfileMenu,
  switchOrganizationsMenu,
  openResourceEditor,
  closeResourceEditor,
  openProductEditor,
  closeProductEditor,
} = interfaceSlice.actions;

export default interfaceSlice.reducer;