import React, { useEffect, useState } from 'react';
import { useMyContext } from '../../store/Context';
import { Wrapper } from '../../components/Wrapper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { 
  TableEnvironment, 
  TableContainer, 
  TableRow, TableCell, 
  TableBarContainer, 
  TableTextFilter, 
  TableCategoryFilter, 
  TableColumnHeader } 
  from '../../components/Table';
import { useFormat } from '../../hooks/useFormat';
import { TableButton } from '../../components/Table/TableButton';
import { ProductEditor } from './ProductEditor';
import {
  getProducts,
  deleteProduct,
  setProductOnEdition
} from '../../store/reducers/productsSlice';
import {
  getCategories
} from '../../store/reducers/productsCategoriesSlice';
import { openProductEditor } from '../../store/reducers/interfaceSlice';
import { useSort } from '../../hooks/useSort';
import { TagsBox } from '../../components/Tags';
import { Loader } from '../../components/Loader';
import { EditIconButton } from '../../components/CustomIconButton/EditIconButton';
import { DeleteIconButton } from '../../components/CustomIconButton/DeleteIconButton';

export function Products () {
  const lang = useMyContext();
  const format = useFormat;
  const dispatch = useAppDispatch();
  const sortByField = useSort;
  const { ...organization } = useAppSelector((state) => state.organization);

  const { products, fetchingProductsStatus } = useAppSelector((state) => state.products);
  const { categories } = useAppSelector((state) => state.productsCategories);

  const [textFilter, setTextFilter] = useState('');
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>([]);
  const [isCategoryFilterEnabled, setIsCategoryFilterEnabled] = useState(false);

  const [orderField, setOrderField] = useState({
    field: 'shortDescription',
    isDesc: true,
  });

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getCategories());
  }, [organization.id]);

  useEffect(() => {
    setFilteredProducts(
      products
        .filter((product:any) => product.shortDescription.toLowerCase().match(textFilter.toLowerCase()))
        .filter((product:any) => isCategoryFilterEnabled? 
          product.categories.some((category: any) => {
            return categoriesFilter.includes(category.id);
          }) : true)
        .sort((a: any, b: any) => sortByField(a,b, orderField.field, orderField.isDesc))
    );
  }, [products, textFilter, categoriesFilter, orderField, isCategoryFilterEnabled]);

  const handleNew = () => {
    dispatch(openProductEditor());
  };

  const handleEdit = (productId: number) => {
    dispatch(setProductOnEdition(productId));
    dispatch(openProductEditor());
  };

  const handleDelete = (productId: number) => {
    const sure = window.confirm(lang.messages.onDeleteMessage);
    if(sure){
      dispatch(deleteProduct({
        productId}));
    }
  };
  return (
    <Wrapper title={lang.modules.products}>
      <Loader fetchingStatus={fetchingProductsStatus}>
        <TableEnvironment>
          <TableBarContainer>
            <TableTextFilter
              setter={setTextFilter}
            />
            <TableCategoryFilter
              type={'Categorias'}
              categories={categories}
              setFilteredCategories={setCategoriesFilter}
              setCategoryFilterStatus={setIsCategoryFilterEnabled}
            />
            <TableButton text={lang.products.new} onClick={handleNew}/>
          </TableBarContainer>
          <TableContainer>
            <TableRow gridLayout='4fr 4fr 3fr 1fr'>
              <TableCell>
                <TableColumnHeader 
                  name='shortDescription' 
                  text={lang.attributes.shortDescription} 
                  isActive={orderField.field ==='shortDescription'} 
                  isDesc={orderField.isDesc} 
                  setOrder={setOrderField}
                />
              </TableCell>
              <TableCell>
                <TableColumnHeader 
                  name='longDescription' 
                  text={lang.attributes.longDescription} 
                  isActive={orderField.field==='longDescription'} 
                  isDesc={orderField.isDesc} 
                  setOrder={setOrderField}
                />
              </TableCell>
              <TableCell>
                <TableColumnHeader 
                  name='category' 
                  text={lang.attributes.category} 
                  isActive={orderField.field==='category'} 
                  isDesc={orderField.isDesc} 
                  setOrder={setOrderField}
                />
              </TableCell>
              <TableCell>
                <TableColumnHeader text={lang.options} />
              </TableCell>
            </TableRow>
            {
              filteredProducts.map((product: any) => {
                return(
                  <TableRow gridLayout='4fr 4fr 3fr 1fr' key={product.id}>
                    <TableCell>{format(product.shortDescription, 'firstCap')}</TableCell>
                    <TableCell>{product.longDescription}</TableCell>
                    <TableCell>
                      <TagsBox
                        tags={product.categories}
                      />
                    </TableCell>
                    <TableCell>
                      <EditIconButton onClick={() => handleEdit(product.id)} />
                      <DeleteIconButton onClick={() => handleDelete(product.id)} />
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableContainer>
        </TableEnvironment>
      </Loader>
      <ProductEditor/>
    </Wrapper>
  );
}