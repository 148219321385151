import api from './config/api';

export const getPriceMakers = async (organizationId: number) => {
  const response = await api.get(`${organizationId}/price_makers`);
  return response.data;
};

export const updatePriceMaker = async (organizationId: number, priceMaker: any) => {
  const response = await api.put(`${organizationId}/price_makers/${priceMaker.id}`, {
    ...formatUploadPriceMaker(priceMaker)
  });
  return response.data;
};

export const createPriceMaker = async (organizationId: number, priceMaker: any) => {
  const response = await api.post(`${organizationId}/price_makers`, {
    ...formatUploadPriceMaker(priceMaker)
  });
  return response.data;
};

const formatUploadPriceMaker = (priceMaker: any) => {
  return {
    description: priceMaker.description,
    formula: priceMaker.formula,
    parsed_formula: priceMaker.parsedFormula,
    product_id: parseInt(priceMaker.productId),
    value: priceMaker.value,
  };
};

export const deletePriceMaker = async (organizationId: number, pricerMakerId: number) => {
  const response = await api.delete(`${organizationId}/price_makers/${pricerMakerId}`);
  return response.data;
};