import React, { createContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PriceMakerReference } from './PriceMakerReference';
import { PriceMakerOperator } from './PriceMakerOperator';
import { PriceMakerValue } from './PriceMakerValue';
import { EmptyGroupAggregator, FormulaItem } from './OperatorAggregator/OperatorAggregator';

interface PriceMakerGroupProps {
  groupType?: string
}

const PriceMakerGroup = styled.div<PriceMakerGroupProps>`
  width: 92%;
  margin: auto 0px auto auto;
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 15px;
  border: solid 2px black;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: flex-end;
  font-size: 1.1rem;
  position: relative;
  input{
    font-size: 1.1rem;
  }
  ${props => props.groupType === 'compact'? 
    `
      flex-direction: row;
    `
    : ''
}
  }
`;


interface FormulaMaker{
  parsedFormula: any[]
  onChange?: any
  groupType?: string
}

export default function FormulaMaker({parsedFormula, onChange, groupType = 'group'}: FormulaMaker) {
  
  const handleAggregation = (index: number, aggregation: any) => {
    if(parsedFormula.length < index+1){
      onChange([
        groupType,
        parsedFormula.concat(aggregation)
      ]);
    } else {
      onChange([
        groupType,
        parsedFormula.slice(0, index).concat(aggregation).concat(parsedFormula.slice(index))
      ]);
    }
  };
  
  const handleValueChange = (index: number, value: any) => {
    const newFormula = parsedFormula.map((item: any, currentIndex: number) => {
      if(index === currentIndex){
        return value;
      }
      return item;
    });
    onChange([groupType, newFormula]);
  };

  const handleDelete = (index: number) => {
    onChange([
      groupType,
      parsedFormula.filter((element: any, elementIndex: number) => elementIndex !== index)
    ]);
  };

  return (
    <PriceMakerGroup groupType={groupType === 'monomial'? 'compact' : ''}>
      {
        parsedFormula &&
        parsedFormula.map((element: any, index: number) => {
          switch(element[0]){
          case 'value':
            if(groupType === 'monomial'){
              return (
                <PriceMakerValue 
                  value={element[1]} 
                  onChange={(value: number) => handleValueChange(index, value)}
                  type='compact'
                  key={index}
                />
              );
            }
            return (
              <FormulaItem 
                onPreAggregate={(aggregation: any) => handleAggregation(index, aggregation)} 
                onPostAggregate={(aggregation: any) => handleAggregation(index+1, aggregation)} 
                onDelete={() => handleDelete(index)}
                key={index}
              >
                <PriceMakerValue 
                  value={element[1]} 
                  onChange={(value: number) => handleValueChange(index, value)}
                />
              </FormulaItem>
            );
          case 'operator': 
            return (
              <PriceMakerOperator 
                key={index}
                operator={element[1]}
                onChange={(value: string) => handleValueChange(index, value)}
                onDelete={() => handleDelete(index)}
                mutable={groupType !== 'monomial'}
              />
            );
          case 'product':
          case 'resource':
          case 'modifier':
            if(groupType === 'monomial'){
              return (
                <PriceMakerReference 
                  type={element[0]} 
                  id={element[1]} 
                  onChange={(value: string) => handleValueChange(index, value)}
                  key={index}
                />
              );
            } else {
              return (
                <FormulaItem 
                  onPreAggregate={(aggregation: any) => handleAggregation(index, aggregation)}
                  onPostAggregate={(aggregation: any) => handleAggregation(index+1, aggregation)}
                  onDelete={() => handleDelete(index)}
                  key={index}
                >
                  <PriceMakerReference 
                    type={element[0]} 
                    id={element[1]} 
                    onChange={(value: string) => handleValueChange(index, value)}
                  />
                </FormulaItem>
              );
            }
          case 'group':
          case 'monomial':
            return (
              <FormulaItem 
                onPreAggregate={(aggregation: any) => handleAggregation(index, aggregation)} 
                onPostAggregate={(aggregation: any) => handleAggregation(index+1, aggregation)} 
                onDelete={() => handleDelete(index)}
                key={index}
              >
                <FormulaMaker 
                  parsedFormula={element[1]}
                  onChange={(value: any) => handleValueChange(index, value)}
                  groupType={element[0] === 'monomial'? 'monomial' : 'group'}
                />
              </FormulaItem>
            );
          }
        })
      }
      {
        parsedFormula?.length === 0 &&
          <EmptyGroupAggregator 
            onAggregate={(aggregation: any) => handleAggregation(0, aggregation)}
          />
      }
    </PriceMakerGroup>
  );
}


