import React from 'react';
import { useMyContext } from '../../store/Context';
import { ItemEditorWrapper } from './styles';

interface EditorProps{
    children: any
    display: boolean
    title: string
    close: any
    save: any
}

export const ItemEditor = ({children, display, title, close, save}: EditorProps) => {
  const lang = useMyContext();
  return (
    <ItemEditorWrapper
      display = {display}
      title = {title}
      close = {close}
      save = {save}
      cancelName = {lang.cancel}
      saveName = {lang.save}
    >
      {children}
    </ItemEditorWrapper>
  );
};