import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useMyContext } from '../../../store/Context';
import { ItemEditor } from '../../../components/ItemEditor';
import { Field, Group, Label } from '../../../components/ItemEditor/styles';
import { Button, Input } from '../../../components/inputs';
import { useAppSelector } from '../../../store/hooks';
import { Select } from '../../../components/ItemEditor/styles/Select';
import { useSelectValues } from '../../../hooks/useSelectValues';
import FormulaMaker from './FormulaMaker';
import { useGenerateFormula } from './hooks/useGenerateFormula';
import { useGetFormulaResult } from './hooks/useGetFormulaResult';
import { colors } from '../../../styles/variables';

interface PriceMakerProps{
  display: boolean,
  title: string,
  close: any,
  save: any,
}

export default function PriceMakerEditor({display, title, close, save}: PriceMakerProps) {

  const lang = useMyContext();
  const generateFormula = useGenerateFormula();
  const getFormulaResult = useGetFormulaResult();

  interface FormData{
    description: string,
    productId: number | null,
    formula: string,
    parsedFormula: any[],
    result: number,
  }

  const initialFormData: FormData = {
    description: '',
    productId: null,
    formula: '',
    parsedFormula: [],
    result: 0,
  };

  const { itemOnEdition } = useAppSelector((state) => state.priceMakers);
  const { products } = useAppSelector((state) => state.products);

  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isResultUpdated, setIsResultUpdated] = useState(true);
  const [productSelectValues, setProductSelectValues] = useState<any[]>([]);

  useEffect(() => {
    if(itemOnEdition !== null){
      setFormData(itemOnEdition);
    } else {
      setFormData(initialFormData);
    }
    setIsResultUpdated(true);
  }, [itemOnEdition]);

  useEffect(() => {
    setProductSelectValues(
      useSelectValues(products, 'id', 'shortDescription')
    );
  }, [products]);

  useEffect(() => {
    setIsResultUpdated(false);
    const updatedFormula = generateFormula(formData.parsedFormula);
    setFormData({
      ...formData,
      formula: updatedFormula,
    });
  }, [formData.parsedFormula]);
  
  const updateFormulaResult = async () => {
    const result = await getFormulaResult(formData.formula);
    setFormData({
      ...formData,
      result,
    });
    setIsResultUpdated(true);
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleParsedFormulaChange = (updatedParsedFormula: any) => {
    setFormData({
      ...formData,
      parsedFormula: updatedParsedFormula[1],
    });
  };

  const onSave = () => {
    save(formData);
  };

  return (
    <ItemEditor
      display={display}
      title={title}
      close={close}
      save={onSave}
    >
      <h2></h2>
      <Group>
        <Field size={ 4 }>
          <Select
            name='productId'
            values={productSelectValues}
            selectedValue={formData.productId?? ''}
            onSelect={handleChange}
          />
        </Field>
        <Field size={4}>
          <Label>
            {lang.prices.editor.description}
          </Label>
          <Input 
            name='description'
            type='text'
            value={formData.description}
            onChange={handleChange}
          />
        </Field>
      </Group>
      <Group>
        <Field size = {8}>
          <FormulaMaker
            parsedFormula={formData.parsedFormula}
            onChange={handleParsedFormulaChange}
          />
        </Field>
      </Group>
      <Group>
        {
          isResultUpdated &&
          <Result>
            {`$${formData.result}`}
          </Result>
        }
        {
          !isResultUpdated &&
          <Button onClick={updateFormulaResult}>Actualizar</Button>
        }
      </Group>
    </ItemEditor>
  );
}

const Result = styled.div`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${colors.softHighlight};
`;