import React, { useEffect, useState } from 'react';
import { useMyContext } from '../../../store/Context';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createIncome } from '../../../store/reducers/incomesSlice';
import { TableBarAccordion } from '../../../components/Table';
import { Wrapper } from '../../../components/Wrapper';
import { InlineItemCreator } from '../../../components/Table/InlineItemCreator';
import { Input } from '../../../components/inputs';
import { Select } from '../../../components/ItemEditor/styles/Select';
import ReactSelect from 'react-select';
import incomeTypes from '../../../config/incomeTypes';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { MdOutlineExpandMore } from 'react-icons/md';
import { useFormatDate } from '../../../hooks/useFormatDate';
import IncomesList from './IncomesList';
import { getProducts } from '../../../store/reducers/productsSlice';
import { IncomesCharts } from './IncomesCharts';

export default function Incomes () {

  const lang = useMyContext();
  const dispatch = useAppDispatch();
  const formatDate = useFormatDate;

  const newIncomeInitialState = {
    date: formatDate(Date.now()),
    type: incomeTypes[0],
    quantity: '',
    concept: '',
    value: '',
    clientId: null,
  };

  const [newIncome, setNewIncome] = useState<any>(newIncomeInitialState);

  const { id: organizationId } = useAppSelector((state) => state.organization);
  const { clients } = useAppSelector((state) => state.clients);
  const { products } = useAppSelector((state) => state.products);

  const [clientsOptions, setClientsOptions] = useState<any[]>([]);
  const [productsOptions, setProductsOptions] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getProducts());
  }, [organizationId]);

  useEffect(() => {
    setClientsOptions(
      clients.map((client: any) => {
        return {
          value: client.id,
          label: client.name,
        };
      })
    );
  }, [clients]);

  useEffect(() => {
    setProductsOptions(
      products.map((product: any) => {
        return {
          value: product.shortDescription,
          label: product.shortDescription,
        };
      })
    );
  }, [products]);

  const handleNewIncomeChange = (e: any) => {
    setNewIncome({
      ...newIncome,
      [e.target.name]: e.target.value,
    });
  };

  const handleNewIncomeClientChange = (e: any) => {
    setNewIncome({
      ...newIncome,
      clientId: e.value,
    });
  };

  const handleNewIncomeSaleConceptChange = (e: any) => {
    setNewIncome({
      ...newIncome,
      concept: e.value,
    });
  };

  const handleCreate = () => {
    dispatch(createIncome({data: newIncome}));
    setNewIncome({
      ...newIncome,
      quantity: '',
      value: '',
    });
  };

  return (
    <Wrapper title={lang.transactions.incomes.name}>
      <TableBarAccordion>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore />}
        >
          {lang.insert}
        </AccordionSummary>
        <AccordionDetails>
          <InlineItemCreator
            onCreate={handleCreate}
            insertEnabled={
              newIncome.quantity !== '' &&
              newIncome.value !== ''
            }
          >
            <label>{lang.attributes.date}</label>
            <Input
              name='date'
              type='date'
              value={newIncome.date}
              onChange={handleNewIncomeChange}
            />
            <label>{lang.attributes.type}</label>
            <Select
              name='type'
              values={
                incomeTypes.map((type: string) => ({
                  option: type,
                  name: lang.transactions.incomes.types[type]?? type,
                }))
              }
              selectedValue={newIncome.type}
              onSelect={handleNewIncomeChange}
            />
            <label>{lang.attributes.quantity}</label>
            <Input
              name='quantity'
              type='number'
              value={newIncome.quantity}
              onChange={handleNewIncomeChange}
            />
            <label>{lang.attributes.concept}</label>
            {
              newIncome.type === 'sale' ?
                <ReactSelect 
                  name='concept'
                  options={productsOptions}
                  onChange={handleNewIncomeSaleConceptChange}
                /> :
                <Input
                  name='concept'
                  type='text'
                  value={newIncome.concept}
                  onChange={handleNewIncomeChange}
                />
            }
            <label>{lang.attributes.client}</label>
            <ReactSelect
              name='client'
              options={clientsOptions}
              onChange={handleNewIncomeClientChange}
            />
            <label>{lang.attributes.value}</label>
            <Input
              name='value'
              type='number'
              value={newIncome.value}
              onChange={handleNewIncomeChange}
            />
          </InlineItemCreator>
        </AccordionDetails>
      </TableBarAccordion>
      <IncomesList/>
      <IncomesCharts/>
    </Wrapper>
  );
}

