import styled from '@emotion/styled';
import React from 'react';

export const ChartWidget = ({ children, title }: {
  children: React.ReactNode,
  title?: string,
}) => {
  return (
    <Container>
      { title && <h2>{ title }</h2> }
      { children }
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  min-width: 500px;
  overflow: auto;
  padding: 20px;
  background-color: #FAF2DF;
  border-radius: 10px;
  box-sizing: border-box;
`;