import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Input } from '../inputs';
import { useMyContext } from '../../store/Context';

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr 100px;
  grid-gap: 15px;
  align-items: center;
`;
  
const WidthInput = styled(Input)`
  width: 100%;
`;

const SmallButton = styled(Button)`
  margin: 0;
`;

export const QueryTextFilter = ({onTermSearch}: any) => {

  const [isModified, setIsModified] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const lang = useMyContext();

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
    setIsModified(true);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsModified(false);
    onTermSearch(searchTerm);
  };

  return (
    <Wrapper>
      <label>
        {lang.searchTerm}
      </label>
      <WidthInput
        name='searchTerm'
        type='text'
        value={searchTerm}
        onChange={handleChange}
      />
      <SmallButton 
        onClick={handleClick}
        disabled={!isModified}
      >
        {lang.search}
      </SmallButton>
    </Wrapper>
  );
};