import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import { colors } from '../../../styles/variables';

export const Sidebar = styled.div`
    width: 200px;
    max-width: 100%;
    background-color: ${colors.dark};
    padding-top: 10px;
`;

const StyledSidebarItem = styled.li`
    width: 100%,
    min-height: 30px;
    list-style: none;
    :hover{
      background-color: rgba(0,0,0,0.5);
    }
`;
      
const StyledSidebarLink = styled(Link)<{
  active: string,
}>(props  => ({
  display: 'block',
  fontSize: '1rem',
  padding: '10px',
  boxSizing: 'border-box',
  width: '100%',
  textDecoration: 'none',
  color: 'white',
  backgroundColor: props.active === 'true' ? 'rgba(0,0,0,0.5)' : 'transparent',
}));

export const SidebarItem = ({children, to}: any) => {
  const [active, setActive] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    const regex = new RegExp(`^[\\/]\\d+[\\/]${to}[\\/]?.*`, 'gi');
    if(regex.test(location.pathname)){
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location]);

  return (
    <StyledSidebarItem>
      <StyledSidebarLink to={to} active={active ? 'true' : 'false'}>
        {children}
      </StyledSidebarLink>
    </StyledSidebarItem>
  );
};