import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrganization } from '../../store/reducers/organizationSlice';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { OrganizationItems } from './OrganizationItems';
import { Button } from '../../components/inputs';
import styled from '@emotion/styled';

export function InitialOrganizationsSelector () {

  const { organizations } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [mappedOrganizations, setMappedOrganizations] = useState<any[]>([]);

  useEffect(() => {
    setMappedOrganizations(
      organizations.map((organization: any) => {
        return {
          text: organization.name,
          imageUrl: organization.imageUrl,
          id: organization.id,
        };
      })
    );
  }, [organizations]);

  const selectActiveOrganization = async (id: number) => {
    await dispatch(getOrganization(id));
    navigate(`/${id}`);
  };

  return(
    <Container>
      <h2>¿En qué organización deseas trabajar?</h2>
      <OrganizationItems 
        items={mappedOrganizations}
        onClick={selectActiveOrganization}
      />
      <div>
        <Button>Crear nueva organización</Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;