import React, { useState } from 'react';
import styled from '@emotion/styled';
import { CustomIconButton } from '../../../../components/CustomIconButton';  
import { BsTrash } from 'react-icons/bs';

const PriceRow = styled.div`
  width: 90%;
  height: 30px;
  margin-left: 10%;
  border-bottom: 1px solid rgb(200,200,200);
  display: grid;
  grid-template-columns: 1fr 100px 150px 60px;
  padding: 0.1rem;
  align-items: end;
  :hover {
    background-color: rgb(240,240,240);
    cursor: pointer;
  }
`;

const Description = styled.div`
  widht: 100%;
`;

const Price = styled.div`
  width: 100%;
`;

const UpdatedAt = styled.div`
  width: 100%;
  font: grey;
  font-size: 0.7rem;
`;

interface DeleteButtonProps {
  $display: boolean
}

const DeleteButton = styled(CustomIconButton)<DeleteButtonProps>`
  width: 28px;
  height: 28px;
  display: ${props => props.$display? 'inherit' : 'none'};
`;

interface PriceProps {
  price: any
  onDelete: any
}

export const PricesGroupPrice = ({ price, onDelete }: PriceProps) => {

  const [ isMouseOver, setIsMouseOver ] = useState(false);
  const getDateString = (ISODate: string) => {
    return new Date(ISODate).toLocaleDateString();
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <PriceRow key={price.id}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <Description>{price.product.shortDescription}</Description>
      <Price>${price.result}</Price>
      <UpdatedAt>{getDateString(price.updatedAt)}</UpdatedAt>
      <DeleteButton $display={isMouseOver} onClick={handleDelete}>
        <BsTrash/>
      </DeleteButton>
    </PriceRow>
  );
};