import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { fetchUserData } from '../../store/reducers/userSlice';
import { Container } from '../../components/Container';
import { Dashboard } from '../Dashboard';
import { InitialOrganizationsSelector } from '../InitialOrganizationSelector';

export function Home () {

  const dispatch = useAppDispatch();

  const { logged } = useAppSelector((state) => state.access);

  useEffect(() => {
    if(logged){
      const getUserData = async () => {
        dispatch(fetchUserData());
      };
      getUserData();
    }
  }, [logged]);
    
  return (
    <Container>
      <Routes>
        <Route path="" element={<Dashboard/>} />
        <Route path=":organizationId/*" element={ <Dashboard/> } />
        <Route path="organization_selection" element={<InitialOrganizationsSelector/>} />
      </Routes>
    </Container>
  );
}