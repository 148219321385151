import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DeleteButton } from './styles/DeleteButton';

const PriceMakerOperatorContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
`;

const CurrentOperatorContainer = styled.button`
  width: 35px;
  height: 35px;
  padding: 0;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px grey;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-size: 1.2rem;
  margin-left: 10px;
`;

interface PriceMakerOperatorProps{
  operator: string,
  onChange: any,
  onDelete: any,
  mutable?: boolean,
}

export const PriceMakerOperator = ({operator, onChange, onDelete, mutable = true}: PriceMakerOperatorProps) => {

  const [isSwitcherOpen, setIsSwitcherOpen] = useState(false);
  const [isAggregatorDisplayed, setIsAggregatorDisplayed] = useState(false);

  const handleMainClick = (e: any) => {
    e.preventDefault();
    setIsSwitcherOpen(!isSwitcherOpen);
  };

  const handleChange = (operator: string) => {
    onChange(operator);
    setIsSwitcherOpen(false);
  };

  return (
    <PriceMakerOperatorContainer 
      onMouseOver={() => setIsAggregatorDisplayed(true)} 
      onMouseLeave={() => setIsAggregatorDisplayed(false)}
    >
      <OperatorSwitcher onChange={handleChange} display={isSwitcherOpen && mutable}/>
      <CurrentOperatorContainer onClick={handleMainClick}>
        {
          getOperatorSymbol(operator)
        }
      </CurrentOperatorContainer>
      <DeleteButton onClick={onDelete} display={isAggregatorDisplayed && mutable}/>
    </PriceMakerOperatorContainer>
  );
};

const getOperatorSymbol = (operator: string) => {
  switch(operator){
  case '*':
    return 'x';
  default:
    return operator;
  }
};

interface OperatorSwitcherContainerProps{
  $display: boolean
}

const OperatorSwitcherContainer = styled.div<OperatorSwitcherContainerProps>`
  padding: 5px;
  box-sizing: border-box;
  border-radius: 10px;
  display: ${props => props.$display? 'inherit' : 'none'};
`;

const Operator = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: none;
  text-align: center;
`;

const OperatorSwitcher = ({onChange, display}: any) => {

  const handleClick = (e: any, operator: string) => {
    e.preventDefault();
    onChange(['operator', operator]);
  };

  return (
    <OperatorSwitcherContainer $display={display}>
      <Operator onClick={(e: any) => handleClick(e, '+')}>+</Operator>
      <Operator onClick={(e: any) => handleClick(e, '-')}>−</Operator>
      <Operator onClick={(e: any) => handleClick(e, '/')}>/</Operator>
      <Operator onClick={(e: any) => handleClick(e, '*')}>X</Operator>
    </OperatorSwitcherContainer>
  );
};