import React from 'react';
import { ProfileMenu } from './ProfileMenu';
import { TopMenuContainer, TopMenuBar} from './styles';
import { ArrowButton } from '../ArrowButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getOrganization } from '../../store/reducers/organizationSlice';
import { switchProfileMenu as switchProfileMenuReducer, switchOrganizationsMenu as switchOrganizationsMenuReducer } from '../../store/reducers/interfaceSlice';
import { OrganizationSelector } from './OrganizationSelector';

export function TopMenu () {

  const dispatch = useAppDispatch();

  const { name: userName, organizations} = useAppSelector((state) => state.user);
  const { ...organization } = useAppSelector((state) => state.organization);
  const { displayProfileMenu, displayOrganizationsMenu } = useAppSelector((state) => state.interface);

  const switchProfileMenu = () => {
    dispatch(switchProfileMenuReducer());
  };

  const switchOrganizationMenu = () => {
    dispatch(switchOrganizationsMenuReducer());
  };

  const handleOrganizationSelection = (organizationId: number) => {
    dispatch(getOrganization(organizationId));
  };

  return (
    <TopMenuContainer>
      <TopMenuBar>
        {
          organization != null &&
            <OrganizationSelector
              src = {organization.imageUrl}
              text = {organization.name}
              organizations = {organizations}
              onSelect = {handleOrganizationSelection}
              display = {displayOrganizationsMenu}
              onSwitch = {switchOrganizationMenu}
            />
        }
        <ArrowButton twisted={displayProfileMenu} onClick={switchProfileMenu}/>
      </TopMenuBar>
      <ProfileMenu 
        display={displayProfileMenu}
        name={userName}
      />
    </TopMenuContainer>
  );
}