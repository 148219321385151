import styled from '@emotion/styled';
import React from 'react';

export const ChartsRow = ({ children }: {
  children: React.ReactNode,
}) => {
  const itemsCount = React.Children.count(children);

  return (
    <Row itemsCount={itemsCount}>
      { children }
    </Row>
  );
};

const Row = styled('div')<{
  itemsCount: number,
}>(props => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '20px',
  justifyContent: 'space-between',
  '> *': {
    width: `calc(100% / ${props.itemsCount} - 10px)`,
  },
}));