import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestStatus } from '../../constants/requestStatus';
import {
  getExpenses as getExpensesService,
  createExpense as createExpenseService,
  updateExpense as updateExpenseService,
  deleteExpense as deleteExpenseService,
  getExpensesAmount as getExpensesAmountService,
} from '../../services/transactionsService';
import { clearStore } from '../actions/clearStore';
interface Expense {
  id: number
  type: string
  date: string
  quantity: number
  concept: string
  value: number
  status: string
}

interface SearchParams{
  searchTerm: string,
  page: number,
  limit: number,
  orderBy: string,
  order: string,
  after?: string,
  before?: string,
  isLapseActive: boolean,
}

interface State {
  expenses: Expense[]
  expensesFetchingStatus: string
  lastPage: number
  currentPeriodExpensesAmount: number
  previousPeriodExpensesAmount: number
  isSynchronized: boolean
  searchParams: SearchParams
}

const initialState: State = {
  expenses: [],
  expensesFetchingStatus: requestStatus.READY,
  lastPage: 10,
  currentPeriodExpensesAmount: 0,
  previousPeriodExpensesAmount: 0,
  isSynchronized: true,
  searchParams: {
    searchTerm: '',
    page: 1,
    limit: 10,
    orderBy: 'date',
    order: 'desc',
    isLapseActive: false,
    after: (new Date()).toISOString().split('T')[0],
    before: (new Date()).toISOString().split('T')[0],
  }
};

export const getExpenses = createAsyncThunk(
  'getExpenses',
  async ({ searchParams }: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getExpensesService(organizationId, searchParams);
    return response.data;
  }
);

export const createExpense = createAsyncThunk(
  'createExpense',
  async({data}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await createExpenseService(organizationId, data);
    return response.data;
  }
);

export const updateExpense = createAsyncThunk(
  'updateExpense',
  async ({expenseId, data}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await updateExpenseService(organizationId, expenseId, data);
    return response.data;
  }
);

export const deleteExpense = createAsyncThunk(
  'deleteExpense',
  async ({expenseId}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await deleteExpenseService(organizationId, expenseId);
    return response.data;
  }
);

export const getExpensesAmount = createAsyncThunk(
  'getExpensesAmount',
  async ({searchParams}: any, { getState }) => {
    const { organization: { id: organizationId }} = getState() as any;
    const response = await getExpensesAmountService(organizationId, searchParams);
    return response.data;
  }
);

const formatDownload = (expense: any): Expense => {
  return {
    id: expense.id,
    type: expense.type,
    date: expense.date,
    quantity: expense.quantity,
    concept: expense.concept,
    value: expense.value,
    status: requestStatus.READY
  };
};

export const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    setExpensesSearchParams: (state, action) => {
      state.searchParams = action.payload;
    }
  },
  extraReducers: (builder) => {
    // CLEAR STORE
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });
    
    builder.addCase(getExpenses.pending, (state) => {
      state.expensesFetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(getExpenses.fulfilled, (state, action) => {
      state.expenses = action.payload.data.map((expense: any) => {
        return formatDownload(expense);
      });
      state.lastPage = action.payload.last_page;
      state.expensesFetchingStatus = requestStatus.SUCCESS;
      state.isSynchronized = true;
    });
    builder.addCase(getExpenses.rejected, (state) => {
      state.expensesFetchingStatus = requestStatus.FAILED;
    });

    builder.addCase(createExpense.fulfilled, (state) => {
      state.isSynchronized = false;
    });

    builder.addCase(updateExpense.fulfilled, (state) => {
      state.isSynchronized = false;
    });
    builder.addCase(updateExpense.rejected, (state) => {
      state.isSynchronized = false;
    });

    builder.addCase(deleteExpense.fulfilled, (state) => {
      state.isSynchronized = false;
    });

    builder.addCase(getExpensesAmount.fulfilled, (state, action) => {
      state.currentPeriodExpensesAmount = action.payload.current_amount?? 0;
      state.previousPeriodExpensesAmount = action.payload.previous_amount?? 0;
    });

  }
});

export default expensesSlice.reducer;
export const {setExpensesSearchParams} = expensesSlice.actions;