import React from 'react';
import { ChartsContainer } from '../../../components/ChartWidget/ChartsContainer';
import { ChartsRow } from '../../../components/ChartWidget/ChartsRow';
import { ExpensesAmountsByMonth } from './charts/ExpensesAmountsByMonth';
import { ExpensesAmountByType } from './charts/ExpensesAmountByType';

export const ExpensesCharts = () => {
  try {
    return (
      <ChartsContainer>
        <ChartsRow>
          <ExpensesAmountsByMonth />
        </ChartsRow>
        <ChartsRow>
          <ExpensesAmountByType />
        </ChartsRow>
      </ChartsContainer>
    );
  } catch (error) {
    return (
      <div>
        Error al cargar los datos
      </div>
    );
  }
};