import React from 'react';
import styled from '@emotion/styled';
import {colors} from '../../styles/variables';

const StyledTableButton = styled.button`
    min-width: 130px;
    width: 20%;
    max-width: 100%;
    padding: 5px;
    box-sizing: border-box;
    margin-left: 5px;
    border-radius: 5px;
    border: none;
    background-color: ${colors.secondary};
    font-size: 0.9rem;
    :hover{
        background-color: ${colors.secondaryHover};
        cursor: pointer;
    }
`;

interface TableButtonProps{
    text: string,
    onClick: any,
}

export const TableButton = (({text, onClick}: TableButtonProps) => {
  return (
    <StyledTableButton onClick={onClick}>
      {text}
    </StyledTableButton>
  );
});