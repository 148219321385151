import React, { MouseEventHandler } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { BiCopy } from 'react-icons/bi';
import { useMyContext } from '../../store/Context';

interface ButtonProps{
  onClick?: MouseEventHandler,
  className?: any
}

export const CloneIconButton = ({onClick, className}: ButtonProps) => {
  const lang = useMyContext();

  return (
    <Tooltip title={lang.clone}>
      <IconButton onClick={onClick} className={className}>
        <BiCopy/>
      </IconButton>
    </Tooltip>
  );
};