import React from 'react';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { Button } from '../../../components/inputs';

const Bar = styled.div`
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const TextInputs = styled.div`
  min-width: 70%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
`;

const Date = styled.div`
    min-width: 30%;
`;

interface ListHeaderProps {
  title: string
  subtitle: string
  date: string
  onChange: any
  onGroupCreation: any
}

export const ListHeader = ({ title, subtitle, date, onChange, onGroupCreation }: ListHeaderProps) => {

  const handleDateChange = (e: any) => {
    onChange('date', e.target.value);
  };

  const handleTitleChange = (e: any) => {
    onChange('title', e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    onChange('subtitle', e.target.value);
  };

  return (
    <Bar>
      <TextInputs>
        <TextField type='text' name='title' value={title} onChange={handleTitleChange} variant='outlined' label='Título'/>
        <TextField type='text' name='subtitle' value={subtitle} onChange={handleDescriptionChange} variant='outlined' label='Subtítulo'/>
      </TextInputs>
      <Date>
        <TextField type="date" value={date} onChange={handleDateChange}/>
      </Date>
      <Button onClick={onGroupCreation}>
        Nuevo grupo de precios
      </Button>
    </Bar>
  );
};
