import React from 'react';
import { IncomesAmountsByMonth } from './charts/IncomesAmountsByMonth';
import { ChartsContainer } from '../../../components/ChartWidget/ChartsContainer';
import { ChartsRow } from '../../../components/ChartWidget/ChartsRow';
import { IncomesByClient } from './charts/IncomesByClient';
import { IncomesAmountByConcept } from './charts/IncomesAmountByConcept';

export const IncomesCharts = () => {
  try {
    return (
      <ChartsContainer>
        <ChartsRow>
          <IncomesAmountsByMonth />
        </ChartsRow>
        <ChartsRow>
          <IncomesByClient />
          <IncomesAmountByConcept />
        </ChartsRow>
      </ChartsContainer>
    );
  } catch (error) {
    return (
      <div>
        Error al cargar los datos
      </div>
    );
  }
};