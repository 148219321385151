import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Select } from '../../../../components/ItemEditor/styles/Select';
import { CustomIconButton } from '../../../../components/CustomIconButton';
import { AiOutlinePlusCircle } from 'react-icons/ai';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 0.3rem;
`;

interface PriceSelectorProps {
  onPriceAddition: any
  prices: any
}

export const PriceSelector = ({ onPriceAddition, prices }: PriceSelectorProps) => {

  const [ valuesList, setValuesList ] = useState<any>([]);
  const [ value, setValue ] = useState<any>();

  useEffect(() => {
    setValuesList(
      prices.map((price: any) => {
        return {
          option: price.id,
          name: `${price.product} - ${price.description}`,
        };
      }).sort()
    );
  }, prices);

  useEffect(() => {
    setValue(valuesList[0]);
  }, [valuesList]);

  const handleSelect = (e: any) => {
    setValue(valuesList.find((value: any) => value.option == e.target.value));
  };

  const handleClick = () => {
    onPriceAddition(value.option);
  };

  return (
    <Container>
      <Select 
        name='price'
        values={valuesList}
        selectedValue={value?.option}
        onSelect={handleSelect}
      />
      <CustomIconButton onClick={handleClick}>
        <AiOutlinePlusCircle />
      </CustomIconButton>
    </Container>
  );
};