import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../../components/Wrapper';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useMyContext } from '../../../store/Context';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getExpensesAmount } from '../../../store/reducers/expensesSlice';
import { getIncomesAmount } from '../../../store/reducers/incomesSlice';
import { LapseFilterPresettable } from '../../../components/LapseFilter/LapseFilterPresettable';
import { BalanceResume } from './styles/BalanceResume';
import { BalanceCharts } from './BalanceCharts';

export default function Balance () {

  const lang = useMyContext();
  const formatDate = useFormatDate;
  const dispatch = useAppDispatch();

  const { id: organizationId } = useAppSelector((state) => state.organization);
  const { currentPeriodIncomesAmount, previousPeriodIncomesAmount, isSynchronized: incomesIsSynchronized } = useAppSelector((state) => state.incomes);
  const { currentPeriodExpensesAmount, previousPeriodExpensesAmount, isSynchronized: expensesIsSynchronized } = useAppSelector((state) => state.expenses);
  const [searchParams, setSearchparams] = useState({
    after: formatDate(Date.now()),
    before: formatDate(Date.now()),
  });

  const handleLapseFilterChange = ({after, before}: any) => {
    setSearchparams({
      after,
      before,
    });
  };

  useEffect(() => {
    fetchAll();
  }, [searchParams, organizationId]);

  useEffect(() => {
    if(!incomesIsSynchronized || !expensesIsSynchronized){
      fetchAll();
    }
  }, [incomesIsSynchronized, expensesIsSynchronized]);
  
  const fetchAll = () => {
    dispatch(getIncomesAmount({organizationId, searchParams}));
    dispatch(getExpensesAmount({organizationId, searchParams}));
  };

  return (
    <Wrapper title={lang.transactions.balance.name}>
      <LapseFilterPresettable
        onChange={handleLapseFilterChange}
        after={searchParams.after}
        before={searchParams.before}
        isActive={true}
        setIsActive={null}
      />
      <BalanceResume 
        currentIncomes={currentPeriodIncomesAmount}
        previousIncomes={previousPeriodIncomesAmount}
        currentExpenses={currentPeriodExpensesAmount}
        previousExpenses={previousPeriodExpensesAmount}
      />
      <BalanceCharts />
    </Wrapper>
  );
}
