import styled from '@emotion/styled';
import React from 'react';
import { Wrapper } from '../Wrapper';
import { MdArrowBack } from 'react-icons/md';
import { CustomIconButton } from '../CustomIconButton';

interface ListDetailProps {
  title: string
  children: any
  onGoBack: any
}

const NavigationBar = styled.div`
  width: 100%;
  text-align: left;
`;

export function ListDetail({title, children, onGoBack}: ListDetailProps) {

  return (
    <>
      <NavigationBar>
        <CustomIconButton
          onClick={onGoBack}
        >
          <MdArrowBack/>
        </CustomIconButton>
      </NavigationBar>
      <Wrapper title={title}>
        {children}
      </Wrapper>
    </>
  );
}