import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { OperationAggregatorMenu } from './OperatorAggregatorMenu';
import { OperatorAggregatorMiniButton } from './styles/OperatorAggregatorMiniButton';
import { ElementAggregatorMenu } from './ElementAggregatorMenu';
import { DeleteButton } from '../styles/DeleteButton';


const AggregatorWrapper = styled.div`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

interface AggregatorContainerProps{
  type: string
  $display: boolean
}

const AggregatorContainer =  styled.div<AggregatorContainerProps>`
  width: 100%;
  height: 25px;
  justify-content: center;
  align-items: center;
  position: absolute;
  display: ${props=>props.$display? 'flex' : 'none'};
  ${props => props.type === 'pre'? 'top: -12.5px;' : 'bottom: -12.5px;'};
`;

interface AggregatorInterface{
  type: 'pre'|'post'|'unique',
  display: boolean,
  onAggregate: any,
  onSetKeepDisplayed: any,
}

const OperationAggregator = ({type, display, onAggregate, onSetKeepDisplayed}: AggregatorInterface) => {

  const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);

  const handleOpenerClick = (e: any) => {
    e.preventDefault();
    setIsMenuDisplayed(true);
  };

  const handleAggregate = (aggregation: string) => {
    onAggregate(aggregation);
    setIsMenuDisplayed(false);
  };

  const handleClose = () => {
    setIsMenuDisplayed(false);
  };

  useEffect(() => {
    onSetKeepDisplayed(isMenuDisplayed);
  }, [isMenuDisplayed]);

  return (
    <AggregatorContainer type={type} $display={display}>
      {
        isMenuDisplayed ?
          type === 'unique'
            ? <ElementAggregatorMenu display={isMenuDisplayed} onAggregate={handleAggregate} onClose={handleClose} type={type} />
            : <OperationAggregatorMenu display={isMenuDisplayed} onAggregate={handleAggregate} onClose={handleClose} type={type} />
          :
          <OperatorAggregatorMiniButton onClick={handleOpenerClick}>+</OperatorAggregatorMiniButton>
      }
    </AggregatorContainer>
  );
};

interface FormulaItemInterface{
  children: any
  onPreAggregate: any
  onPostAggregate: any
  onDelete: any,
}

export const FormulaItem = ({children, onPreAggregate, onPostAggregate, onDelete}: FormulaItemInterface) => {

  const [isAggregatorDisplayed, setIsAggregatorDisplayed] = useState(false);
  const [keepDisplayed, setKeepDisplayed] = useState(false);

  return (
    <AggregatorWrapper onMouseOver={() => setIsAggregatorDisplayed(true)} onMouseLeave={() => setIsAggregatorDisplayed(false)}>
      <OperationAggregator type='pre' display={isAggregatorDisplayed || keepDisplayed } onAggregate={onPreAggregate} onSetKeepDisplayed={setKeepDisplayed}/>
      {children}
      <OperationAggregator type='post' display={isAggregatorDisplayed || keepDisplayed} onAggregate={onPostAggregate} onSetKeepDisplayed={setKeepDisplayed}/>
      <DeleteButton onClick={onDelete} display={isAggregatorDisplayed || keepDisplayed}/>
    </AggregatorWrapper>
  );
};

const EmptyGroupContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const EmptyGroupAggregator = ({onAggregate}: any) => {

  const [isAggregatorDisplayed, setIsAggregatorDisplayed] = useState(true);
  const [keepDisplayed, setKeepDisplayed] = useState(true);

  return (
    <EmptyGroupContainer onMouseOver={() => setIsAggregatorDisplayed(true)} onMouseLeave={() => setIsAggregatorDisplayed(false)}>
      <OperationAggregator type='unique' onAggregate={onAggregate} onSetKeepDisplayed={setKeepDisplayed} display={ isAggregatorDisplayed || keepDisplayed} />
    </EmptyGroupContainer>
  );
};