import api from './config/api';

export const getClients = async (organizationId: number) => {
  const response = await api.get(`${organizationId}/clients`);
  return response.data;
};

export const getClient = async (organizationId: number, id: number) => {
  const response = await api.get(`${organizationId}/clients/${id}`);
  return response.data;
};

export const createClient = async (organizationId: number, data: any)  => {
  const response = await api.post(`${organizationId}/clients`, data);
  return response.data;
};

export const updateClient = async (organizationId: number, id: number, data: any) => {
  const response = await api.put(`${organizationId}/clients/${id}`, data);
  return response.data;
};

export const deleteClient = async (organizationId: number, id: number) => {
  const response = await api.delete(`${organizationId}/clients/${id}`);
  return response.data;
};
