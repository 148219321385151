import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { getMonthlyIncomesAmount } from '../../../../services/transactionsService';
import { ChartWidget } from '../../../../components/ChartWidget';
import { LineChart } from '@mui/x-charts';

export const IncomesAmountsByMonth = () => {
  const [incomes, setIncomes] = useState([]);
  const { id: organizationId } = useAppSelector((state) => state.organization);

  const fetchIncomes = async () => {
    const response = await getMonthlyIncomesAmount(organizationId, {});
    setIncomes(response.data);
  };

  useEffect(() => {
    fetchIncomes();
  }, [organizationId]);

  return (
    <ChartWidget title="Ventas por mes">
      <LineChart
        xAxis={[
          {
            scaleType: 'point',
            data: incomes.map((income: any) => income.date)
          }
        ]}
        series={[
          {
            data: incomes.map((income: any) => Number(income.amount)),
          }
        ]}
        margin={
          {
            left: 100,
          }
        }
        height={300}
      />
    </ChartWidget>
  );
};