import React from 'react';
import styled from '@emotion/styled';
import { FiArrowDownCircle } from 'react-icons/fi';

const StyledArrowButton = styled.div<ArrowButtonInterface>`
transform: rotate(
    ${props => props.twisted
    ? '180deg'
    : '0deg'
}
    );
    width: 30px;
    height: 30px;
    svg {
        width: 100%;
        height: 100%;
    }
    `;
    
interface ArrowButtonInterface{
    twisted: boolean,
    onClick: any,
}

export const ArrowButton = ({ twisted, onClick } : any) => {
  return (
    <StyledArrowButton twisted={twisted} onClick={onClick}>
      <FiArrowDownCircle />
    </StyledArrowButton>
  );
};