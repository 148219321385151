import styled from '@emotion/styled';
import { Accordion } from '@mui/material';
import { colors } from '../../styles/variables';
export * from './TableCategoryFilter';
export * from './TableTextFilter';
export * from './TableHeader';

export const TableEnvironment = styled.div`
    width: 100%;
    heigh: 100%;
    box-sizing: border-box;
    padding: 15px 0;
`;

export const TableContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 15px 0;
    box-sizing: border-box;
    background-color: ${colors.softHighlight};
    border-radius: 15px;
`;

export const TableTitle = styled.h3`
    width: 100%;
    padding: 1%;
    box-sizing: border-box;
    border-bottom: solid 1px rgba(0,0,0,0.2);
`;

interface TableRowProps{
    gridLayout: string,
}

export const TableRow = styled.div<TableRowProps>`
    display: grid;
    grid-template-columns: ${props => props.gridLayout};
    grid-gap: 2px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    :hover{
        background-color: ${colors.softHighlight};
    }
`;

export const TableCell = styled.div`
    padding: 8px 2px 8px 2px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const TableBarContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    display: flex;
`;

export const TableBarAccordion = styled(Accordion)`
    width: 100%;
    background-color: white;
`;



