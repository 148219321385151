import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PricesGroupPrice } from './PricesGroupPrice';
import { PriceSelector } from './PriceSelector';
import { useAppDispatch } from '../../../../store/hooks';
import { addGroupPrice, deletePricesGroup, removeGroupPrice, updatePricesGroup } from '../../../../store/reducers/pricesListEditorSlice';
import { PricesGroupTitle } from './PricesGroupTitle';
import { colors } from '../../../../styles/variables';
import { CustomIconButton } from '../../../../components/CustomIconButton';
import { BiTrashAlt } from 'react-icons/bi';

const Group = styled.div`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 3rem;
  border: 2px solid ${colors.softHighlight};
  border-radius: 4px;
`;

const GroupHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

interface PricesGroupProps {
  pricesGroup: any
  prices: any
  onUpdate: any
}

export const PricesGroup = ({ pricesGroup, prices, onUpdate }: PricesGroupProps) => {

  const dispatch = useAppDispatch();

  const [ data, setData ] = useState<any>({});

  useEffect(() => {
    setData(pricesGroup);
  }, [pricesGroup]);

  const handleDelete = () => {
    dispatch(deletePricesGroup({id: data.id}));
  };

  const handlePriceDelete = (id: number) => {
    dispatch(removeGroupPrice({pricesGroupId: data.id, priceMakerId: id})).unwrap();
    onUpdate();
  };

  const handlePriceAddition = (id: number) => {
    console.log(id);
    dispatch(addGroupPrice({pricesGroupId: data.id, priceMakerId: id})).unwrap();
    onUpdate();
  };

  const handleChange = (field: string, value: any) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const updateData = () => {
    dispatch(updatePricesGroup({data}));
  };

  return (
    <Group>
      <GroupHeader>
        <PricesGroupTitle title={data.title} onChange={handleChange} onSave={updateData}/>
        <CustomIconButton onClick={handleDelete}>
          <BiTrashAlt/>
        </CustomIconButton>
      </GroupHeader>
      <PriceSelector onPriceAddition={handlePriceAddition} prices={prices}/>
      {
        data.prices?.map((price:any) => {
          return (
            <PricesGroupPrice 
              price={price} 
              key={price.id}
              onDelete={() => handlePriceDelete(price.id)}
            />
          );
        })
      }
    </Group>
  );
};