import React from 'react';
import styled from '@emotion/styled';
import { Pagination } from '@mui/material';
import { colors } from '../../styles/variables';

const Wrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${colors.softHighlight};
`;

interface TablePaginationProps{
  count: number
  page: number
  onChange: any
}

export const TablePagination = ({count, page, onChange}: TablePaginationProps) => {
  return (
    <Wrapper>
      <Pagination count={count} page={page} onChange={onChange}/>
    </Wrapper>
  );
};