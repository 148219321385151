import React, { ChangeEventHandler } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../styles/variables';

const StyledInput = styled.input`
    width: 300px;
    max-width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 1px rgb(20,20,20);
`;

interface InputPropsInterface{
  type: string
  value: any
  onChange: ChangeEventHandler
  name: string
  className?: string
}

export const Input = ( ({type, value, onChange, name, className}: InputPropsInterface) => {
  return (<StyledInput
    type = {type}
    value = {value}
    onChange = {onChange}
    name = {name}
    className = {className}
  />);
});

interface StyledButtonPropsInterface{
  full: boolean
}

const StyledButton = styled.button<StyledButtonPropsInterface>`
    background-color: ${colors.secondary};
    width: ${(props) => props.full? '100%' : 'auto'};
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    margin: 5px 5px;
    font-size: 0.9rem;
    :hover{
        background-color: ${colors.secondaryHover};
    }
`;

export const Button = ({onClick, ...props}: any) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    onClick(e);
  };
  return (
    <StyledButton {...props} onClick={handleClick}/>
  );
};

const StyledWideButton = styled(StyledButton)`
  width: 100%;
`;

export const WideButton = ({onClick, ...props}: any) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    onClick(e);
  };
  return (
    <StyledWideButton {...props} onClick={handleClick}/>
  );
};
