import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { 
  login as loginService,
  logout as logoutService,
  register as registerService
} from '../../services/authService'; 
import { clearStore } from '../actions/clearStore';
import { requestStatus } from '../../constants/requestStatus';

interface AuthState {
    logged: boolean,
    loginError: boolean,
    errorMessage: string,
    registerStatus: string,
    isAccessTokenActive: boolean
}

interface AuthorizationData {
    email: string,
    password: string,
}

const initialState: AuthState = {
  logged: false,
  loginError: false,
  errorMessage: '',
  registerStatus: requestStatus.READY,
  isAccessTokenActive: false,
};

export const fetchAccessToken = createAsyncThunk(
  'access/fetchAccessToken',
  async ({ email, password }: AuthorizationData, { rejectWithValue }) => {
    try {
      const response = await loginService(email, password);
      return response;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status
      });
    }
  }
);

export const logout = createAsyncThunk(
  'access/logout',
  async () => {
    const response = await logoutService();
    return response;
  }
);


interface RegisteringData{
    name: string,
    email: string,
    password: string,
}

export const register = createAsyncThunk(
  'access/register',
  async (registeringData: RegisteringData) => {
    const response = await registerService(registeringData.name, registeringData.email, registeringData.password);
    return response.data;
  }
);

export const authSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    setLoggedIn: (state) => {
      state.logged = true;
      state.isAccessTokenActive = true;
    },
    setLoggedOut: (state) => {
      state.logged = false;
      state.isAccessTokenActive = false;
    }
  },
  extraReducers: (builder) => {
    // Clear Store
    builder.addCase(clearStore, (state) => {
      Object.assign(state, initialState);
    });
    
    builder.addCase(fetchAccessToken.pending, (state) => {
      state.loginError = false;
    });
    builder.addCase(fetchAccessToken.fulfilled, (state, action) => {
      console.log(action);
      localStorage.setItem('accessToken', action.payload.access_token);
      state.logged = true;
      state.isAccessTokenActive = true;
    });
    builder.addCase(fetchAccessToken.rejected, (state, { payload }: any) => {
      state.loginError = true;
      if (payload.status === 401) {
        state.errorMessage = 'El usuario o la contraseña no son correctos';
      } else {
        state.errorMessage = 'Error al iniciar sesión';
      }
    });
    builder.addCase(logout.fulfilled, (state) => {
      localStorage.removeItem('accessToken');
      state.isAccessTokenActive = false;
      state.logged = false;
    });
    builder.addCase(logout.rejected, (state) => {
      state.logged = false;
    });
    builder.addCase(register.pending, (state) => {
      state.registerStatus = 'pending';
    });
    builder.addCase(register.fulfilled, (state) => {
      state.registerStatus = requestStatus.SUCCESS;
    });
    builder.addCase(register.rejected, (state) => {
      state.registerStatus = requestStatus.FAILED;
    });

  }
});

export default authSlice.reducer;
export const { setLoggedIn, setLoggedOut } = authSlice.actions;