import React from 'react';
import styled from '@emotion/styled';
import { IoMdArrowDropdown } from 'react-icons/io';

const Wrapper = styled.button`
    width: 100%;
    font-size: 1.05rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
`;

interface TextProps{
    isActive?: boolean
}

const Text = styled.div<TextProps>`
    font-weight: ${props => props.isActive? '900' : '300'}
`;

interface ArrowProps{
    isActive: boolean
    isDesc: boolean
}

const Arrow = styled.div<ArrowProps>`
    height: 100%;
    display: ${props => props.isActive? 'inherit' : 'none'};
    transform: rotate(${props => props.isDesc? '0deg' : '180deg'});
`; 

interface TableColumnHeaderProps{
    name?: string
    text: string
    isActive?: boolean
    isDesc?: boolean
    setOrder?: any
}

export const TableColumnHeader = ({name, text, isActive, isDesc, setOrder}: TableColumnHeaderProps) => {

  const handleClick = () => {
    if(isActive){
      setOrder({
        field: name,
        isDesc: !isDesc,
      });
    } else {
      setOrder({
        field: name,
        isDesc: true,
      });
    }
  };

  return (
    <Wrapper onClick={handleClick}>
      {
        name && isActive && isDesc !== undefined?
          <>
            <Text isActive={isActive}>
              {text}
            </Text>
            <Arrow isActive={isActive} isDesc={isDesc}>
              <IoMdArrowDropdown/>
            </Arrow>
          </>
          : <Text>{text}</Text>
      }
    </Wrapper>
  );
};