import React, { useEffect, useState } from 'react';
import { units } from '../../config/units';
import { useSelectValues } from '../../hooks/useSelectValues';
import { useMyContext } from '../../store/Context';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeResourceEditor } from '../../store/reducers/interfaceSlice';
import {
  createResource,
  updateResource,
  setResourceOnEdition,
} from '../../store/reducers/resourcesSlice';
import { ItemEditor } from '../../components/ItemEditor';
import { Field, Group, Input, Label } from '../../components/ItemEditor/styles';
import { Select } from '../../components/ItemEditor/styles/Select';

export function ResourceEditor () {

  const dispatch = useAppDispatch();
  const formatSelectValues = useSelectValues;
  const lang = useMyContext();

  const formDataInitialState = {
    name: '',
    price: 0,
    category: 1,
    stock: 0,
    unit: 'kg',
  };

  const [formData, setFormData] = useState(formDataInitialState);

  const { categories } = useAppSelector((state) => state.resourcesCategories);

  const {
    resourceOnEdition,
  } = useAppSelector((state) => state.resources);

  const { displayResourceEditor: display } = useAppSelector((state) => state.interface);

  const save = async () => {
    if(resourceOnEdition){
      dispatch(updateResource({
        resourceId: resourceOnEdition.id,
        data: formData
      }));
    } else {
      dispatch(createResource({
        data: formData
      }));
    }
    close();
  };

  const close = () => {
    dispatch(setResourceOnEdition(null));
    dispatch(closeResourceEditor());
  };
    
  useEffect(() => {
    if (resourceOnEdition !== null) {
      const { name, price, stock, unit, category } = resourceOnEdition;
      setFormData({
        name,
        price,
        stock,
        unit,
        category: category,
      });
    } else {
      setFormData(formDataInitialState);
    }
  }, [resourceOnEdition]);
    
  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <ItemEditor
      display={display}
      title={
        resourceOnEdition
          ? lang.resources.edit
          : lang.resources.new
      }
      close={close}
      save={save}
    >
      <Group>
        <Field size={4}>
          <Label>{lang.attributes.name}:</Label>
          <Input
            type='text'
            name='name'
            value={formData['name']}
            onChange={handleChange}
          />  
        </Field>
        <Field size={3}>
          <Label>{lang.attributes.price}</Label>
          <Input
            type='number'
            name='price'
            value={formData['price']}
            onChange={handleChange}
          />
        </Field>
        <Field size={2}>
          <Label>{lang.attributes.stock}</Label>
          <Input
            type='number'
            name='stock'
            value={formData['stock']}
            onChange={handleChange}
          />
        </Field>
        <Field size={1}>
          <Label>{lang.attributes.unit}</Label>
          <Select 
            name = 'unit'
            values = {formatSelectValues(units)}
            selectedValue = {formData.unit}
            onSelect = {handleChange}
          />
        </Field>
      </Group>
      <Group>
        <Field size={1}>
          <Label>{lang.attributes.category}</Label>
          <Select
            name = 'category'
            values = {formatSelectValues(categories, 'id', 'name')}
            selectedValue = {formData.category}
            onSelect = {handleChange}
          />
        </Field>
      </Group>
    </ItemEditor>
  );
}