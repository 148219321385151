import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { ValueContainer } from './BalanceValue';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const TransactionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  margin: 5px;
`;
interface BalanceResumeProps{
  currentIncomes: number,
  previousIncomes: number,
  currentExpenses: number,
  previousExpenses: number,
}

export const BalanceResume = ({currentIncomes, previousIncomes, currentExpenses, previousExpenses}: BalanceResumeProps) => {

  const [incomesVariation, setIncomesVariation] = useState(0);
  const [expensesVariation, setExpensesVariation] = useState(0);
  const [balanceVariation, setBalanceVariation] = useState(0);

  useEffect(() => {
    setIncomesVariation(
      previousIncomes !== 0? parseFloat((currentIncomes / previousIncomes).toFixed(1)) : 0
    );
    setExpensesVariation(
      previousExpenses !== 0? parseFloat((currentExpenses / previousExpenses).toFixed(1)) : 0
    );
    setBalanceVariation(
      (previousIncomes-previousExpenses) !== 0? parseFloat(((currentIncomes-currentExpenses) / (previousIncomes-previousExpenses)).toFixed(2)) : 0
    );
  }, [currentIncomes, previousIncomes, currentExpenses, previousExpenses]);

  return (
    <Container>
      <TransactionsContainer>
        <ValueContainer
          title='Ingresos'
          content={`$${currentIncomes}`}
          variationPercentage={incomesVariation}
        />
        <ValueContainer
          title='Egresos'
          content={`$${currentExpenses}`}
          variationPercentage={expensesVariation}
        />
      </TransactionsContainer>
      <TransactionsContainer>
        <ValueContainer
          title='Balance'
          content={`$${currentIncomes-currentExpenses}`}
          variationPercentage={balanceVariation}
        />
      </TransactionsContainer>
    </Container>
  );
};