import React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { fonts } from '../../styles/variables';

const Cell = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    aling-items: center;
`;

const Input = styled.input`
    width: 100%;
    background: none;
    border: solid 2px black;
    text-align: center;
    border-radius: 3px;
`;

const Text = styled.button`
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    font-family: ${fonts.base.family};
    font-size: ${fonts.base.size}
`;


interface TableCellInputProps{
    id: number,
    actualValue: any,
    onChange: any,
    type?: string,
}

export const TableCellInput = ({id, actualValue, onChange, type = 'text'}: TableCellInputProps) => {

  const [isOnEdition, setIsOnEdition] = useState<boolean>(false);
  const [value, setValue] = useState(actualValue);

  const onInputChange = (e: any) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    save();
  };

  const onKeyUp = (e: any) => {
    if(e.keyCode === 13){
      save();
    }
  };

  const handleClick = () => {
    setIsOnEdition(true);
  };

  const save = () => {
    onChange(id, value);
    setIsOnEdition(false);
  };

  return (
    <Cell>
      {
        isOnEdition
          ? <Input type={type} value={value} onChange={onInputChange} onKeyUp={onKeyUp} onBlur={onBlur}/>
          : <Text onClick={handleClick}>{value}</Text>
      }
    </Cell>
  );
};