import React from 'react';
import styled from '@emotion/styled';

const StyledContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

interface ContainerProps{
  children: JSX.Element
}

export const Container = ({ children }: ContainerProps ) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  );
};