import styled from '@emotion/styled';
import React, { useState } from 'react';
import { elementValues } from './elementValues';
import { OperatorAggregatorMiniButton } from './styles/OperatorAggregatorMiniButton';

interface MenuProps{
  $display: boolean,
}

const StyledMenu = styled.div<MenuProps>`
  height: 25px;
  display: ${props => props.$display? 'flex': 'none'};
`;

const TypeSelector = styled.select`
  width: 200px;
`;

export const ElementAggregatorMenu = ({display, onAggregate, onClose, type}: any) => {

  const [elementType, setElementType] = useState('value');

  const [formulaOperation, setFormulaOperation] = useState<any>([
    [], ['value', 0]
  ]);

  const handleTypeSelect = (e: any) => {
    setElementType(e.target.value);
    setFormulaOperation(
      [
        formulaOperation[0],
        [e.target.value, elementValues[e.target.value]]
      ]
    );
  };

  const aggregate = (e: any) => {
    e.preventDefault();
    if(type === 'pre'){
      onAggregate([formulaOperation[1], formulaOperation[0]]);
    } else {
      onAggregate([formulaOperation[1]]);
    }
  };

  const handleCloseClick = (e: any) => {
    e.preventDefault();
    onClose();
  };

  return (
    <StyledMenu $display={display}>
      <TypeSelector onChange={handleTypeSelect} value={elementType}>
        <option value='value'>Valor</option>
        <option value='modifier'>Modificador</option>
        <option value='resource'>Recurso</option>
        <option value='product'>Producto</option>
        <option value='group'>Grupo</option>
        <option value='monomial'>Monomio</option>
      </TypeSelector>
      <button onClick={aggregate}>Insertar</button>
      <OperatorAggregatorMiniButton onClick={handleCloseClick}>x</OperatorAggregatorMiniButton>
    </StyledMenu>
  );
};