import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestStatus } from '../../constants/requestStatus';
import { me as meService } from '../../services/authService';
import { getPermissions } from '../../services/permissionService';
import { clearStore } from '../actions/clearStore';

interface UserState {
    name: string,
    email: string,
    roleId: number | null,
    organizations: any[],
    permissions: any[],
    userFetchingStatus: string,
    permissionsFetchingStatus: string,
}

const initialState: UserState = {
  name: '',
  email: '',
  roleId: null,
  organizations: [],
  permissions: [],
  userFetchingStatus: requestStatus.READY,
  permissionsFetchingStatus: requestStatus.READY,
};

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async () => {
    const userResponse = await meService();
    return userResponse.data;
  }       
);

export const fetchPermissions = createAsyncThunk(
  'user/fetchPermissions',
  async (organizationId: number) => {
    const response = await getPermissions(organizationId);
    return response.data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.userFetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.roleId = action.payload.role_id;
      state.organizations = action.payload.organizations.map((organization: any) => ({
        id: organization.id,
        name: organization.name,
        imageUrl: organization.image_url,
      }));
      state.userFetchingStatus = requestStatus.SUCCESS;
    });
    builder.addCase(fetchUserData.rejected, (state) => {
      state.userFetchingStatus = requestStatus.FAILED;
    });
    builder.addCase(clearStore, (state) => {
      state = Object.assign(state, initialState);
    });
    builder.addCase(fetchPermissions.pending, (state) => {
      state.permissionsFetchingStatus = requestStatus.PENDING;
    });
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      state.permissions = Object.assign(state.permissions, action.payload.permissions);
      state.permissionsFetchingStatus = requestStatus.SUCCESS;
    });
    builder.addCase(fetchPermissions.rejected, (state) => {
      state.permissionsFetchingStatus = requestStatus.FAILED;
    });
  }
});

export default userSlice.reducer;