import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { getIncomesAmountByClient } from '../../../../services/transactionsService';
import { PieChart } from '@mui/x-charts';
import { ChartWidget } from '../../../../components/ChartWidget';

const maxClients = 7;

export const IncomesByClient = () => {
  const { id: organizationId } = useAppSelector((state) => state.organization);
  const [incomes, setIncomes] = useState([]);

  const fetchIncomes = async () => {
    const response = await getIncomesAmountByClient(organizationId, {});
    const parsedIncomes = response.data.reduce((acc: any, income: any, index: number) => {
      if (index < maxClients) {
        acc.push({
          label: income.client_name,
          value: income.amount
        });
      } else if (index === maxClients) {
        acc.push({
          label: 'Otros',
          value: income.amount
        });
      } else {
        acc[maxClients].value += income.amount;
      }
      return acc;
    }, []);
    setIncomes(parsedIncomes);
  };

  useEffect(() => {
    fetchIncomes();
  }, [organizationId]);

  return (
    <ChartWidget title="Ventas por cliente">
      <PieChart
        series={[
          {
            data: incomes,
            innerRadius: 50,
            outerRadius: 120,
            paddingAngle: 2,
            cornerRadius: 5,
            cx: '30%',
          }
        ]}
        height={300}
        slotProps={
          {
            legend: {
              labelStyle: {
                fontSize: 10
              }
            }
          }
        }
      />
    </ChartWidget>
  );
};