import styled from '@emotion/styled';
import React from 'react';

const StyledImagoType = styled.div`
    width: 100%;
`;

export const ImagoType = () => {
  return (
    <StyledImagoType>
      <img src="imagotype.png" />
    </StyledImagoType>
  );
};