import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background-color: white;
    overflow: auto;
`;

const Title = styled.div`
    width: 100%;
    height: 70px;
    border-bottom: solid 1px black;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    box-sizing: border-box;
`;

interface WrapperInterface{
    title: string,
    children: any,
}

export const Wrapper = ({title, children}: WrapperInterface) => {
  const formattedTitle = title.substring(0,1).toUpperCase()+title.substring(1).toLowerCase();
  return (
    <Container>
      <Title>
        <h2>{formattedTitle}</h2>
      </Title>
      <Content>
        {children}
      </Content>
    </Container>
  );
};