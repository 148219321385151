import React, { useEffect, useState } from 'react';
import { useFormat } from '../../hooks/useFormat';
import { useSort } from '../../hooks/useSort';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getCategories } from '../../store/reducers/productsCategoriesSlice';
import { getProducts, updateProduct } from '../../store/reducers/productsSlice';
import { CustomIconButton } from '../../components/CustomIconButton';
import { 
  TableBarContainer, 
  TableCategoryFilter, 
  TableCell, 
  TableColumnHeader, 
  TableContainer, 
  TableEnvironment, 
  TableRow, 
  TableTextFilter, 
  TableTitle } 
  from '../../components/Table';
import { TableCellInput } from '../../components/Table/TableCellInput';
import { BiReset } from 'react-icons/bi';
import { useMyContext } from '../../store/Context';

export function ProductsStock () {
    
  const lang = useMyContext();
  const sortByField = useSort;
  const format = useFormat;
  const dispatch = useAppDispatch();

  const [textFilter, setTextFilter] = useState('');
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>([]);
  const [order, setOrder] = useState({
    field: 'name',
    isDesc: true
  });

  const { ...organization } = useAppSelector((state) => state.organization);
  const { products } = useAppSelector((state) => state.products);
  const { categories } = useAppSelector((state) => state.productsCategories);

  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [stockedFilteredProducts, setStockedFilteredProducts] = useState<any[]>([]);
  const [nonStockedFilteredProducts, setNotStockedFilteredProducts] = useState<any[]>([]);
  const [isCategoryFilterEnabled, setCategoryFilterStatus] = useState(false);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getCategories());
  }, [organization.id]);

  useEffect(() => {
    setFilteredProducts(
      products
        .filter((product:any) => product.shortDescription.toLowerCase().match(textFilter.toLowerCase()))
        .filter((product:any) => isCategoryFilterEnabled? product.categories.some((category: any) => 
          categoriesFilter.includes(category.id)
        ) : true)
        .sort((a: any, b: any) => sortByField(a,b, order.field, order.isDesc))
    );
  }, [products, textFilter, categoriesFilter, order]);

  useEffect(() => {
    setStockedFilteredProducts(
      filteredProducts.filter((product:any) => product.stockable)
    );
    setNotStockedFilteredProducts(
      filteredProducts.filter((product:any) => !product.stockable)
    );
  }, [filteredProducts]);

  const setProductStock = (id: number, value: number) => {
    updateData(id, 'stock', value);
  };

  const setProductStockable = (id: number, value: boolean) => {
    updateData(id, 'stockable', value);
  };

  const updateData = (id: number, field: string, value: any) => {
    const data = {
      productId: id,
      data: {
        [field]: value,
      },
    };
    console.log(data);
    dispatch(updateProduct(data));
  };

  return (
    <TableEnvironment>
      <TableBarContainer>
        <TableTextFilter
          setter={setTextFilter}
        />
        <TableCategoryFilter 
          type='Categorías'
          categories={categories}
          setFilteredCategories={setCategoriesFilter}
          setCategoryFilterStatus={setCategoryFilterStatus}
        />
      </TableBarContainer>
      <TableContainer>
        <TableTitle>{lang.stock.stockedProducts}</TableTitle>
        <TableRow gridLayout='6fr 4fr 3fr 1fr 1fr'>
          <TableCell>
            <TableColumnHeader 
              name='name' 
              text={lang.attributes.shortDescription} 
              isActive={order.field==='name'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader 
              name='category' 
              text={lang.attributes.categories} 
              isActive={order.field==='category'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader 
              name='stock' 
              text={lang.attributes.stock} 
              isActive={order.field==='stock'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader text={lang.attributes.unit} />
          </TableCell>
          <TableCell>
            <TableColumnHeader text={lang.options} />
          </TableCell>
        </TableRow>
        {
          stockedFilteredProducts.map((product: any) => {
            return(
              <TableRow gridLayout='6fr 4fr 3fr 1fr 1fr' key={product.id}>
                <TableCell>{format(product.shortDescription, 'firstCap')}</TableCell>
                <TableCell>{
                  //categories.find((category) => category.id === product.category).name
                }</TableCell>
                <TableCellInput
                  id={product.id}
                  actualValue={product.stock}
                  onChange={setProductStock}
                />
                <TableCell>
                  {product.unit}
                </TableCell>
                <TableCell>
                  <CustomIconButton onClick={() => setProductStockable(product.id, false)}><BiReset/></CustomIconButton>
                </TableCell>
              </TableRow>
            );
          })
        }
      </TableContainer>
      <TableContainer>
        <TableTitle>{lang.stock.nonStockedProducts}</TableTitle>
        <TableRow gridLayout='6fr 4fr 3fr 1fr'>
          <TableCell>
            <TableColumnHeader 
              name='name' 
              text={lang.attributes.shortDescription} 
              isActive={order.field==='name'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader 
              name='category' 
              text={lang.attributes.categories} 
              isActive={order.field ==='category'} 
              isDesc={order.isDesc} 
              setOrder={setOrder}
            />
          </TableCell>
          <TableCell>
            <TableColumnHeader text={lang.options} />
          </TableCell>
        </TableRow>
        {
          nonStockedFilteredProducts.map((product: any) => {
            return(
              <TableRow gridLayout='6fr 4fr 3fr' key={product.id}>
                <TableCell>{format(product.shortDescription, 'firstCap')}</TableCell>
                <TableCell>{
                  //categories.find((category) => category.id === product.category).name
                }</TableCell>
                <TableCell>
                  <CustomIconButton onClick={() => { setProductStockable(product.id, true);}}>{lang.stock.restore}</CustomIconButton>
                </TableCell>
              </TableRow>
            );
          })
        }
      </TableContainer>
    </TableEnvironment>
  );
}